// @flow

import * as R from "ramda";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import localforage from "localforage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

import defaultStatuses, { defaultStatusConfig } from "src/constants/status";
import * as morpheus from "src/utils/morpheus";
import { createSelector } from "reselect";
import { behaviors, behaviorToSettings } from "src/conditions";

import alert from "./alert";
import memberAddDialog, * as fromMemberAddDialog from "./memberAddDialog";
import settings from "./settings";
import signupCode from "./signupCode";
import signupError from "./signupError";
import canJoin from "./canJoin";
import rehydration from "./rehydration";
import chatRooms, * as fromChatRooms from "./chatRooms";
import currentUser, * as fromCurrentUser from "./currentUser";
import recentChats, * as fromRecentChats from "./recentChats";
import activeChat from "./activeChat";
import workflow, * as fromWorkflow from "./workflow";
import prompts, * as fromPrompts from "./prompts";
import pinnedFilters from "./pinnedFilters";
import users, * as fromUsers from "./users";
import files, * as fromFile from "./file";
import roomMembers, * as fromRoomMembers from "./roomMembers";
import roomGroups from "./roomGroups";
import notification, * as fromNotification from "./notification";
import checklist, * as fromChecklist from "./checklist";
import checklistBuilder, * as fromChecklistBuilder from "./checklistBuilder";
import workflowChecklists, * as fromWorkflowChecklists from "./workflowChecklist";
import error from "./error";
import orgs, * as fromOrg from "./orgs";
import showOverlay from "./showOverlay";
import view from "./view";
import activeDock, { getMainActiveDock, getSRWActiveDock } from "./activeDock";
import uiTriggers from "./uiTriggers";
import reply from "./reply";
import signup from "./signup";
import signin from "./signin";
import * as fromSignin from "./signin";
import invitation from "./invitation";
import conversationDialog, * as fromConversationDialog from "./conversationDialog";
import modal, * as fromModal from "./modal";
import departments, * as fromDepartment from "./departments";
import conversationModal, * as fromConversationModal from "./conversationModal";
import email, * as fromEmail from "./email";
import pendingRoomMembers from "./pendingRoomMembers";
import updates from "./updates";
import groups, * as fromGroups from "./groups";
import userAnalytics from "./userAnalytics";
import statuses, * as fromStatuses from "./statuses";
import templateStatus, * as fromTemplateStatus from "./templateStatus";
import disableUserModal from "./disableUserModal";
import srw, * as fromSrw from "./srw";
import reports, * as fromReports from "./reports";
import header, * as fromHeader from "./header";
import systemProcess from "./systemProcess";
import charts, * as fromCharts from "./charts";
import dashboard, * as fromDashboard from "./dashboard";
import orgSettings, * as fromOrgSettings from "./orgSettings";
import homescreen from "./homescreen";
import form, * as fromForms from "./form";
import roleManagement, * as fromRoles from "./roleManagement";
import oAuthReducer from "./oAuth";
import orgClone from "./orgClone";
import fieldSizeMap from "./fieldSizeMap";
import * as fromFieldSizeMap from "./fieldSizeMap";
import * as fromOrgMembersAndGroups from "./allMembersAndGroups";
import * as fromOrgs from "./orgs";
import integrations, * as fromIntegrations from "./integrations";

import type {
  UID,
  AppState,
  Location,
  LocationPayload,
  RoomId,
  WorkflowId,
  FieldId,
  ComponentPermissionName,
  ChecklistId,
  UnifizeChatRoom,
  Group,
  OrgCloneState,
  LinkedFieldChatroom,
  ProcessTemplateId,
  ColumnId,
  TemplateStatus,
  UnifizeFile,
  ChartId,
  AuthProvider,
  Role,
  Permission,
  AllRecords,
  Action,
  ChecklistValue,
  WorkflowById,
  Workflow,
  RoleIds,
  GroupId
} from "src/types";

export const EMPTY_OBJECT = {};

const orgsPersistConfig = {
  key: "orgDetails",
  storage: localforage,
  throttle: 1500,
  whitelist: ["last"],
  stateReconciler: hardSet
};

const chatApp = combineReducers<Object, Action>({
  form,
  roleManagement,
  homescreen,
  dashboard,
  charts,
  systemProcess,
  settings,
  signupError,
  signupCode,
  canJoin,
  signup,
  chatRooms,
  currentUser,
  recentChats,
  activeChat,
  header,
  roomMembers,
  roomGroups,
  workflow,
  prompts,
  pinnedFilters,
  users,
  files,
  notification,
  checklist,
  checklistBuilder,
  workflowChecklists,
  error,
  orgs: persistReducer(orgsPersistConfig, orgs),
  showOverlay,
  modal,
  view,
  activeDock,
  uiTriggers,
  reply,
  signin,
  invitation,
  conversationDialog,
  departments,
  conversationModal,
  email,
  pendingRoomMembers,
  updates,
  rehydration,
  groups,
  userAnalytics,
  templateStatus,
  statuses,
  disableUserModal,
  srw,
  reports,
  memberAddDialog,
  orgSettings,
  alert,
  oAuth: oAuthReducer,
  orgClone,
  fieldSizeMap,
  integrations
});

export default chatApp;

export const getCurrentUser = (state: AppState) => state.currentUser;

export const getDashboardsSortedByName = (state: AppState) =>
  fromDashboard.getDashboardsSortedByName(state.dashboard);

export const getDashboard = (state: AppState, id: number) =>
  fromDashboard.getDashboard(state.dashboard, id);

export const getRecentChats = (state: AppState, roomId: string) =>
  fromRecentChats.getRecentChats(state.recentChats, roomId);

export const getLastReadTime = (state: AppState, roomId: string) =>
  fromRecentChats.getLastReadTime(state.recentChats, `${roomId}`);

export const getReport = (state: AppState, id: string) =>
  fromReports.getReport(state.reports, id);

export const getReportName = (state: AppState, id: string) =>
  fromReports.getReportName(state.reports, id);

export const getReportType = (state: AppState, id: string) =>
  fromReports.getReportType(state.reports, id);

export const getFormIdOfReport = (state: AppState, id: string) =>
  fromReports.getFormIdOfReport(state.reports, id);

export const getReportFilters = (state: AppState, id: string) =>
  fromReports.getReportFilters(state.reports, id);

export const getReadMessageCount = (state: AppState, roomId: string) =>
  fromChatRooms.getReadMessageCount(state.chatRooms, roomId);

export const getChatRoom = (state: AppState, roomId: string) =>
  fromChatRooms.getChatRoom(state.chatRooms, roomId);

export const getSeqNoForSearchedGroupedFiles = (
  state: AppState,
  roomId: string
) => fromChatRooms.getSeqNoForSearchedGroupedFiles(state.chatRooms, roomId);

export const getCurrentChatRoomFiles = (
  state: AppState,
  includeFileDetails?: boolean
) => {
  const roomId = `${state.chatRooms.current}`;
  const fileById = getAllFilesById(state).toJS();
  return fromChatRooms.getCurrentChatRoomFiles(
    state.chatRooms,
    roomId,
    fileById,
    includeFileDetails
  );
};

export const getRoomFiles = (state: AppState) => {
  const roomId = `${state.chatRooms.current}`;
  const fileIds = getRoomFileIds(state, roomId);
  const roomFiles: Array<UnifizeFile> = R.map(
    fileId => getFile(state, fileId),
    fileIds
  );
  return roomFiles;
};

export const getRoomFileIds = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getRoomFileIds(state.chatRooms, roomId);

export const getTemplateId = (state: AppState, roomId: string) =>
  fromChatRooms.getTemplateId(state.chatRooms, roomId);

export const getWorkflowSettings = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowSettings(state.workflow, id);

export const getWorkflowAdhocArchived = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowAdhocArchived(state.workflow, id);

export const getSequenceNo = (
  state: AppState,
  roomId: string,
  seqNo: ?string
) => {
  const templateId = getTemplateId(state, roomId);
  const workflowSettings = getWorkflowSettings(state, templateId);
  const { hideAutoNumber } = workflowSettings || {};

  if (hideAutoNumber) return "";

  return R.isNil(seqNo)
    ? fromChatRooms.getSequenceNo(state.chatRooms, roomId)
    : seqNo;
};

export const isCurrentVersion = (state: AppState, roomId: string) =>
  fromChatRooms.isCurrentVersion(state.chatRooms, roomId);

export const getVersionNo = (state: AppState, roomId: string) =>
  fromChatRooms.getVersionNo(state.chatRooms, roomId);

export const getMututalVersionsAndCurrentVersionId = (
  state: AppState,
  roomId: string
) =>
  fromChatRooms.getMututalVersionsAndCurrentVersionId(state.chatRooms, roomId);

export const getMutualVersions =
  (state: AppState) => (linkedField: LinkedFieldChatroom[], roomId: number) =>
    fromChatRooms.getMutualVersions(state, { linkedField, roomId });

export const getActiveState = (state: AppState, roomId: string) =>
  fromChatRooms.getActiveState(state.chatRooms, roomId);

export const getOutcome = (state: AppState, roomId: string) =>
  fromChatRooms.getOutcome(state.chatRooms, roomId);

export const getChatroomPriority = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomPriority(state.chatRooms, roomId);

export const getChatroomParticipants = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomParticipants(state.chatRooms, roomId);

export const getCanceled = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getCanceled(state.chatRooms, roomId);

export const getDescription = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getDescription(state.chatRooms, roomId);

export const getChatroomType = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomType(state.chatRooms, roomId);

export const getModalState = (modalName: string) => (state: AppState) =>
  state.modal[modalName];

export const getTotalRelatedCount = (state: AppState, roomId: string) =>
  fromChatRooms.getTotalRelatedCount(state.chatRooms, roomId);

export const getProcessTitle = (state: AppState, roomId: string) =>
  fromChatRooms.getProcessTitle(state.chatRooms, roomId);

export const getChatroomTitle = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomTitle(state.chatRooms, roomId);

export const getCreationTime = (state: AppState, roomId: string) =>
  fromChatRooms.getCreationTime(state.chatRooms, roomId);

export const getUpdationTime = (state: AppState, roomId: string) =>
  fromChatRooms.getUpdationTime(state.chatRooms, roomId);

export const getCompletionTime = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getCompletionTime(state.chatRooms, roomId);

export const getCreator = (state: AppState, roomId: string) =>
  fromChatRooms.getCreator(state.chatRooms, roomId);

export const getCreatedDate = (state: AppState, roomId: string) =>
  fromChatRooms.getCreatedDate(state.chatRooms, roomId);

export const getCompletedBy = (state: AppState, roomId: string) =>
  fromChatRooms.getCompletedBy(state.chatRooms, roomId);

export const getDirectChildren = (state: AppState, roomId: string) =>
  fromChatRooms.getDirectChildren(state.chatRooms, roomId);

export const getChildren = (state: AppState, roomId: string) =>
  fromChatRooms.getChildren(state.chatRooms, roomId);

export const getProcessTableColumns = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getProcessTableColumns(state.workflow, `${id}`);

export const isReportChanged = (state: AppState) =>
  fromWorkflow.isReportChanged(state.workflow);

export const getReportsTableColumns = (state: AppState, id: ?string) =>
  fromReports.getReportsTableColumns(state.reports, `${id || ""}`);

export const getAllReports = (state: AppState, title: string) =>
  fromReports.getAllReports(state.reports, title || "");

export const getModalReportType = (state: AppState) =>
  fromReports.getModalReportType(state.reports);

export const getReportModal = (state: AppState) =>
  fromReports.getReportModal(state.reports);

export const getNestedRows = (state: AppState, roomId: string) =>
  fromWorkflow.getNestedRows(state.workflow, roomId);

export const getProcessFilterId = (state: AppState) =>
  fromWorkflow.getProcessFilterId(state.workflow);

export const getProcessChartId = (state: AppState) =>
  fromWorkflow.getProcessChartId(state.workflow);

export const getPrincipalChecklistFieldNames = (state: AppState) =>
  fromWorkflow.getPrincipalChecklistFieldNames(state.workflow);

export const getChildrenCount = (state: AppState, roomId: string) =>
  fromChatRooms.getChildrenCount(state.chatRooms, roomId);

export const getPrincipalChecklistFieldName = (
  state: AppState,
  fieldId: string
) => fromWorkflow.getPrincipalChecklistFieldName(state.workflow, fieldId);

export const getChildCount = (state: AppState, roomId: string) =>
  fromChatRooms.getChildCount(state.chatRooms, `${roomId}`);

export const getChatRoomEmail = (state: AppState) =>
  fromChatRooms.getChatRoomEmail(state.chatRooms);

export const getMessageCount = (state: AppState, roomId: string) =>
  fromChatRooms.getMessageCount(state.chatRooms, roomId);

export const getDueDate = (state: AppState, roomId: string) =>
  fromChatRooms.getDueDate(state.chatRooms, roomId);

export const getParent = (state: AppState, roomId: string) =>
  fromChatRooms.getParent(state.chatRooms, roomId);

export const getParentsCount = (state: AppState, roomId: string) =>
  fromChatRooms.getParentsCount(state.chatRooms, roomId);

export const getFilteredUnreadConversationCount = (state: AppState) =>
  fromChatRooms.getFilteredUnreadConversationCount(
    state.chatRooms,
    state.templateStatus
  );

export const getChatRoomsErrorMessage = (state: AppState) =>
  fromChatRooms.getErrorMessage(state.chatRooms);

export const getChatroomReminders = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomReminders(state.chatRooms, roomId);

export const getChatroomManualArchived = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomManualArchived(state.chatRooms, roomId);

export const getWorkflowTemplates = (state: AppState) =>
  fromWorkflow.getWorkflowTemplates(state.workflow);

export const getWorkflowIdsForNew = (state: AppState) =>
  fromWorkflow.getWorkflowIdsForNew(state.workflow);

export const getWorkflowColor = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowColor(state.workflow, id);

export const getRecentWorkflows = (state: AppState) =>
  fromWorkflow.getRecentWorkflows(state.workflow);

export const getShowVersionInfo = (state: AppState) =>
  fromWorkflow.getShowVersionInfo(state.workflow);

export const getWorkflowName = (state: AppState, id: WorkflowId) =>
  fromChatRooms.getWorkflowName(state.chatRooms, `${id}`);

export const getCurrentRoomWorkflowTitle = (state: AppState) =>
  fromChatRooms.getCurrentRoomWorkflowTitle(state.chatRooms);

export const getPrincipalChecklistFields = (state: AppState) =>
  fromWorkflow.getPrincipalChecklistFields(state.workflow);

export const getPrincipalChecklist = (state: AppState) =>
  fromWorkflow.getPrincipalChecklist(state.workflow);

export const getAllPrincipalChecklistFields = (state: AppState) =>
  fromWorkflow.getAllPrincipalChecklistFields(state.workflow);

export const getPrincipalChecklistFieldsList = (state: AppState) =>
  fromWorkflow.getPrincipalChecklistFieldsList(state.workflow);

export const getCurrentChecklistFormWithFields = (state: AppState) =>
  fromWorkflow.getCurrentChecklistFormWithFields(
    state.workflow,
    state.form.byId
  );

export const getCurrentChecklistFormDetails = (state: AppState) =>
  fromWorkflow.getCurrentChecklistFormDetails(state.workflow, state.form.byId);

export const getRoomSearchedFilteredResults = (
  state: AppState,
  query: string
) => fromRoomMembers.getRoomSearchedFilteredResults(state, query);

export const getSortedRoomMembersAndGroups = (state: AppState) =>
  fromRoomMembers.getSortedRoomMembersAndGroups(state);

export const getRoomMembers = (state: AppState) =>
  fromRoomMembers.getRoomMembers(state);

export const getUsers = (state: AppState) => fromUsers.getUsers(state.users);

export const getUsersWithRoles = (state: AppState, roles: string[]) =>
  fromUsers.getUsersWithRoles(state.users, roles);

export const getUsersWithRoleIds = (state: AppState, roles: number[]) =>
  fromUsers.getUsersWithRoleIds(state.users, roles);

export const getUsersById = (state: AppState) =>
  fromUsers.getUsersById(state.users);

export const getUserEmailsFromIds = (state: AppState, idList: UID[]) =>
  fromUsers.getUserEmailsFromIds(state.users, idList);

export const getUserIdsByEmail = (state: AppState) =>
  fromUsers.getUserIdsByEmail(state.users);

export const getAllGroupsById = (state: AppState) =>
  fromGroups.getAllGroupsById(state.groups);

export const getGroup = (state: AppState, id: number) =>
  fromGroups.getGroup(state.groups, id);

export const getGroupMembers = (state: AppState, id: number) =>
  fromGroups.getGroupMembers(state.groups, id);

export const getGroups = (state: AppState, ids: number[]) =>
  fromGroups.getGroups(state.groups, ids);

export const getFilteredGroups = (state: AppState) =>
  fromGroups.getFilteredGroups(state.groups);

export const getFilteredGroupDetails = (state: AppState) =>
  fromGroups.getFilteredGroupDetails(state.groups);

export const getUserGroups = (state: AppState, uid: UID) =>
  fromGroups.getUserGroups(state.groups, uid);

export const getAddedOn = (state: AppState, uid: UID) =>
  fromGroups.getAddedOn(state.groups, uid);

export const getAllParticipantsCount = (state: AppState) =>
  fromGroups.getAllParticipantsCount(state);

export const getAllParticipants = (state: AppState) =>
  fromGroups.getAllParticipants(state);

export const getGroupDetails = (state: AppState, groupIds: Array<GroupId>) =>
  fromGroups.getGroupDetails(state.groups, groupIds);

export const getMembersByGroups = (state: AppState, groupIds: Array<GroupId>) =>
  fromGroups.getMembersByGroups(state.groups, groupIds);

export const getUser = (state: AppState, uid: UID) =>
  fromUsers.getUser(state.users, uid);

export const getUsersFromIds = (state: AppState, idList: Array<UID>) =>
  fromUsers.getUsersFromIds(state.users, idList);

export const getUserEmail = (state: AppState, uid: UID) =>
  fromUsers.getUserEmail(state.users, uid);

export const isUserDisabled = (state: AppState, uid: UID) =>
  fromUsers.isUserDisabled(state.users, uid);

export const getRole = (state: AppState, uid: UID) =>
  fromUsers.getRole(state.users, uid);

export const getRoleId = (state: AppState, uid: UID) =>
  fromUsers.getRoleId(state.users, uid);

export const getUserRole = (state: AppState) => fromOrg.getUserRole(state.orgs);

export const getMemberAddDialog = (state: AppState) =>
  fromMemberAddDialog.getMemberAddDialog(state);

export const getTempAttachment = (state: AppState, id: string) =>
  fromEmail.getTempAttachment(state.email, id);

export const getConversationModalVisibility = (state: AppState) =>
  fromConversationModal.getConversationModalVisibility(state.conversationModal);

export const getConversationModalId = (state: AppState) =>
  fromConversationModal.getConversationModalId(state.conversationModal);

export const getHeaderParticipants = (state: AppState) =>
  fromHeader.getHeaderParticipants(state.header);

export const getIsNavigatedFromHeader = (state: AppState) =>
  fromHeader.getIsNavigatedFromHeader(state.header);

export const hasRecipients = (state: AppState) =>
  fromEmail.hasRecipients(state.email);

export const hasNonOrgRecipients = (state: AppState) =>
  fromEmail.hasNonOrgRecipients(state.email);

export const uploadingAttachment = (state: AppState) =>
  fromEmail.uploadingAttachment(state.email);

export const getNonOrgRecipients = (state: AppState) =>
  fromEmail.getNonOrgRecipients(state.email);

export const getAllFiles = (state: AppState) =>
  fromFile.getAllFiles(state.files);

export const getAllFilesById = (state: AppState) =>
  fromFile.getFilesById(state.files);

export const getFilePreviewURL = (state: AppState, name: string) =>
  fromFile.getFilePreviewURL(state.files, name);

export const getFileThumbnailURL = (state: AppState, name: string) =>
  fromFile.getFileThumbnailURL(state.files, name);

export const getFile = (state: AppState, id: string) =>
  fromFile.getFile(state.files, id);

export const getFilteredFiles = (state: AppState, ids: Array<string>) =>
  fromFile.getFilteredFiles(state.files, ids);

export const getTempFile = (state: AppState, id: string) =>
  fromChatRooms.getTempFile(state.chatRooms, id);

export const getIsUploadingFile = (state: AppState) =>
  fromFile.getIsUploading(state.files);

export const getUploadStateById = (state: AppState, roomId: string) =>
  fromFile.getUploadStateById(state.files, roomId);

export const getIsChatroomUploadingFile = (state: AppState, roomId: string) =>
  fromFile.getIsChatroomUploadingFile(state.files, roomId);

export const getIsDownloadingFile = (state: AppState) =>
  fromFile.getIsDownloading(state.files);

export const getFolders = (state: AppState) => fromFile.getFolders(state.files);

export const getFolderRoute = (state: AppState) =>
  fromFile.getFolderRoute(state.files);

export const getBrowseFolders = (state: AppState) =>
  fromFile.getBrowseFolders(state.files);

export const getMoveToFolderPath = (state: AppState) =>
  fromFile.getFolderPath(state.files.browseFolderRoute);

export const getCurrentFile = (state: AppState) =>
  fromFile.getCurrentFile(state.files);

export const getIsMoving = (state: AppState) =>
  fromFile.getIsMoving(state.files);

export const getBrowseFolderRoute = (state: AppState) =>
  fromFile.getBrowseFolderRoute(state.files);

export const getLastFolder = (state: AppState) =>
  fromFile.getLastFolder(state.files);

export const getCurrentFolderFiles = (state: AppState) =>
  fromFile.getCurrentFolderFiles(state.files);

export const getBrowseFolderFiles = (state: AppState) =>
  fromFile.getBrowseFolderFiles(state.files);

export const getFolderFiles = (state: AppState) =>
  fromFile.getFolderFiles(state.files);

export const getCurrentFolder = (state: AppState) =>
  fromFile.getCurrentFolder(state.files);

export const getIsMovingFolder = (state: AppState) =>
  fromFile.getIsMovingFolder(state.files);

export const getActiveFileMenu = (state: AppState) =>
  fromFile.getActiveFileMenu(state.files);

export const getActiveFolderMenu = (state: AppState) =>
  fromFile.getActiveFolderMenu(state.files);

export const getIsPinningFile = (state: AppState) =>
  fromFile.getIsPinning(state.files);

export const getFileViewer = (state: AppState) =>
  fromFile.getViewer(state.files);

export const getCurrentRoom = (state: AppState) =>
  fromChatRooms.getCurrentRoom(state.chatRooms);

export const getChatroomStatus = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomStatus(state.chatRooms, roomId);

export const getCurrentRoomId = (state: AppState) =>
  fromChatRooms.getCurrentRoomId(state.chatRooms);

export const getIsCurrentRoom = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getIsCurrentRoom(state.chatRooms, roomId);

export const getCurrentChatRoom = (state: AppState) =>
  fromChatRooms.getChatRoom(state.chatRooms, state.chatRooms.current);

export const getBreadCrumbs = (state: AppState, roomId: string) =>
  fromChatRooms.getBreadCrumbs(state.chatRooms, `${roomId}`);

export const getChatroomChecklists = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomChecklists(state.chatRooms, roomId);

export const sortConversation = (
  state: AppState,
  sortBy: string,
  value: Array<number>
) => fromChatRooms.sortConversation(state.chatRooms, sortBy, value);

export const getSelectedAction = (state: AppState) =>
  fromChatRooms.getSelectedAction(state.chatRooms);

export const getSelectedRoomId = (state: AppState) =>
  fromChatRooms.getSelectedRoomId(state.chatRooms);

export const getFilteredConversations = (
  state: AppState,
  filter: Object,
  templateStatus: TemplateStatus
) =>
  fromChatRooms.getFilteredConversations(
    filter,
    templateStatus
  )(state.chatRooms);

export const getFilteredChatRooms = (state: AppState) =>
  fromChatRooms.getFilteredChatRooms(state.chatRooms, state.templateStatus);

export const getLastMessageAuthor = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getLastMessageAuthor(state.chatRooms, roomId);

export const getLastMessageText = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getLastMessageText(state.chatRooms, roomId);

export const getFilteredChatroomCount = (state: AppState) =>
  fromChatRooms.getFilteredChatroomCount(state.chatRooms, state.templateStatus);

export const getIsNotificationEnabled = (state: AppState) =>
  fromNotification.getIsNotificationEnabled(state.notification);

export const getMessageData = (state: AppState, messageId: string) =>
  fromRecentChats.getMessageData(state, messageId);

export const getMessageText = (state: AppState, messageId: string) =>
  fromRecentChats.getMessageText(state, messageId);

export const getChatroomPrivacy = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomPrivacy(state.chatRooms, roomId);

export const isProcessOwner = (state: AppState, roomId: string) => {
  const templateId = fromChatRooms.getTemplateId(state.chatRooms, `${roomId}`);
  if (templateId) {
    const processOwners =
      fromWorkflow.getWorkflow(state.workflow, `${templateId}`)
        ?.processOwners || [];
    const currentUserId = getCurrentUserId(state);
    return processOwners.includes(currentUserId);
  }
  return false;
};

export const getFilteredChatroomExcluding = (
  state: AppState,
  exclude: Array<number>
) => fromChatRooms.getFilteredChatroomExcluding(state.chatRooms, exclude);

export const getChatroomSearchResults = (state: AppState) =>
  fromChatRooms.getChatroomSearchResults(state.chatRooms);

export const filterCurrentVersions = (
  state: AppState,
  results: number[],
  selectedVersions: ?(number[])
) =>
  fromChatRooms.filterCurrentVersions(
    state.chatRooms,
    results,
    selectedVersions
  );

export const getChatroomFiles = (state: AppState) =>
  fromFile.getChatroomFiles(state.files);

export const getActiveFileTab = (state: AppState) =>
  fromFile.getActiveTab(state.files);

export const getChatRoomIsAddFile = (state: AppState) =>
  fromChatRooms.getIsAddFile(state.chatRooms);

export const getChatrooms = (state: AppState) =>
  fromChatRooms.getChatrooms(state.chatRooms);

export const getChatRoomsById = (state: AppState) =>
  fromChatRooms.getChatRoomsById(state.chatRooms);

export const getUserMembership = (state: AppState) =>
  fromChatRooms.getUserMembership(state.chatRooms);

export const getFilterText = (state: AppState) =>
  fromChatRooms.getFilterText(state.chatRooms);

export const getCurrentFilter = (state: AppState) =>
  fromChatRooms.getCurrentFilter(state.chatRooms);

export const getCurrentFilterSortBy = (state: AppState) =>
  fromChatRooms.getCurrentFilterSortBy(state.chatRooms);

export const getCurrentFilterWorkflow = (state: AppState) =>
  fromChatRooms.getCurrentFilterWorkflow(state.chatRooms);

export const getSortByModal = (state: AppState) =>
  fromChatRooms.getSortByModal(state.chatRooms);

export const getStatusModal = (state: AppState) =>
  fromChatRooms.getStatusModal(state.chatRooms);

export const getCurrentFilterActive = (state: AppState) =>
  fromChatRooms.getCurrentFilterActive(state.chatRooms);

export const getToogleUnread = (state: AppState) =>
  fromChatRooms.getToogleUnread(state.chatRooms);

export const getCustomStatuses = (state: AppState) =>
  fromChatRooms.getCustomStatuses(state.chatRooms);

export const getFileFilter = (state: AppState) =>
  fromFile.getFilter(state.files);

export const getFiles = (state: AppState) => fromFile.getFiles(state.files);

export const getFileNotificationMenu = (state: AppState) =>
  fromFile.getNotificationMenu(state.files);

export const getCurrentRoomDescription = (state: AppState) =>
  fromChatRooms.getCurrentRoomDescription(state.chatRooms);

export const getCurrentRoomOwner = (state: AppState) =>
  fromChatRooms.getCurrentRoomOwner(state.chatRooms);

export const getChatroomOwner = (state: AppState, room: RoomId) =>
  fromChatRooms.getChatroomOwner(state.chatRooms, room);

export const getRoomOwner = (state: AppState, roomId: string) =>
  fromChatRooms.getRoomOwner(state.chatRooms, `${roomId}`);

export const getChatroomAddress = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomAddress(state.chatRooms, `${roomId}`);

export const getCurrentChatroomAddress = (state: AppState) =>
  fromChatRooms.getCurrentChatroomAddress(state.chatRooms);

export const getIsQuickFilterOpen = (state: AppState) =>
  fromChatRooms.getIsQuickFilterOpen(state.chatRooms);

export const getSuggestedWorkflows = (state: AppState) =>
  fromChatRooms.getSuggestedWorkflows(state.chatRooms);

export const getCurrentActiveFilterName = (state: AppState) =>
  fromChatRooms.getCurrentActiveFilterName(state.chatRooms);

export const getChatroomTyping = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomTyping(roomId)(state.chatRooms);

export const getCurrentRevisionFile = (state: AppState) =>
  fromFile.getCurrentRevisionFile(state.files);

export const getFileSortBy = (state: AppState) =>
  fromFile.getSortBy(state.files);

export const getIsFileSortMenuOpen = (state: AppState) =>
  fromFile.getIsSortMenuOpen(state.files);

export const getChecklistTemplates = (state: AppState) =>
  fromChecklist.getChecklistTemplates(state.checklist);

export const getCurrentChecklistId = (state: AppState) =>
  fromChecklist.getCurrentChecklistId(state.checklist);

export const getChecklistFieldsById = (state: AppState) =>
  fromChecklist.getChecklistFieldsById(state.checklist);

export const getExpandedFields = (state: AppState) =>
  fromChecklist.getExpandedFields(state.checklist);

export const getExpandedPicklists = (state: AppState) =>
  fromChecklist.getExpandedPicklists(state.checklist);

export const getExpandedRows = (state: AppState) =>
  fromChecklist.getExpandedRows(state.checklist);

export const getExpansionState = (state: AppState) =>
  fromChecklist.getExpansionState(state.checklist);

export const getFormFields = (state: AppState, formId: number) =>
  fromChecklist.getFormFields(state.checklist, formId);

export const getOpenedForm = (state: AppState) =>
  fromForms.getOpenedForm(state.form);

export const getSelectedChecklist = (state: AppState) =>
  fromChecklist.getSelectedChecklist(state.checklist);

export const getSelectedChecklistFieldId = (state: AppState) =>
  fromChecklist.getSelectedChecklistFieldId(state.checklist);

export const getSelectedChecklistValue = (state: AppState) =>
  fromChecklist.getSelectedChecklistValue(state.checklist);

export const selectChecklist = (state: AppState) => state.checklist;

export const getChecklist = createSelector(
  [selectChecklist],
  checklist => checklist
);

export const getIfFormFieldsVisible = (state: AppState, fieldId: FieldId) =>
  fromWorkflow.getIfFormFieldsVisible(state.workflow, fieldId);

export const getWorkflowInstancesById = (state: AppState) =>
  fromWorkflow.getInstancesById(state.workflow);

export const getWorkflowInstancesByAutoNo = (state: AppState, autoNo: string) =>
  fromWorkflow.getInstancesByAutoNo(state.workflow, { autoNo });

export const getExpandedRowsLength = (
  state: AppState,
  autoNo: string,
  parentId: string
) => fromWorkflow.getExpandedRowsLength(state.workflow, autoNo, parentId);

export const getChatroomByColumnId = (
  state: AppState,
  autoNo: string,
  columnId: string
) => fromWorkflow.getChatroomByColumnId(state.workflow, autoNo, columnId);

export const getWorkflowInstances = (state: AppState) =>
  fromWorkflow.getWorkflowInstances(state.workflow);

export const getIsRowExpanded = (state: AppState, id: string) =>
  fromChecklist.getIsRowExpanded(state.checklist, id);

export const getCSVFieldMappings = (
  state: AppState,
  templateId: ProcessTemplateId
) => fromWorkflow.getCSVFieldMappings(state.workflow, templateId);

export const getCSVFieldMappingsLoading = (
  state: AppState,
  templateId: ProcessTemplateId
) => fromWorkflow.getCSVFieldMappingsLoading(state.workflow, templateId);

export const getCSVFieldMappingsFile = (
  state: AppState,
  templateId: ProcessTemplateId
) => fromWorkflow.getCSVFieldMappingsFile(state.workflow, templateId);

export const getAllWorkflows = (state: AppState) => state.workflow;

export const getEmbeddedFormColumns = (state: AppState) =>
  fromChecklist.getEmbeddedFormColumns(
    state.checklist,
    state.workflow.formFieldsVisibility
  );

export const getEmbeddedConversationColumns = (state: AppState) =>
  fromChecklist.getEmbeddedConversationColumns(
    state.checklist,
    state.workflow.instanceFilter.id
  );

export const getConditionsShown = (state: AppState) =>
  fromChecklistBuilder.getConditionsShown(state.checklistBuilder);

export const getConditionsSettings = (state: AppState, position: number) =>
  fromChecklistBuilder.getConditionsSettings(state.checklistBuilder, position);

export const getConditionsFieldPosition = (state: AppState) =>
  fromChecklistBuilder.getConditionsFieldPosition(state.checklistBuilder);

export const getConditionsFieldId = (state: AppState) =>
  fromChecklistBuilder.getConditionsFieldId(state.checklistBuilder);

export const getCurrentChecklistByField = (state: AppState, position: number) =>
  fromChecklistBuilder.getCurrentChecklistByField(
    state.checklistBuilder,
    position
  );

export const getChecklistFields = (state: AppState, id: string) =>
  fromChecklist.getChecklistFields(state.checklist, id);

export const getFormChecklistFields = (state: AppState, id: string) =>
  fromChecklist.getFormChecklistFields(state.checklist, id);

export const getChecklistFormValue = (state: AppState, id: string) =>
  fromChecklist.getChecklistFormValue(state.checklist, id);

export const getFieldsByChecklist = (state: AppState) =>
  fromChecklist.getFieldsByChecklist(state.checklist);

export const getChecklistFieldDetails = (state: AppState, id: string) =>
  fromChecklist.getChecklistFieldDetails(state.checklist, id);

export const getSelectedForms = (state: AppState, id: string) =>
  fromChecklist.getSelectedForms(state.checklist, id);

export const getChecklistFieldVisibility = (
  state: AppState,
  id: number,
  roomId: RoomId
) =>
  fromWorkflowChecklists.getChecklistFieldVisibility(
    state.workflowChecklists,
    id,
    roomId
  );

export const getCurrentChecklistBuilder = (state: AppState) =>
  fromChecklistBuilder.getCurrentChecklistBuilder(state.checklistBuilder);

export const getCurrentChecklistBuilderFields = (
  state: AppState,
  includeNewFields?: boolean
) =>
  fromChecklistBuilder.getCurrentChecklistBuilderFields(
    state.checklistBuilder,
    includeNewFields
  );

export const getCurrentChecklist = (state: AppState) =>
  fromChatRooms.getCurrentChecklist(state.chatRooms);

export const getFirstChecklist = (state: AppState) =>
  fromChatRooms.getFirstChecklist(state.chatRooms);

export const getChatroomHideStatus = (state: AppState, id: string) =>
  fromWorkflow.getChatroomHideStatus(state.workflow, id);

export const getWorkflowPendingStatuses = (state: AppState, id: string) =>
  fromWorkflow.getWorkflowPendingStatuses(state.workflow, id);

export const getWorkflowCompleteStatuses = (state: AppState, id: string) =>
  fromWorkflow.getWorkflowCompleteStatuses(state.workflow, id);

export const getWorkflowStatuses = (state: AppState, id: string) =>
  fromWorkflow.getWorkflowStatuses(state.workflow, id);

export const getBuilderWorkflowId = (state: AppState) =>
  fromWorkflow.getBuilderWorkflowId(state.workflow);

export const getWorkflowBuilderShown = (state: AppState) =>
  fromWorkflow.getWorkflowBuilderShown(state.workflow);

export const isStatusDisabled = (state: AppState, roomId: RoomId) => {
  const templateId = fromChatRooms.getTemplateId(state.chatRooms, `${roomId}`);
  if (templateId) {
    return fromWorkflow.getChatroomHideStatus(state.workflow, `${templateId}`);
  }
  return false;
};

export const getWorkflowBuilderPendingStatus = (state: AppState) =>
  fromWorkflow.getWorkflowBuilderPendingStatus(state.workflow);

export const getWorkflowBuilderCompletedStatus = (state: AppState) =>
  fromWorkflow.getWorkflowBuilderCompletedStatus(state.workflow);

export const getCurrentFilterTemplateId = (state: AppState) =>
  fromChatRooms.getCurrentFilterTemplateId(state.chatRooms);

export const getWorkflowsById = (state: AppState) =>
  fromWorkflow.getWorkflowsById(state.workflow);

export const getWorkflowWhitelistMembership = createSelector(
  [getCurrentFilterTemplateId, getCurrentUser, getWorkflowsById],
  (templateId, currentUser, workflowsById: WorkflowById) => {
    const workflow: Workflow = workflowsById[`${templateId}`];

    // $FlowFixMe - Optional chaining is not yet supported
    return workflow?.privacySettings?.whitelist?.includes(currentUser.uid);
  }
);

export const getChecklistFieldPosition = (state: AppState) =>
  fromWorkflow.getChecklistFieldPosition(state.workflow);

export const getChatroomMembership = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChatroomMembership(state.chatRooms, roomId);

export const getCurrentChatroomMembership = (state: AppState) =>
  fromChatRooms.getCurrentChatroomMembership(state.chatRooms);

export const getSelectedMessages = (state: AppState) =>
  fromChatRooms.getSelectedMessages(state.chatRooms);

export const getChecklistValue = (
  state: AppState,
  id: ?FieldId,
  roomId: string
) =>
  fromWorkflowChecklists.getChecklistValue(
    state.workflowChecklists,
    id,
    roomId
  );

export const getChecklistInstances = (state: AppState) =>
  fromWorkflowChecklists.getChecklistInstances(state.workflowChecklists);

export const getOrg = (state: AppState, id: number) =>
  fromOrg.getOrg(state.orgs, id);

export const getOrgName = (state: AppState, id: number) =>
  fromOrg.getOrgName(state.orgs, id);

export const getAllOrgs = (state: AppState) => fromOrg.getAllOrgs(state.orgs);

export const getAllOrgDetails = (state: AppState): any =>
  fromOrg.getAllOrgDetails(state.orgs);

export const getDefaultOrg = (state: AppState) =>
  fromOrg.getDefaultOrg(state.orgs);

export const getCurrentOrg = (state: AppState) =>
  fromOrg.getCurrentOrg(state.orgs);

export const getLastOrg = (state: AppState) => fromOrg.getLastOrg(state.orgs);

export const getReplyTo = (state: AppState) => state.reply;

export const getUserIds = (state: AppState) =>
  fromUsers.getUserIds(state.users);

export const getChecklistFieldsBySections = (state: AppState) =>
  fromChecklist.getFieldsBySections(state.checklist);

export const getRevisionField = (state: AppState, checklistId: ChecklistId) =>
  fromChecklist.getRevisionField(state.checklist, checklistId);

export const getChecklistFieldTypes = (
  state: AppState,
  fieldIds: Array<string>
) => fromChecklist.getChecklistFieldTypes(fieldIds)(state.checklist);

export const getChecklistFieldLabel = (state: AppState, fieldId: ?number) =>
  fromChecklist.getChecklistFieldLabel(fieldId)(state.checklist);

export const getChecklistFieldType = (state: AppState, fieldId: ?number) =>
  fromChecklist.getChecklistFieldType(fieldId)(state.checklist);

export const getChecklistFieldSettings = (state: AppState, fieldId: ?number) =>
  fromChecklist.getChecklistFieldSettings(fieldId)(state.checklist);

export const getChecklistFieldSourceSettings = (
  state: AppState,
  fieldId: ?number
) => fromChecklist.getChecklistFieldSourceSettings(fieldId)(state.checklist);

export const getChecklistFieldLinkedSettings = (
  state: AppState,
  fieldId: ?number
) => fromChecklist.getChecklistFieldLinkedSettings(fieldId)(state.checklist);

export const getChecklistFieldBehavior = (
  state: AppState,
  roomId: RoomId,
  fieldId: FieldId
) => fromChecklist.getChecklistFieldBehavior(state.checklist, roomId, fieldId);

export const getRoomFieldValueStatus = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
) => fromChecklist.getRoomFieldValueStatus(state.checklist, fieldId, roomId);

export const getChecklistFileUploadProgress = (
  state: AppState,
  roomId: RoomId,
  fieldId: FieldId
) =>
  fromChecklist.getChecklistFileUploadProgress(
    state.checklist,
    roomId,
    fieldId
  );

export const getFieldsBehavior = (state: AppState) =>
  fromChecklist.getFieldsBehavior(state.checklist);

export const getRoomFieldValueError = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
) => fromChecklist.getRoomFieldValueError(state.checklist, fieldId, roomId);

export const getFormFieldValueStatus = (
  state: AppState,
  fieldId: FieldId,
  formId: ?number
) => fromChecklist.getFormFieldValueStatus(state.checklist, fieldId, formId);

export const getBehaviorByFormField = (state: AppState, fieldId: string) =>
  fromChecklist.getBehaviorByFormField(state.checklist, fieldId);

export const getFormFieldLocked = (state: AppState, fieldId: string) =>
  fromChecklist.getFormFieldLocked(state.checklist, fieldId);

export const getFormFieldHidden = (state: AppState, fieldId: string) =>
  fromChecklist.getFormFieldHidden(state.checklist, fieldId);

export const getFormFieldMandatoryStatus = (state: AppState, fieldId: string) =>
  fromChecklist.getFormFieldMandatoryStatus(state.checklist, fieldId);

export const getMandatoryFieldCount = (state: AppState) =>
  fromChecklist.getMandatoryFieldCount(state.checklist);

export const getWhetherMandatoryField = (state: AppState, fieldId: FieldId) =>
  fromChecklist.getWhetherMandatoryField(state.checklist, fieldId);

export const getSectionMandatoryFieldCount = (
  state: AppState,
  params: {
    sectionId: FieldId,
    fields: Array<FieldId | Object>,
    checklistId: ChecklistId,
    roomId: RoomId
  }
) =>
  fromChecklist.getSectionMandatoryFieldCount(
    state.checklist,
    state.workflowChecklists,
    params
  );

export const getFormMandatoryFieldCount = (
  state: AppState,
  formId: number,
  roomId: RoomId
) => fromChecklist.getFormMandatoryFieldCount(state.checklist, formId, roomId);

export const getCreatingConversation = (state: AppState) =>
  fromChecklist.getCreatingConversation(state.checklist);

export const getIsFieldFetched = (state: AppState, fieldId: FieldId) =>
  fromChecklist.getIsFieldFetched(state.checklist, fieldId);

export const getLocationPayload =
  (key: string) =>
  (location: Location): LocationPayload =>
    location.payload[key];

export const getLocationType = (state: Location): string => state.type;

export const getValueFromQuery =
  (key: string) =>
  (location: Location): LocationPayload =>
    location.query && location.query[key];

export const getCurrentLocation = (state: Location): string => state.pathname;

export const getCurrentUserId = (state: AppState): string =>
  state.currentUser.uid;

export const getCurrentUserName = (state: AppState) =>
  state.currentUser.displayName;

export const getCurrentEmail = (state: AppState) => state.currentUser.email;

export const getSignUpError =
  (key: string) =>
  (state: AppState): string =>
    state.signup.error[key] || "";

export const getSignUpEmail = (state: AppState): string => state.signup.email;

export const getSignUpOrgs = (state: AppState): Array<Object> =>
  state.signup.orgs;

export const getSignInEmail = (state: AppState): string => state.signin.email;

export const getSignInError =
  (key: string) =>
  (state: AppState): string =>
    state.signin.error[key] || "";

export const getSignInResetCode = (state: AppState): string =>
  state.signin.resetCode || "";

export const getIsAuthenticationChecked = (state: AppState): boolean =>
  state.signin.isAuthenticationChecked;

export const getAuthProvider = (state: AppState): string | null =>
  fromSignin.getAuthProvider(state.signin);

export const getAuthProviderDetails = (state: AppState): AuthProvider =>
  fromSignin.getAuthProviderDetails(state.signin);

export const getUserSearchResult = (state: AppState) =>
  fromUsers.getUserSearchResult(state.users);

export const getUserMentions = (state: AppState) =>
  fromUsers.getUserMentions(state.users);

export const getUserAndGroupMentions = (state: AppState) =>
  fromUsers.getUserAndGroupMentions(state);

export const getDirectMessageMentions = (
  state: AppState,
  members: Array<UID>
) => fromUsers.getDirectMessageMentions(members)(state.users);

export const getProcessBuilderMentions = (state: AppState) =>
  fromUsers.getProcessBuilderMentions(state);

export const getChatroomFileCount = (state: AppState, roomId: string) =>
  fromChatRooms.getChatroomFileCount(state.chatRooms, roomId);

export const getCurrentRoomAccess = (state: AppState) =>
  fromChatRooms.getCurrentRoomAccess(state.chatRooms);

export const getUserNames = (state: AppState) =>
  fromChatRooms.getUserNames(state.chatRooms);

export const getWorkflow = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflow(state.workflow, id);

export const getWorkflowTitle = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowTitle(state.workflow, id);

export const getCurrentWorkflowStatus = (state: AppState) =>
  fromWorkflow.getCurrentWorkflowStatus(state.workflow);

export const getWorkflowEditable = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowEditable(state.workflow, id);

export const getUniqueValues = (state: AppState, column: string) =>
  fromWorkflow.getUniqueValues(state.workflow, column);

export const getSearchUniqueValues = (state: AppState, column: string) =>
  fromWorkflow.getSearchUniqueValues(state.workflow, column);

export const hasUniqueValue = (state: AppState, column: string) =>
  fromWorkflow.hasUniqueValue(state.workflow, column);

export const getWorkflowBuilderChecklistIds = (state: AppState) =>
  fromWorkflow.getWorkflowBuilderChecklistIds(state.workflow);

export const getInvitedOrg = (state: AppState) => state.invitation.invitedOrg;

export const getLoginAs = (state: AppState) => state.signin.loginAs;

export const getSearchString = (state: AppState) =>
  fromUsers.getSearchString(state.users);

export const getFilteredDirectMessages = (state: AppState) =>
  fromChatRooms.getFilteredDirectMessages(state.chatRooms);

export const getDepartment = (state: AppState, id: ?number) =>
  fromDepartment.getDepartment(state.departments, id);

export const getInstanceFilter = (state: AppState) =>
  fromWorkflow.getInstanceFilter(state.workflow);

export const getInstanceFilterId = (state: AppState) =>
  fromWorkflow.getInstanceFilterId(state.workflow);

export const getInstanceReportId = (state: AppState) =>
  fromWorkflow.getInstanceReportId(state.workflow);

export const getWorkflowInstanceFilter = (state: AppState) =>
  fromWorkflow.getWorkflowInstanceFilter(state.workflow);

export const getProcessFilterPage = (state: AppState) =>
  fromWorkflow.getProcessFilterPage(state.workflow);

export const getBuilderAutoReminder = (state: AppState) =>
  fromWorkflow.getBuilderAutoReminder(state.workflow);

export const getWorkflowInstanceAscending = (state: AppState) =>
  fromWorkflow.getWorkflowInstanceAscending(state.workflow);

export const getWorkflowInstancesort = (state: AppState) =>
  fromWorkflow.getWorkflowInstancesort(state.workflow);

export const getWorkflowChecklistId = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowChecklistId(state.workflow, id);

export const getCurrentEmbeddedFieldDetails = (state: AppState) =>
  fromWorkflow.getCurrentEmbeddedFieldDetails(state.workflow);

export const getAdditionalFiltersChartId = (state: AppState) =>
  fromWorkflow.getAdditionalFiltersChartId(state.workflow);

export const getPeopleTablesort = (state: AppState) =>
  fromUsers.getPeopleTablesort(state.users);

export const getSearchResult = (state: AppState) =>
  fromFile.getSearchResult(state.files);

export const getSearchedGroupedFiles = fromFile.getSearchedGroupedFiles;

export const getSingleSelectColumnNames = (state: AppState) =>
  fromWorkflow.getSingleSelectColumnNames(state.workflow);

export const getFormFieldColumnNames = (state: AppState) =>
  fromWorkflow.getFormFieldColumnNames(state.workflow);

export const getNumericalColumnNames = (state: AppState) =>
  fromWorkflow.getNumericalColumnNames(state.workflow);

export const getConversationalDialog = (state: AppState) =>
  fromConversationDialog.getConversationalDialog(state.conversationDialog);

export const isInternalOrg = (state: AppState) =>
  fromOrg.isInternalOrg(state.orgs);

export const getAllUserIds = (state: AppState) =>
  fromUsers.getAllIds(state.users);

export const isProcessRowSelected = (state: AppState, id: string) =>
  fromWorkflow.isProcessRowSelected(state.workflow, id);

export const isFormRowSelected = (state: AppState, id: string) =>
  fromForms.isFormRowSelected(state.form, id);

export const getPrivacySettings = (state: AppState) =>
  fromWorkflow.getPrivacySettings(state.workflow);

export const isGroupDetailsRowSelected = (state: AppState, uid: UID) =>
  fromGroups.isGroupDetailsRowSelected(state.groups, uid);

export const getAllGroupIds = (state: AppState) =>
  fromGroups.getAllGroupIds(state.groups);

export const getGroupTitle = (state: AppState, id: number) =>
  fromGroups.getGroupTitle(state.groups, id);

export const getSelectedPeople = (state: AppState, uid: UID) =>
  fromUsers.getSelectedPeople(state.users, uid);

export const getCurrentPromptFields = (state: AppState, roomId: RoomId) =>
  fromPrompts.getCurrentPromptFields(state.prompts, roomId);

export const getDismissed = (state: AppState) =>
  fromPrompts.getDismissed(state.prompts, state.chatRooms.current);

export const getSearchedStatus = (state: AppState) =>
  fromStatuses.getSearchedStatus(state.statuses);

export const getStatusTitle = (state: AppState, id: string) =>
  fromStatuses.getStatusTitle(state.statuses, id);

export const getWorkflowBuilderStatuses = (state: AppState): Array<Object> => {
  const rawStatuses = fromWorkflow.getWorkflowBuilderStatus(state.workflow);

  const sortedStatuses = R.sortBy(R.prop("seqNo"), rawStatuses);

  return sortedStatuses.map(status => {
    if (status.id < 0) {
      return {
        ...status,
        ...defaultStatuses[status.id]
      };
    }

    return {
      ...status,
      text: fromStatuses.getStatusTitle(state.statuses, String(status.id)),
      ...defaultStatusConfig
    };
  });
};

export const getWorkflowBuilderStatus = (state: AppState) =>
  fromWorkflow.getWorkflowBuilderStatus(state.workflow);

export const getChatroomCustomStatus = (state: AppState, roomId: RoomId) => {
  // $FlowFixMe
  const sortBySeqNo = R.sortBy(R.prop("seqNo"));
  const templateId = fromChatRooms.getTemplateId(state.chatRooms, `${roomId}`);
  return sortBySeqNo(
    fromWorkflow.getCustomStatus(state.workflow, `${templateId}`) || []
  );
};

export const getCurrentChartName = (state: AppState) =>
  fromCharts.getCurrentChartName(state.charts);

export const getTotalChartsCount = (state: AppState, reportId: number) =>
  fromCharts.getTotalChartsCount(state.charts, reportId);

export const getChart = (state: AppState, id: string) =>
  fromCharts.getChart(state.charts, id);

export const getChartLoading = (state: AppState) =>
  fromCharts.getChartLoading(state.charts);

export const getChartLastGeneratedTime = (state: AppState, id: string) =>
  fromCharts.getChartLastGeneratedTime(state.charts, id);

export const getIfChartIsRefreshing = (state: AppState, id: string) =>
  fromCharts.getIfChartIsRefreshing(state.charts, id);

export const getChartPrimaryFieldList = (state: AppState) =>
  fromCharts.getChartPrimaryFieldList(state.charts);

export const getChartPrimaryFieldListLoading = (state: AppState) =>
  fromCharts.getChartPrimaryFieldListLoading(state.charts);

export const getChartRecordCountFieldList = (state: AppState) =>
  fromCharts.getChartRecordCountFieldList(state.charts);

export const getChartFormFieldList = (state: AppState) =>
  fromCharts.getChartFormFieldList(state.charts);

export const getChartRecordCountFieldListLoading = (state: AppState) =>
  fromCharts.getChartRecordCountFieldListLoading(state.charts);

export const getChartNumericalFieldList = (state: AppState) =>
  fromCharts.getChartNumericalFieldList(state.charts);

export const getChartNumericalFieldListLoading = (state: AppState) =>
  fromCharts.getChartNumericalFieldListLoading(state.charts);

export const getChartEmbeddedNumericalFieldList = (state: AppState) =>
  fromCharts.getChartEmbeddedNumericalFieldList(state.charts);

export const getChartEmbeddedNumericalFieldListLoading = (state: AppState) =>
  fromCharts.getChartEmbeddedNumericalFieldListLoading(state.charts);

export const getChartGroupByList = (state: AppState) =>
  fromCharts.getChartGroupByList(state.charts);

export const getChartGroupByListLoading = (state: AppState) =>
  fromCharts.getChartGroupByListLoading(state.charts);

export const getAllCharts = (state: AppState) =>
  fromCharts.getAllCharts(state.charts);

export const getChartsByReport = (state: AppState, id: number) =>
  fromCharts.getChartsByReport(state.charts, id);

export const getAllChartIds = (state: AppState) =>
  fromCharts.getAllChartIds(state.charts);

export const getChartData = (state: AppState, id: string) =>
  fromCharts.getChartData(state.charts, id);

export const getChartDialog = (state: AppState) =>
  fromCharts.getChartDialog(state.charts);

export const getChartDataLoading = (state: AppState, chartId: ChartId) =>
  fromCharts.getChartDataLoading(state.charts, chartId);

export const getChartReportId = (state: AppState, id: number | string) =>
  fromCharts.getChartReportId(state.charts, id);

export const getNewChartDialogTitle = (state: AppState) =>
  fromCharts.getNewChartDialogTitle(state.charts);

export const getNewChartDialogId = (state: AppState) =>
  fromCharts.getNewChartDialogId(state.charts);

export const getWorkflowStatus = (state: AppState, templateId: number) =>
  fromWorkflow.getCustomStatus(state.workflow, `${templateId}`) || [];

export const getActiveStatus = (
  state: AppState,
  id: string,
  statusId: string
) =>
  fromTemplateStatus.getActiveStatus(
    state.templateStatus,
    `${id}`,
    `${statusId}`
  );

export const getStatusArchiveMode = (
  state: AppState,
  id: string,
  statusId: string
) =>
  fromTemplateStatus.getStatusArchiveMode(
    state.templateStatus,
    `${id}`,
    `${statusId}`
  );

export const getAllTemplateStatus = (state: AppState) =>
  fromTemplateStatus.getAllTemplateStatus(state.templateStatus);

export const getTemplateStatus = (state: AppState, id: string) =>
  fromTemplateStatus.getTemplateStatus(state.templateStatus, `${id}`);

export const getWorkflowCounts = (state: AppState, id: WorkflowId) =>
  fromWorkflow.getWorkflowCounts(state.workflow, id);

export const getPrincipalChecklistFieldSetting = (
  state: AppState,
  fieldId: string
) => fromWorkflow.getPrincipalChecklistFieldSetting(state.workflow, fieldId);

export const getLoadingInstances = (state: AppState) =>
  fromWorkflow.getLoadingInstances(state.workflow);
export const getCustomWorkflowStatuses = (state: AppState) =>
  fromWorkflow.getCustomWorkflowStatuses(state.workflow);

export const getAdditionalFilters = (state: AppState) =>
  fromWorkflow.getAdditionalFilters(state.workflow);

export const getFormFieldsVisibility = (state: AppState) =>
  fromWorkflow.getFormFieldsVisibility(state.workflow);
export const getAllRecords = (state: AppState): AllRecords =>
  fromWorkflow.getAllRecords(state.workflow);

export const getAreInstancesFormatted = (state: AppState) =>
  fromWorkflow.getAreInstancesFormatted(state.workflow);

export const getChecklistId = (state: AppState) =>
  fromWorkflow.getChecklistId(state.workflow);

export const getWorkflowAdditionalFilterChartId = (state: AppState) =>
  fromWorkflow.getWorkflowAdditionalFilterChartId(state.workflow);

export const getSection = (state: AppState, id: number) =>
  fromOrgSettings.getSection(state.orgSettings, id);

export const getTile = (state: AppState, id: number) =>
  fromOrgSettings.getTile(state.orgSettings, id);

export const getSegmentName = (state: AppState, id: number) =>
  fromOrgSettings.getSegmentName(state.orgSettings, id);

export const getSSOConfig = (state: AppState) =>
  fromOrgSettings.getSSOConfig(state.orgSettings);

export const getUserDisplayNames = (state: AppState) =>
  fromUsers.getUserDisplayNames(state.users);

export const getForm = (state: AppState, id: number) =>
  fromForms.getForm(state.form, id);

export const getFormTitle = (state: AppState, id: number) =>
  fromForms.getFormTitle(state.form, id);

export const getPermissions = (state: AppState): Array<Permission> =>
  fromRoles.getPermissions(state.roleManagement);

export const getComponentPermission = (
  state: AppState,
  component: ComponentPermissionName
) => fromRoles.getComponentPermission(state.roleManagement, component);

export const getComponentPermissionsSynced = (state: AppState) =>
  fromRoles.getComponentPermissionsSynced(state.roleManagement);

export const getRolesById = (state: AppState) =>
  fromRoles.getRolesById(state.roleManagement);

export const getRoles = (state: AppState) =>
  fromRoles.getRoles(state.roleManagement);

export const getAllRolesById = (state: AppState) =>
  fromRoles.getAllRolesById(state.roleManagement);

export const getAllRoleIds = (state: AppState) =>
  fromRoles.getAllRoleIds(state.roleManagement);

export const getRoleTitle = (state: AppState, roleId: number) =>
  fromRoles.getRoleTitle(state.roleManagement, roleId);

export const getFileUploadModalStatus = (state: AppState, viewId: string) =>
  fromModal.getFileUploadModalStatus(state.modal, viewId);

export const getCloneOrgModalStatus = (state: AppState) =>
  fromModal.getShowCloneOrgModal(state.modal);

export const getFileUploadModalFiles = (state: AppState, viewId: string) =>
  fromModal.getFileUploadModalFiles(state.modal, viewId);

export const getRoleTitles = (state: AppState) =>
  fromRoles.getRoleTitles(state.roleManagement);

export const getMembersByRole = (state: AppState, roleIds: RoleIds) =>
  fromRoles.getMembersByRole(state.roleManagement, roleIds);

export const getRolesWithComponentPermission = (
  state: AppState,
  componentName: string
) =>
  fromRoles.getRolesWithComponentPermission(
    state.roleManagement,
    componentName
  );

// Get the last room of a process visited by the user
export const getLastVisitedRoomOfProcess = (
  state: AppState,
  processId: number
) => {
  const recentChatrooms = state.recentChats.lastRead.toJS();
  const chatroomsById = getChatRoomsById(state);
  const chatrooms: UnifizeChatRoom[] = R.values(chatroomsById);

  const roomsOfThisProcess = chatrooms.filter(
    R.propEq("templateId", processId)
  );

  const lastVisitedRoom = R.head(
    R.reverse(
      R.sortBy(
        room => new Date(room.time).getTime(),
        R.map(
          roomId => ({
            time: recentChatrooms[roomId] || chatroomsById[roomId].createdAt,
            id: roomId
          }),
          R.pluck("id", roomsOfThisProcess)
        )
      )
    )
  );

  return lastVisitedRoom?.id;
};

export const isCurrentUserUnifizeEmployee = (state: AppState): boolean =>
  fromCurrentUser.isCurrentUserUnifizeEmployee(state.currentUser);

export const getOrgCloneState = (state: AppState): OrgCloneState =>
  state.orgClone;

const getUsersInGroups = (groups: Group[]): UID[] =>
  // $FlowFixMe
  R.pipe(R.pluck("members"), R.flatten, R.uniq)(groups);

export const getAuthorizedCreators = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
): Object => {
  const settings = morpheus.revision(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );

  const { authorizedToCreate = {} } = settings;
  const {
    groups = [],
    roles = [],
    users = [],
    fields = [],
    allParticipants = false,
    owner = false
  } = authorizedToCreate;
  const userFields = fields.map(field =>
    getChecklistValue(state, field, roomId)
  );
  const fieldValues = userFields.flatMap(field => field?.val?.value);
  fieldValues.forEach(item => {
    if (typeof item === "number") {
      groups.push(item);
    } else if (typeof item === "string") {
      users.push(item);
    }
  });

  const participants = allParticipants ? getAllParticipants(state) : [];
  const roomOwner = owner ? [getChatroomOwner(state, roomId)] : [];
  const membersByRole = getMembersByRole(state, roles);
  const membersByGroup = getMembersByGroups(state, groups);
  const authorizedMembers = R.uniq([
    ...participants,
    ...roomOwner,
    ...users,
    ...membersByRole,
    ...membersByGroup
  ]);
  return authorizedMembers;
};

export const getIsAuthorizedCreator = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId,
  currentUserId: UID
): boolean => {
  const allAuthorizedCreators =
    getAuthorizedCreators(state, fieldId, roomId) || [];
  return allAuthorizedCreators.includes(currentUserId);
};

export const getAuthorizedToMarkCurrentCreators = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
): Object => {
  const settings = morpheus.revision(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );

  const { authorizedToMarkCurrent = {} } = settings;
  const {
    groups = [],
    roles = [],
    users = [],
    fields = [],
    allParticipants = false,
    owner = false
  } = authorizedToMarkCurrent;
  const userFields = fields.map(field =>
    getChecklistValue(state, field, roomId)
  );
  const fieldValues = userFields.flatMap(field => field?.val?.value);
  fieldValues.forEach(item => {
    if (typeof item === "number") {
      groups.push(item);
    } else if (typeof item === "string") {
      users.push(item);
    }
  });
  const participants = allParticipants ? getAllParticipants(state) : [];
  const roomOwner = owner ? [getChatroomOwner(state, roomId)] : [];
  const membersByRole = getMembersByRole(state, roles);
  const membersByGroup = getMembersByGroups(state, groups);
  const authorizedMembers = R.uniq([
    ...participants,
    ...roomOwner,
    ...users,
    ...membersByRole,
    ...membersByGroup
  ]);
  return authorizedMembers;
};

export const getIsAuthorizedToMarkRevisionCurrent = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId,
  currentUserId: UID
): boolean => {
  const settings = morpheus.revision(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );
  const { authorizedToMarkCurrent = {} } = settings;
  const chatroomStatus = getChatroomStatus(state, roomId);
  const { statuses = [] } = authorizedToMarkCurrent;

  // Check if marking is allowed under current chatroom status.
  if (statuses.length > 0 && !statuses.includes(chatroomStatus)) return false;
  const allAuthorizedCreators =
    getAuthorizedToMarkCurrentCreators(state, fieldId, roomId) || [];
  return allAuthorizedCreators.includes(currentUserId);
};

// TODO: Move to reselect selector
export const getAllowedSignatories = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
): UID[] => {
  const settings = morpheus.approval(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );

  let allowedSignatories: UID[] = [];
  let users = [];
  let groups = [];
  let roles = [];
  let fields = [];
  let allParticipants = false;
  let owner = false;
  let preventRequester = false;

  const fieldValueDetails = getChecklistValue(state, fieldId, roomId);
  if (!fieldValueDetails) {
    if (settings?.approvers) {
      users = settings.approvers.users;
      groups = settings.approvers.groups;
      roles = settings.approvers.roles;
      fields = settings.approvers.fields || [];
      allParticipants = settings.approvers?.allParticipants;
      owner = settings.approvers?.owner;
    } else {
      return [];
    }
  }

  if (
    fieldValueDetails &&
    fieldValueDetails?.value.config !== null &&
    (fieldValueDetails?.value.config.mode === "adhoc" ||
      settings.requiredApprovers === "adhoc")
  ) {
    const value = fieldValueDetails?.value;
    users = value.config.approvers.users;
    groups = value.config.approvers.groups;
    preventRequester = settings.approvers?.preventRequester;
  } else {
    users = settings.approvers.users;
    groups = settings.approvers.groups;
    roles = settings.approvers.roles;
    fields = settings.approvers.fields || [];
    allParticipants = settings.approvers?.allParticipants;
    owner = settings.approvers?.owner;
  }

  const userFields = fields.map(field =>
    getChecklistValue(state, field, roomId)
  );

  const fieldValues = userFields.flatMap(field => field?.val?.value);
  fieldValues.forEach(item => {
    if (typeof item === "number") {
      groups.push(item);
    } else if (typeof item === "string") {
      users.push(item);
    }
  });

  allowedSignatories = allowedSignatories.concat(users);

  allowedSignatories = allowedSignatories.concat(
    Object.keys(getUsersWithRoleIds(state, roles))
  );

  allowedSignatories = allowedSignatories.concat(
    getUsersInGroups(
      R.values(R.pick(groups.map(String), getAllGroupsById(state)))
    )
  );

  if (owner) {
    allowedSignatories = allowedSignatories.concat(
      getChatroomOwner(state, roomId) || []
    );
  }

  if (allParticipants) {
    allowedSignatories = allowedSignatories.concat(getAllParticipants(state));
  }

  if (preventRequester) {
    const revisions = fieldValueDetails?.value.revisions || [];
    const approvals =
      revisions.find(revision => !revision?.deprecated)?.approvals || [];
    const requestedBy = approvals[0]?.createdBy || "";
    if (requestedBy) {
      // $FlowFixMe
      allowedSignatories = R.reject(R.equals(requestedBy), allowedSignatories);
    }
  }

  return R.uniq(allowedSignatories);
};

// TODO: Move to reselect selector
export const getAllSignatories = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
): UID[] => {
  const settings = morpheus.approval(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );

  const {
    owner = false,
    allParticipants = false,
    users,
    groups,
    roles,
    fields = []
  } = settings.approvers;

  const userFields = fields.map(field =>
    getChecklistValue(state, field, roomId)
  );

  const fieldValues = userFields.flatMap(field => field?.val?.value);
  fieldValues.forEach(item => {
    if (typeof item === "number") {
      groups.push(item);
    } else if (typeof item === "string") {
      users.push(item);
    }
  });

  let allowedSignatories: UID[] = [];

  allowedSignatories = allowedSignatories.concat(users);

  allowedSignatories = allowedSignatories.concat(
    Object.keys(getUsersWithRoleIds(state, roles))
  );

  allowedSignatories = allowedSignatories.concat(
    getUsersInGroups(
      R.values(R.pick(groups.map(String), getAllGroupsById(state)))
    )
  );

  if (owner) {
    allowedSignatories = allowedSignatories.concat(
      getChatroomOwner(state, roomId) || []
    );
  }

  if (allParticipants) {
    allowedSignatories = allowedSignatories.concat(getAllParticipants(state));
  }

  return R.uniq(allowedSignatories);
};

// TODO: Move to reselect selector
export const getAllowedCancellers = (
  state: AppState,
  fieldId: FieldId,
  roomId: RoomId
): UID[] => {
  const settings = morpheus.approval(
    JSON.parse(getChecklistFieldSettings(state, fieldId))
  );

  const {
    allParticipants = false,
    approvers = false,
    users,
    groups,
    roles,
    fields = []
  } = settings.cancellers;

  const userFields = fields.map(field =>
    getChecklistValue(state, field, roomId)
  );

  let allowedCancellers: UID[] = [];

  allowedCancellers = allowedCancellers.concat(users);
  allowedCancellers = allowedCancellers.concat(
    Object.keys(getUsersWithRoleIds(state, roles))
  );

  allowedCancellers = allowedCancellers.concat(
    getUsersInGroups(
      R.values(R.pick(groups.map(String), getAllGroupsById(state)))
    )
  );

  allowedCancellers = allowedCancellers.concat(
    userFields.flatMap(field => field?.val?.value || [])
  );

  if (allParticipants) {
    allowedCancellers = allowedCancellers.concat(getAllParticipants(state));
  }

  if (approvers) {
    allowedCancellers = allowedCancellers.concat(
      getAllowedSignatories(state, fieldId, roomId)
    );
  }

  return R.uniq(allowedCancellers);
};

export const getAllUsersAndGroupsSearchResults = (
  state: AppState,
  query: string
) => fromOrgMembersAndGroups.getAllUsersAndGroupsSearchResults(state, query);

export const getFilteredSearchResults = (
  state: AppState,
  search: Function,
  multiple: boolean,
  userAndGroupIds: Array<string | number>
) =>
  fromOrgMembersAndGroups.getFilteredSearchResults(
    state,
    search,
    multiple,
    userAndGroupIds
  );

export const getAllUsers = (state: AppState) =>
  fromOrgMembersAndGroups.getAllUsers(state);

export const getAllActiveUsers = (state: AppState) =>
  fromOrgMembersAndGroups.getAllActiveUsers(state);

export const getAllGroups = (state: AppState) =>
  fromOrgMembersAndGroups.getAllGroups(state);

export const getAllMembersAndGroups = (state: AppState) =>
  fromOrgMembersAndGroups.getAllMembersAndGroups(state);

export const getChecklistLayoutSettings = (state: AppState) =>
  fromWorkflow.getChecklistLayoutSettings(state.workflow);

export const getProcessLayoutSettings = (state: AppState, roomId: RoomId) => {
  const templateId = fromChatRooms.getTemplateId(state.chatRooms, `${roomId}`);
  return fromWorkflow.getLayoutSettings(state.workflow, `${templateId}`) || [];
};

export const getWorkflowSelectedRows = (state: AppState) =>
  fromWorkflow.getWorkflowSelectedRows(state.workflow);

export const getIsSingleResponse = (state: AppState) =>
  fromSrw.getIsSingleResponse(state.srw);

export const getFieldWidth = (state: AppState, columnId: ColumnId) =>
  fromFieldSizeMap.getFieldSize(state, columnId);

export const getChatroomAttributeWidth = (
  state: AppState,
  columnId: ColumnId
) => fromFieldSizeMap.getChatroomAttributeWidth(state.fieldSizeMap, columnId);

export const getFieldSizeMap = (state: AppState) => state.fieldSizeMap;

export const getIsSrwMobile = (state: AppState) =>
  fromSrw.getIsSrwMobile(state.srw);

export const getChecklistLoaderState = (state: AppState) =>
  fromChecklist.getChecklistLoaderState(state.checklist);

export const getFieldsByChecklistId = (state: AppState, checklistId: string) =>
  fromChecklist.getFieldsByChecklistId(state.checklist, checklistId);

export const getChecklistFieldsByForm = (state: AppState) =>
  fromChecklist.getChecklistFieldsByForm(state.checklist);

export const getAllFormTemplates = (state: AppState) =>
  fromChecklist.getAllFormTemplates(state.checklist);

export const getIsChecklistLoading = (state: AppState) =>
  fromChecklist.getIsChecklistLoading(state.checklist);

export const getRoomChecklistFields = (
  state: AppState,
  checklistId: ChecklistId
) => fromChecklist.getRoomChecklistFields(state.checklist, checklistId);

export const getIsFormCreating = (state: AppState, id: string) =>
  fromChecklist.getIsFormCreating(state.checklist, id);

export const getChecklistFieldCount = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getChecklistFieldCount(state.chatRooms, roomId);

export const getIsChecklistPresent = (state: AppState, roomId: RoomId) =>
  fromChatRooms.getIsChecklistPresent(state.chatRooms, roomId);

export const getArchivedConversations = (
  state: AppState,
  checklistValue: ChecklistValue
) => fromChatRooms.getArchivedConversations(state.chatRooms, checklistValue);

export const getIsRoomLoading = (
  state: AppState,
  chatroomAddress: string
): boolean => fromChatRooms.getIsRoomLoading(state.chatRooms, chatroomAddress);

export const getLockedFields = (state: AppState) =>
  fromWorkflowChecklists.getLockedFields(state.workflowChecklists);

export const getLockedStatus = createSelector(
  [
    getLockedFields,
    getChecklistFieldBehavior,
    (state: AppState, roomId: RoomId) => roomId,
    (state: AppState, roomId: RoomId, fieldId: FieldId) => fieldId
  ],
  (lockedFields, behavior, roomId, fieldId) => {
    const isFieldLocked = (lockedFields[roomId] || []).includes(fieldId);
    const isBehaviourDisabled =
      behavior?.current === behaviorToSettings[behaviors.disableField];
    return isFieldLocked || isBehaviourDisabled;
  }
);
export const getRoomHiddenFields = (state: AppState, roomId: RoomId) =>
  fromWorkflowChecklists.getRoomHiddenFields(state.workflowChecklists, roomId);

export const getWorkflowNextSeqNo = (state: AppState) =>
  fromWorkflow.getWorkflowNextSeqNo(state.workflow);

export const getWorkerData = createSelector(
  [
    getAllRecords,
    getAllWorkflows,
    getChecklistFieldsById,
    getWorkflowInstanceFilter,
    getWorkflowInstancesById
  ],
  (allRecords, workflow, fieldsById, instanceFilter, instancesById) => {
    const chatroomAttributes = ["owner", "dueDate", "status"];
    const updatedInstances = { ...instancesById };
    const workerData = {
      workflow,
      fieldsById,
      instancesById,
      filters: instanceFilter,
      chatroomAttributes,
      updatedInstances,
      allRecords
    };
    return workerData;
  }
);

export const getAllOrgDomains = (state: AppState) =>
  fromOrgs.getAllOrgDomains(state.orgs);

export const getUserPermissions = createSelector(
  [getCurrentUserId, getUsersById, getAllRolesById],
  (currentUid, usersById, rolesById) => {
    const currentUserRoleId = usersById[currentUid].orgRoleId;
    const currentUserRole: Role = rolesById[currentUserRoleId];

    return {
      resourcePermissions: currentUserRole?.resourcePermissions || [],
      componentPermissions: currentUserRole?.componentPermissions || []
    };
  }
);

export const getAppIntegrations = (state: AppState) =>
  fromIntegrations.getAppIntegrations(state.integrations);

export const getMs365Status = (state: AppState) =>
  fromIntegrations.getMs365Status(state.integrations);

export const getMs365AdminStatus = (state: AppState) =>
  fromIntegrations.getMs365AdminStatus(state.integrations);

export const getIsMs365Active = (state: AppState) =>
  fromIntegrations.getIsMs365Active(state.integrations);

export const getIsMs365AdminActive = (state: AppState) =>
  fromIntegrations.getIsMs365AdminActive(state.integrations);

export const getActiveDock = createSelector(
  [getIsSingleResponse, getMainActiveDock, getSRWActiveDock],
  (isSrw, main, srw) => {
    return isSrw ? srw : main;
  }
);

export const getIsFieldLocked = createSelector(
  [
    getLockedFields,
    getFormFieldLocked,
    (state, roomFieldFormId) => roomFieldFormId,
    (state, roomFieldFormId, fieldId) => fieldId,
    (state, roomFieldFormId, fieldId, roomId) => roomId
  ],
  (
    lockedFields,
    isFormFieldLocked,
    roomFieldFormId: string,
    fieldId: FieldId,
    roomId: RoomId
  ) => {
    return (lockedFields[roomId] || []).includes(fieldId) || isFormFieldLocked;
  }
);
