// @flow

import React from "react";
import { useDispatch } from "react-redux";

import RevisionCreationModal from "./Modal";
import ConfirmModal from "./ConfirmModal";

import { setChecklistValue } from "src/actions/checklist";

import type { RoomId, FieldId } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId: ?number,
  modalStage: ?number,
  revisionsCreated: boolean,
  setRevisionsCreated: boolean => void,
  setModalStage: (number | null) => void,
  promptCallback: ?Function,
  copyableFields: FieldId[]
};

const modalStages = {
  confirm: 0,
  create: 1
};

const RevisionModal = ({
  roomId,
  fieldId,
  formId,
  modalStage,
  setModalStage,
  setRevisionsCreated,
  promptCallback,
  copyableFields
}: Props) => {
  const dispatch = useDispatch();

  const create = value => {
    dispatch(
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value,
          type: "revision",
          checked: true
        },
        progress: true,
        formId
      })
    );
    setRevisionsCreated(true);
  };

  const cancel = () => {
    setModalStage(null);
    if (promptCallback) promptCallback();
  };

  const confirm = () => {
    setModalStage(modalStages.create);
  };

  switch (modalStage) {
    case modalStages.confirm:
      return <ConfirmModal open onClose={cancel} onSuccess={confirm} />;
    case modalStages.create:
      return (
        <RevisionCreationModal
          open
          onClose={cancel}
          onSuccess={create}
          defaultCopyableFields={copyableFields}
        />
      );
    default:
      return null;
  }
};

export default RevisionModal;
