// @flow

export const SIGNED_IN = "SIGNED_IN";
export const MOBILE_SSO_SIGNIN = "MOBILE_SSO_SIGNIN";
export const SIGNED_OUT = "SIGNED_OUT";
export const AUTH_CHANNEL_EVENT_COMPLETE = "AUTH_CHANNEL_EVENT_COMPLETE";

export const INITIATE_CURRENT_CHATROOM_SET = "INITIATE_CURRENT_CHATROOM_SET";
export const INITIATE_CURRENT_CHATROOM_ERROR =
  "INITIATE_CURRENT_CHATROOM_ERROR";
export const SET_CURRENT_CHATROOM_REQUEST = "SET_CURRENT_CHATROOM_REQUEST";
export const SET_CURRENT_CHATROOM_SUCCESS = "SET_CURRENT_CHATROOM_SUCCESS";
export const SET_CURRENT_CHATROOM_FAILURE = "SET_CURRENT_CHATROOM_FAILURE";
export const UPDATE_CURRENT_CHATROOM = "UPDATE_CURRENT_CHATROOM";
export const CHANGE_CURRENT_CHATROOM = "CHANGE_CURRENT_CHATROOM";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const SET_FILTER_VIEW = "SET_FILTER_VIEW";
export const SET_OWNER = "SET_OWNER";
export const SET_MINE = "SET_MINE";
export const SET_UNREAD = "SET_UNREAD";

export const TRIGGER_FILTER_UPDATE = "TRIGGER_FILTER_UPDATE";

export const SET_APPROVED = "SET_APPROVED";
export const SET_ULTIMATE_PARENT = "SET_ULTIMATE_PARENT";
export const SET_NEW_CHATS = "SET_NEW_CHATS";

export const UPLOAD_PROFILE_PHOTO = "UPLOAD_PROFILE_PHOTO";
export const UPLOAD_PROFILE_PHOTO_SUCCESS = "UPLOAD_PROFILE_PHOTO_SUCCESS";
export const UPLOAD_PROFILE_PHOTO_FAILURE = "UPLOAD_PROFILE_PHOTO_FAILURE";

export const UPLOAD_PROFILE_PICTURE_FILE = "UPLOAD_PROFILE_PICTURE_FILE";
export const UPLOAD_PROFILE_PICTURE_FILE_SUCCESS =
  "UPLOAD_PROFILE_PICTURE_FILE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_FILE_FAILURE =
  "UPLOAD_PROFILE_PICTURE_FILE_FAILURE";
export const GET_OTHER_USER_PROFILE = "GET_OTHER_USER_PROFILE";

export const EDIT_DISPLAY_NAME = "EDIT_DISPLAY_NAME";

export const SHOW_UPDATE_NAME_LOADING = "SHOW_UPDATE_NAME_LOADING";
export const HIDE_UPDATE_NAME_LOADING = "HIDE_UPDATE_NAME_LOADING";

export const TOGGLE_DISPLAY_NAME_MODAL = "TOGGLE_DISPLAY_NAME_MODAL";
export const TOGGLE_CREATE_ORG_MODAL = "TOGGLE_CREATE_ORG_MODAL";
export const TOGGLE_CLONE_ORG_MODAL = "TOGGLE_CLONE_ORG_MODAL";
export const TOGGLE_RELATED_MODAL = "TOGGLE_RELATED_MODAL";
export const TOGGLE_CHANGE_PASSWORD_MODAL = "TOGGLE_CHANGE_PASSWORD_MODAL";

export const SEARCH_RELATED_CONVERSATIONS_SUCCESS =
  "SEARCH_RELATED_CONVERSATIONS_SUCCESS";

export const CREATE_NEW_ORG_REQUEST = "CREATE_NEW_ORG_REQUEST";
export const CREATE_NEW_ORG_SUCCESS = "CREATE_NEW_ORG_SUCCESS";
export const CREATE_NEW_ORG_FAILURE = "CREATE_NEW_ORG_FAILURE";

export const UPDATE_ORG_DETAILS_REQUEST = "UPDATE_ORG_DETAILS_REQUEST";
export const UPDATE_ORG_DETAILS_SUCCESS = "UPDATE_ORG_DETAILS_SUCCESS";
export const UPDATE_ORG_DETAILS_FAILURE = "UPDATE_ORG_DETAILS_FAILURE";

export const ADD_ORG_DOMAIN_REQUEST = "ADD_ORG_DOMAIN_REQUEST";
export const ADD_ORG_DOMAIN_SUCCESS = "ADD_ORG_DOMAIN_SUCCESS";
export const ADD_ORG_DOMAIN_FAILURE = "ADD_ORG_DOMAIN_FAILURE";

export const DELETE_ORG_DOMAIN_REQUEST = "DELETE_ORG_DOMAIN_REQUEST";
export const DELETE_ORG_DOMAIN_SUCCESS = "DELETE_ORG_DOMAIN_SUCCESS";
export const DELETE_ORG_DOMAIN_FAILURE = "DELETE_ORG_DOMAIN_FAILURE";

export const CLEAR_MESSAGE_FILTER = "CLEAR_MESSAGE_FILTER";

export const SET_CHATROOM_QUERY = "SET_CHATROOM_QUERY";

export const CLEAR_ACTIVE_FILTER = "CLEAR_ACTIVE_FILTER";
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
export const SET_WORKFLOW_FILTER = "SET_WORKFLOW_FILTER";

export const UPDATE_DATE_IN_VIEW = "UPDATE_DATE_IN_VIEW";

export const UPDATE_CHATROOM_SUCCESS = "UPDATE_CHATROOM_SUCCESS";
export const UPDATE_CHATROOM_FAILURE = "UPDATE_CHATROOM_FAILURE";

export const LOAD_WORKFLOW_REQUEST = "LOAD_WORKFLOW_REQUEST";
export const LOAD_WORKFLOW_SUCCESS = "LOAD_WORKFLOW_SUCCESS";
export const LOAD_WORKFLOW_FAILURE = "LOAD_WORKFLOW_FAILURE";

export const UPDATE_CURRENT_WORKFLOW = "UPDATE_CURRENT_WORKFLOW";
export const RESET_CURRENT_WORKFLOW = "RESET_CURRENT_WORKFLOW";

export const CREATE_WORKFLOW_REQUEST = "CREATE_WORKFLOW_REQUEST";
export const CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS";
export const CREATE_WORKFLOW_FAILURE = "CREATE_WORKFLOW_FAILURE";

export const DELETE_WORKFLOW_REQUEST = "DELETE_WORKFLOW_REQUEST";
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_FAILURE = "DELETE_WORKFLOW_FAILURE";

export const SYNC_WORKFLOWS_FAILURE = "SYNC_WORKFLOWS_FAILURE";
export const SYNC_WORKFLOWS_SUCCESS = "SYNC_WORKFLOWS_SUCCESS";

export const SEARCH_USERS_REQUEST = "SEARCH_USERS_REQUEST";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export const CLEAR_USERS_SEARCH_RESULTS = "CLEAR_USERS_SEARCH_RESULTS";
export const SET_MESSAGE_FILTER = "SET_MESSAGE_FILTER";

export const SHOW_CHAT_MENU = "SHOW_CHAT_MENU";
export const HIDE_CHAT_MENU = "HIDE_CHAT_MENU";
export const SET_CHAT_MENU_COMPONENT = "SET_CHAT_MENU_COMPONENT";
export const TOGGLE_CHATMENU = "TOGGLE_CHATMENU";

export const START_FILE_SYNC = "START_FILE_SYNC";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";
export const LISTEN_FILE_CHANGE = "LISTEN_FILE_CHANGE";
export const LISTEN_FILE_CHANGE_FAILURE = "LISTEN_FILE_CHANGE_FAILURE";
export const STOP_LISTEN_FILE_CHANGE = "STOP_LISTEN_FILE_CHANGE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const RENAME_FILE_REQUEST = "RENAME_FILE_REQUEST";
export const RENAME_FILE_SUCCESS = "RENAME_FILE_SUCCESS";
export const RENAME_FILE_FAILURE = "RENAME_FILE_FAILURE";

export const MOVE_FILE_REQUEST = "MOVE_FILE_REQUEST";
export const MOVE_FILE_SUCCESS = "MOVE_FILE_SUCCESS";
export const MOVE_FILE_FAILURE = "MOVE_FILE_FAILURE";

export const CLONE_FILE_REQUEST = "CLONE_FILE_REQUEST";
export const CLONE_FILE_SUCCESS = "CLONE_FILE_SUCCESS";
export const CLONE_FILE_FAILURE = "CLONE_FILE_FAILURE";

export const FETCH_FOLDER_REQUEST = "FETCH_FOLDER_REQUEST";
export const FETCH_FOLDER_SUCCESS = "FETCH_FOLDER_SUCCESS";
export const FETCH_FOLDER_FAILURE = "FETCH_FOLDER_FAILURE";

export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";

export const RENAME_FOLDER_REQUEST = "RENAME_FOLDER_REQUEST";
export const RENAME_FOLDER_SUCCESS = "RENAME_FOLDER_SUCCESS";
export const RENAME_FOLDER_FAILURE = "RENAME_FOLDER_FAILURE";

export const MOVE_FOLDER_REQUEST = "MOVE_FOLDER_REQUEST";
export const MOVE_FOLDER_SUCCESS = "MOVE_FOLDER_SUCCESS";
export const MOVE_FOLDER_FAILURE = "MOVE_FOLDER_FAILURE";

export const STOP_MOVE_FILE_PROCESS = "STOP_MOVE_FILE_PROCESS";
export const START_MOVE_FILE_PROCESS = "START_MOVE_FILE_PROCESS";
export const START_MOVE_FOLDER_PROCESS = "START_MOVE_FOLDER_PROCESS";

export const COPY_FILE_STATE = "COPY_FILE_STATE";

export const GOTO_FOLDER = "GOTO_FOLDER";
export const EXPAND_FOLDER = "EXPAND_FOLDER";
export const BROWSE_IN_FOLDER = "BROWSE_IN_FOLDER";
export const BROWSE_BACK_FOLDER = "BROWSE_BACK_FOLDER";

export const FETCH_BROWSE_FOLDER_SUCCESS = "FETCH_BROWSE_FOLDER_SUCCESS";
export const FETCH_BROWSE_FOLDER_FAILURE = "FETCH_BROWSE_FOLDER_FAILURE";

export const SHOW_FILE_MENU = "SHOW_FILE_MENU";
export const HIDE_FILE_MENU = "HIDE_FILE_MENU";
export const SHOW_FOLDER_MENU = "SHOW_FOLDER_MENU";
export const HIDE_FOLDER_MENU = "HIDE_FOLDER_MENU";

export const START_PIN_FILE_PROCESS = "START_PIN_FILE_PROCESS";
export const STOP_PIN_FILE_PROCESS = "STOP_PIN_FILE_PROCESS";

export const PIN_FILE_REQUEST = "PIN_FILE_REQUEST";
export const PIN_FILE_SUCCESS = "PIN_FILE_SUCCESS";
export const PIN_FILE_FAILURE = "PIN_FILE_FAILURE";

export const UNPIN_FILE_REQUEST = "UNPIN_FILE_REQUEST";
export const UNPIN_FILE_SUCCESS = "UNPIN_FILE_SUCCESS";
export const UNPIN_FILE_FAILURE = "UNPIN_FILE_FAILURE";

export const OPEN_FILE_VIEWER_REQUEST = "OPEN_FILE_VIEWER_REQUEST";
export const OPEN_FILE_VIEWER = "OPEN_FILE_VIEWER";
export const OPEN_FILE_VIEWER_FAILURE = "OPEN_FILE_VIEWER_FAILURE";
export const CLOSE_FILE_VIEWER = "CLOSE_FILE_VIEWER";

export const SET_QUERY_TEXT = "SET_QUERY_TEXT";
export const CLEAR_QUERY_TEXT = "CLEAR_QUERY_TEXT";
export const SET_QUERY_TYPE = "SET_QUERY_TYPE";
export const REMOVE_QUERY_TYPE = "REMOVE_QUERY_TYPE";
export const SET_QUERY_DUE_DATE = "SET_QUERY_DUE_DATE";
export const SET_QUERY_RELATED_CONVERSATION = "SET_QUERY_RELATED_CONVERSATION";
export const SET_QUERY_ACTIVE = "SET_QUERY_ACTIVE";

export const SET_QUERY_FILTER = "SET_QUERY_FILTER";

export const START_DIRECT_CONVERSATION = "START_DIRECT_CONVERSATION";
export const START_DIRECT_CONVERSATION_SUCCESS =
  "START_DIRECT_CONVERSATION_SUCCESS";
export const START_DIRECT_CONVERSATION_FAILURE =
  "START_DIRECT_CONVERSATION_FAILURE";

export const ADD_ROOM_MEMBER_REQUEST = "ADD_ROOM_MEMBER_REQUEST";
export const ADD_ROOM_MEMBER_SUCCESS = "ADD_ROOM_MEMBER_SUCCESS";
export const ADD_ROOM_MEMBER_REQUEST_FAILURE =
  "ADD_ROOM_MEMBER_REQUEST_FAILURE";

export const ADD_ROOM_GROUP_SUCCESS = "ADD_ROOM_GROUP_SUCCESS";
export const ADD_ROOM_GROUP_FAILURE = "ADD_ROOM_GROUP_FAILURE";

export const REMOVE_ROOM_GROUP_REQUEST = "REMOVE_ROOM_GROUP_REQUEST";
export const REMOVE_ROOM_GROUP_SUCCESS = "REMOVE_ROOM_GROUP_SUCCESS";
export const REMOVE_ROOM_GROUP_FAILURE = "REMOVE_ROOM_GROUP_FAILURE";

export const START_ROOM_MEMBER_SYNC = "START_ROOM_MEMBER_SYNC";
export const ROOM_MEMBER_SYNC_FAILURE = "ROOM_MEMBER_SYNC_FAILURE";
export const STOP_ROOM_MEMBER_SYNC = "STOP_ROOM_MEMBER_SYNC";
export const ROOM_MEMBER_SYNC_SUCCESS = "ROOM_MEMBER_SYNC_SUCCESS";
export const LOAD_ROOM_MEMBERS_SUCCESS = "LOAD_ROOM_MEMBERS_SUCCESS";
export const LOAD_ROOM_MEMBERS_FAILURE = "LOAD_ROOM_MEMBERS_FAILURE";

export const UPDATE_PENDING_MEMBERS_REQUEST = "UPDATE_PENDING_MEMBERS_REQUEST";
export const UPDATE_PENDING_MEMBERS_SUCCESS = "UPDATE_PENDING_MEMBERS_SUCCESS";
export const CLEAR_PENDING_MEMBERS = "CLEAR_PENDING_MEMBERS";

export const REMOVE_ROOM_MEMBER_REQUEST = "REMOVE_ROOM_MEMBER_REQUEST";
export const REMOVE_ROOM_MEMBER_REQUEST_SUCCESS =
  "REMOVE_ROOM_MEMBER_REQUEST_SUCCESS";
export const REMOVE_ROOM_MEMBER_REQUEST_FAILURE =
  "REMOVE_ROOM_MEMBER_REQUEST_FAILURE";

export const NOTIFICATION_STATUS_CHANGED = "NOTIFICATION_STATUS_CHANGED";

export const UPLOAD_FILE_TO_CHAT_REQUEST = "UPLOAD_FILE_TO_CHAT_REQUEST";
export const UPLOAD_FILE_TO_CHAT_SUCCESS = "UPLOAD_FILE_TO_CHAT_SUCCESS";
export const UPLOAD_FILE_TO_CHAT_FAILURE = "UPLOAD_FILE_TO_CHAT_FAILURE";
export const UPLOAD_FILE_TO_CHAT_PROGRESS = "UPLOAD_FILE_TO_CHAT_PROGRESS";

export const UPLOAD_FILE_TO_TEMP_STORAGE_REQUEST =
  "UPLOAD_FILE_TO_TEMP_STORAGE_REQUEST";
export const UPLOAD_FILE_TO_TEMP_STORAGE_SUCCESS =
  "UPLOAD_FILE_TO_TEMP_STORAGE_SUCCESS";
export const UPLOAD_FILE_TO_TEMP_STORAGE_FAILURE =
  "UPLOAD_FILE_TO_TEMP_STORAGE_FAILURE";

export const CLEAR_FILE_UPLOAD_STATE = "CLEAR_FILE_UPLOAD_STATE";

export const PIN_FILE_TO_CHAT_REQUEST = "PIN_FILE_TO_CHAT_REQUEST";
export const PIN_FILE_TO_CHAT_SUCCESS = "PIN_FILE_TO_CHAT_SUCCESS";
export const PIN_FILE_TO_CHAT_FAILURE = "PIN_FILE_TO_CHAT_FAILURE";

export const CREATE_FILE_REQUEST = "CREATE_FILE_REQUEST";
export const CREATE_FILE_SUCCESS = "CREATE_FILE_SUCCESS";
export const CREATE_FILE_FAILURE = "CREATE_FILE_FAILURE";

export const FILE_TAB_CHANGE = "FILE_TAB_CHANGE";

export const GET_FILE_REQUEST = "GET_FILE_REQUEST";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_FAILURE = "GET_FILE_FAILURE";

export const START_FILES_CHANNEL = "START_FILES_CHANNEL";
export const GET_FILE_BATCH_REQUEST = "GET_FILE_BATCH_REQUEST";
export const GET_FILE_BATCH_FAILURE = "GET_FILE_BATCH_FAILURE";
export const GET_FILE_BATCH_SUCCESS = "GET_FILE_BATCH_SUCCESS";

export const SET_ERROR = "SET_ERROR";
export const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const SIGN_IN_REQUIRED = "SIGN_IN_REQUIRED";
export const INSERT_CHAT_REQUEST = "INSERT_CHAT_REQUEST";
export const INSERT_CHAT_SUCCESS = "INSERT_CHAT_SUCCESS";
export const INSERT_CHAT_FAILURE = "INSERT_CHAT_FAILURE";
export const UPDATE_LAST_READ = "UPDATE_LAST_READ";
export const SET_FAVOURITE_FILTER = "SET_FAVOURITE_FILTER";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const LISTEN_FOR_MESSAGES = "LISTEN_FOR_MESSAGES";

export const UPDATE_LAST_READ_REQUEST = "UPDATE_LAST_READ_REQUEST";
export const UPDATE_LAST_READ_SUCCESS = "UPDATE_LAST_READ_SUCCESS";
export const UPDATE_LAST_READ_FAILURE = "UPDATE_LAST_READ_FAILURE";

export const UPDATE_FAVOURITE_REQUEST = "UPDATE_FAVOURITE_REQUEST";
export const ADD_TO_FAVOURITE = "ADD_TO_FAVOURITE";
export const REMOVE_FROM_FAVOURITE = "REMOVE_FROM_FAVOURITE";
export const UPDATE_FAVOURITE_FAILURE = "UPDATE_FAVOURITE_FAILURE";

export const SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS =
  "SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS";
export const SYNC_USER_CHATROOM_ATTRIBUTES_FAILURE =
  "SYNC_USER_CHATROOM_ATTRIBUTES_FAILURE";

export const FETCH_CHATROOM = "FETCH_CHATROOM";
export const FETCH_CHATROOM_SUCCESS = "FETCH_CHATROOM_SUCCESS";
export const FETCH_CHATROOM_FAILURE = "FETCH_CHATROOM_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const READ_MESSAGE = "READ_MESSAGE";
export const READ_MESSAGE_SUCCESS = "READ_MESSAGE_SUCCESS";
export const READ_MESSAGE_FAILURE = "READ_MESSAGE_FAILURE";

export const GET_LAST_ROOM = "GET_LAST_ROOM";
export const GET_LAST_ROOM_SUCCESS = "GET_LAST_ROOM_SUCCESS";
export const GET_LAST_ROOM_FAILURE = "GET_LAST_ROOM_FAILURE";

export const LOAD_CHATROOMS_SUCCESS = "LOAD_CHATROOMS_SUCCESS";
export const LOAD_CHATROOMS_FAILURE = "LOAD_CHATROOMS_FAILURE";

export const CHATROOM_UPDATES_FAILURE = "CHATROOM_UPDATES_FAILURE";
export const LOAD_CHATROOM_SUCCESS = "LOAD_CHATROOM_SUCCESS";

export const FETCH_CHATROOM_DETAILS_REQUEST = "FETCH_CHATROOM_DETAILS_REQUEST";
export const FETCH_CHATROOM_DETAILS_SUCCESS = "FETCH_CHATROOM_DETAILS_SUCCESS";
export const FETCH_CHATROOM_DETAILS_FAILURE = "FETCH_CHATROOM_DETAILS_FAILURE";

export const REFETCH_ALL_CONVERSATIONS_REQUEST =
  "REFETCH_ALL_CONVERSATIONS_REQUEST";
export const REFETCH_ALL_CONVERSATIONS_FAILURE =
  "REFETCH_ALL_CONVERSATIONS_FAILURE";
export const REFETCH_ALL_CONVERSATIONS_SUCCESS =
  "REFETCH_ALL_CONVERSATIONS_SUCCESS";

export const LOAD_CHATROOM_REQUEST = "LOAD_CHATROOM_REQUEST";
export const LOAD_CHATROOM_FAILURE = "LOAD_CHATROOM_FAILURE";
export const FETCH_LAST_MESSAGE_SUCCESS = "FETCH_LAST_MESSAGE_SUCCESS";

export const STOP_LISTEN_CHATROOMS = "STOP_LISTEN_CHATROOMS";
export const CHATROOM_SYNC_FAILURE = "CHATROOM_SYNC_FAILURE";
export const NEW_MESSAGE_FAILURE = "NEW_MESSAGE_FAILURE";

export const START_MESSAGE_SYNC = "START_MESSAGE_SYNC";
export const CANCEL_MESSAGE_SYNC = "CANCEL_MESSAGE_SYNC";
export const RESYNC_MESSAGES = "RESYNC_MESSAGES";

export const SHOW_LOADING_CHATROOM = "SHOW_LOADING_CHATROOM";
export const HIDE_LOADING_CHATROOM = "HIDE_LOADING_CHATROOM";
export const HANDLE_MESSAGES = "HANDLE_MESSAGES";
export const NEW_MESSAGES = "NEW_MESSAGES";
export const LOAD_MORE_MESSAGES = "LOAD_MORE_MESSAGES";

export const FETCH_MESSAGE_REQUEST = "FETCH_MESSAGE_REQUEST";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const FETCH_MESSAGE_FAILURE = "FETCH_MESSAGE_FAILURE";

export const FETCH_MESSAGE_RANGE_REQUEST = "FETCH_MESSAGE_RANGE_REQUEST";
export const FETCH_MESSAGE_RANGE_SUCCESS = "FETCH_MESSAGE_RANGE_SUCCESS";
export const FETCH_MESSAGE_RANGE_FAILURE = "FETCH_MESSAGE_RANGE_FAILURE";

export const GET_USER_MEMBERSHIP = "GET_USER_MEMBERSHIP";
export const GET_USER_MEMBERSHIP_REQUEST = "GET_USER_MEMBERSHIP_REQUEST";
export const SET_USER_MEMBERSHIP_SRW = "SET_USER_MEMBERSHIP_SRW";
export const GET_USER_MEMBERSHIP_SUCCESS = "GET_USER_MEMBERSHIP_SUCCESS";
export const REMOVE_USER_MEMBERSHIP_SUCCESS = "REMOVE_USER_MEMBERSHIP_SUCCESS";
export const GET_USER_MEMBERSHIP_FAILURE = "GET_USER_MEMBERSHIP_FAILURE";
export const START_MEMBERSHIP_SYNC = "START_MEMBERSHIP_SYNC";
export const MEMBERSHIP_SYNC_FAILURE = "MEMBERSHIP_SYNC_FAILURE";
export const STOP_MEMBERSHIP_SYNC = "STOP_MEMBERSHIP_SYNC";
export const STOP_LISTEN_MEMBERSHIP = "STOP_LISTEN_MEMBERSHIP";
export const TOGGLE_CHATROOM_ADD = "TOGGLE_CHATROOM_ADD";

export const SEARCH_FILE_REQUEST = "SEARCH_FILE_REQUEST";
export const SEARCH_FILE_SUCCESS = "SEARCH_FILE_SUCCESS";
export const SEARCH_FILE_FAILURE = "SEARCH_FILE_FAILURE";
export const SEARCH_RESULTS_FILE_SUCCESS = "SEARCH_RESULTS_FILE_SUCCESS";

export const FILE_FILTER_CHANGE = "FILE_FILTER_CHANGE";

export const INCREMENT_MESSAGE_COUNT = "INCREMENT_MESSAGE_COUNT";
export const SET_MESSAGE_COUNT = "SET_MESSAGE_COUNT";

export const SYNC_LAST_READ_STATUS = "SYNC_LAST_READ_STATUS";

export const GOTO_ROOT_FOLDER = "GOTO_ROOT_FOLDER";
export const GOTO_MY_DRIVE = "GOTO_MY_DRIVE";
export const SET_MY_DRIVE = "SET_MY_DRIVE";

export const SHOW_FILE_NOTIFICATION_MENU = "SHOW_FILE_NOTIFICATION_MENU";
export const HIDE_FILE_NOTIFICATION_MENU = "HIDE_FILE_NOTIFICATION_MENU";

export const START_FILE_REVISION = "START_FILE_REVISION";
export const CANCEL_FILE_REVISION = "CANCEL_FILE_REVISION";

export const EDIT_WORKFLOW = "EDIT_WORKFLOW";
export const EDIT_WORKFLOW_SUCCESS = "EDIT_WORKFLOW_SUCCESS";
export const EDIT_WORKFLOW_FAILURE = "EDIT_WORKFLOW_FAILURE";

export const SHOW_CHATROOM_LINK = "SHOW_CHATROOM_LINK";
export const HIDE_CHATROOM_LINK = "HIDE_CHATROOM_LINK";

export const GET_FILE_VERSIONS_REQUEST = "GET_FILE_VERSIONS_REQUEST";
export const GET_FILE_VERSIONS_SUCCESS = "GET_FILE_VERSIONS_SUCCESS";
export const GET_FILE_VERSIONS_FAILURE = "GET_FILE_VERSIONS_FAILURE";
export const GET_FILE_VERSIONS_COUNT = "GET_FILE_VERSIONS_COUNT";
export const FILE_VERSIONS_COUNT = "FILE_VERSIONS_COUNT";
export const FILE_VERSIONS_COUNT_FAILURE = "FILE_VERSIONS_COUNT_FAILURE";

export const SAVE_RECENT_CHAT = "SAVE_RECENT_CHAT";
export const SAVE_RECENT_CHAT_SUCCESS = "SAVE_RECENT_CHAT_SUCCESS";
export const SAVE_RECENT_CHAT_FAILURE = "SAVE_RECENT_CHAT_FAILURE";

export const SET_SINGLE_QUERY_TYPE = "SET_SINGLE_QUERY_TYPE";

export const DELETE_MESSAGE_REQUEST = "DELETE_MESSAGE_REQUEST";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "DELETE_MESSAGE_FAILURE";

export const HIDE_MESSAGE_REQUEST = "HIDE_MESSAGE_REQUEST";
export const HIDE_MESSAGE_SUCCESS = "HIDE_MESSAGE_SUCCESS";
export const HIDE_MESSAGE_FAILURE = "HIDE_MESSAGE_FAILURE";

export const UNHIDE_MESSAGE_REQUEST = "UNHIDE_MESSAGE_REQUEST";
export const UNHIDE_MESSAGE_SUCCESS = "UNHIDE_MESSAGE_SUCCESS";
export const UNHIDE_MESSAGE_FAILURE = "UNHIDE_MESSAGE_FAILURE";

export const FORWARD_MESSAGE_REQUEST = "FORWARD_MESSAGE_REQUEST";
export const FORWARD_MESSAGE_SUCCESS = "FORWARD_MESSAGE_SUCCESS";
export const FORWARD_MESSAGE_FAILURE = "FORWARD_MESSAGE_FAILURE";

export const CHANGE_FILE_SORTING = "CHANGE_FILE_SORTING";
export const TOGGLE_FILE_SORTMENU = "TOGGLE_FILE_SORTMENU";

export const RESTORE_FILE_VERSION_REQUEST = "RESTORE_FILE_VERSION_REQUEST";
export const RESTORE_FILE_VERSION_SUCCESS = "RESTORE_FILE_VERSION_SUCCESS";
export const RESTORE_FILE_VERSION_FAILURE = "RESTORE_FILE_VERSION_FAILURE";

export const GET_CHECKLIST_REQUEST = "GET_CHECKLIST_REQUEST";
export const GET_CHECKLIST_SUCCESS = "GET_CHECKLIST_SUCCESS";
export const GET_CHECKLIST_FAILURE = "GET_CHECKLIST_FAILURE";

export const SET_CURRENT_CHECKLIST = "SET_CURRENT_CHECKLIST";
export const SET_CHECKLIST_BUILDER_ATTRIBUTES =
  "SET_CHECKLIST_BUILDER_ATTRIBUTES";
export const SET_CHECKLIST_BUILDER_ATTRIBUTE =
  "SET_CHECKLIST_BUILDER_ATTRIBUTE";
export const ADD_FIELDS_IN_CHECKLIST_BUILDER_REQUEST =
  "ADD_FIELDS_IN_CHECKLIST_BUILDER_REQUEST";
export const ADD_FIELDS_IN_CHECKLIST_BUILDER =
  "ADD_FIELDS_IN_CHECKLIST_BUILDER";
export const UPDATE_FIELD_IN_CHECKLIST_BUILDER =
  "UPDATE_FIELD_IN_CHECKLIST_BUILDER";
export const DELETE_FIELD_IN_CHECKLIST_BUILDER =
  "DELETE_FIELD_IN_CHECKLIST_BUILDER";
export const MOVE_FIELD_IN_CHECKLIST_BUILDER =
  "MOVE_FIELD_IN_CHECKLIST_BUILDER";

export const GET_ALL_CHECKLIST_TEMPLATES = "GET_ALL_CHECKLIST_TEMPLATES";

export const SHOW_CHECKLIST_BUILDER = "SHOW_CHECKLIST_BUILDER";
export const HIDE_CHECKLIST_BUILDER = "HIDE_CHECKLIST_BUILDER";
export const TOGGLE_CHECKLIST_BUILDER = "TOGGLE_CHECKLIST_BUILDER";

// export const CREATE_CHECKLIST = "CREATE_CHECKLIST";
// export const CREATE_CHECKLIST_SUCCESS = "CREATE_CHECKLIST_SUCCESS";
// export const CREATE_CHECKLIST_FAILURE = "CREATE_CHECKLIST_FAILURE";

export const GET_CHECKLIST_FIELDS_REQUEST = "GET_CHECKLIST_FIELDS_REQUEST";
export const GET_CHECKLIST_FIELDS_SUCCESS = "GET_CHECKLIST_FIELDS_SUCCESS";
export const GET_CHECKLIST_FIELDS_FAILURE = "GET_CHECKLIST_FIELDS_FAILURE";
export const GET_CHECKLIST_FIELDS_BY_SECTIONS =
  "GET_CHECKLIST_FIELDS_BY_SECTIONS";

export const GET_CHATROOM_CHECKLIST_FIELDS_REQUEST =
  "GET_CHATROOM_CHECKLIST_FIELDS_REQUEST";
export const GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS =
  "GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS";
export const GET_CHATROOM_CHECKLIST_FIELDS_FAILURE =
  "GET_CHATROOM_CHECKLIST_FIELDS_FAILURE";
export const GET_CHATROOM_CHECKLIST_FIELDS_COMPLETE =
  "GET_CHATROOM_CHECKLIST_FIELDS_COMPLETE";
export const HIDE_CHECKLIST_LOADER = "HIDE_CHECKLIST_LOADER";

export const GET_CHECKLIST_FIELD_VALUE = "GET_CHECKLIST_FIELD_VALUE";
export const GET_CHECKLIST_FIELD_VALUE_REQUEST =
  "GET_CHECKLIST_FIELD_VALUE_REQUEST";
export const GET_CHECKLIST_FIELD_VALUE_SUCCESS =
  "GET_CHECKLIST_FIELD_VALUE_SUCCESS";
export const GET_CHECKLIST_FIELD_VALUE_COMPLETE =
  "GET_CHECKLIST_FIELD_VALUE_COMPLETE";
export const GET_CHECKLIST_FIELD_VALUE_FAILURE =
  "GET_CHECKLIST_FIELD_VALUE_FAILURE";
export const GET_CHECKLIST_FIELD_VALUE_CANCELLED =
  "GET_CHECKLIST_FIELD_VALUE_CANCELLED";

export const GET_CHECKLIST_FIELD_VALUES_REQUEST =
  "GET_CHECKLIST_FIELD_VALUES_REQUEST";
export const GET_CHECKLIST_FIELD_VALUES_SUCCESS =
  "GET_CHECKLIST_FIELD_VALUES_SUCCESS";
export const GET_CHECKLIST_FIELD_VALUES_FAILURE =
  "GET_CHECKLIST_FIELD_VALUES_FAILURE";

export const SET_LOCKED_CHECKLIST_FIELDS_REQUEST =
  "SET_LOCKED_CHECKLIST_FIELDS_REQUEST";
export const SET_LOCKED_CHECKLIST_FIELDS = "SET_LOCKED_CHECKLIST_FIELDS";
export const SET_HIDDEN_CHECKLIST_FIELDS = "SET_HIDDEN_CHECKLIST_FIELDS";

export const SET_CHECKLIST_VALUE = "SET_CHECKLIST_VALUE";
export const SET_CHECKLIST_VALUE_FAILURE = "SET_CHECKLIST_VALUE_FAILURE";
export const SET_CHECKLIST_VALUE_OPTIMISTIC = "SET_CHECKLIST_VALUE_OPTIMISTIC";
export const CLEAR_CHECKLIST_FIELD_ERROR = "CLEAR_CHECKLIST_FIELD_ERROR";

export const DELETE_CHECKLIST_VALUE_SUCCESS = "DELETE_CHECKLIST_VALUE_SUCCESS";

export const GET_CHECKLIST_WORKFLOWS = "GET_CHECKLIST_WORKFLOWS";
export const GET_CHECKLIST_WORKFLOWS_SUCCESS =
  "GET_CHECKLIST_WORKFLOWS_SUCCESS";
export const GET_CHECKLIST_WORKFLOWS_FAILURE =
  "GET_CHECKLIST_WORKFLOWS_FAILURE";

export const SET_RECENT_CHAT_FILTER = "SET_RECENT_CHAT_FILTER";
export const CLEAR_RECENT_CHAT_FILTER = "CLEAR_RECENT_CHAT_FILTER";
export const TOGGLE_RECENT_CHAT_FILTER = "TOGGLE_RECENT_CHAT_FILTER";

// export const EDIT_CHECKLIST_BUILDER = "EDIT_CHECKLIST_BUILDER";
export const CLEAR_CURRENT_CHECKLIST = "CLEAR_CURRENT_CHECKLIST";
// export const EDIT_CHECKLIST_FAILURE = "EDIT_CHECKLIST_FAILURE";

export const SET_CURRENT_ORG_REQUEST = "SET_CURRENT_ORG_REQUEST";
export const SET_CURRENT_ORG_SUCCESS = "SET_CURRENT_ORG_SUCCESS";
export const SET_CURRENT_ORG_FAILURE = "SET_CURRENT_ORG_FAILURE";
export const SET_CURRENT_ORG_SRW = "SET_CURRENT_ORG_SRW";

export const GET_CURRENT_ORG_REQUEST = "GET_CURRENT_ORG_REQUEST";
export const GET_CURRENT_ORG_SUCCESS = "GET_CURRENT_ORG_SUCCESS";
export const GET_CURRENT_ORG_FAILURE = "GET_CURRENT_ORG_FAILURE";

export const GET_DEFAULT_ORG_REQUEST = "GET_DEFAULT_ORG_REQUEST";
export const GET_DEFAULT_ORG_SUCCESS = "GET_DEFAULT_ORG_SUCCESS";

export const GET_ORGS_REQUEST = "GET_ORGS_REQUEST";
export const GET_ORGS_SUCCESS = "GET_ORGS_SUCCESS";
export const GET_ORGS_FAILURE = "GET_ORGS_FAILURE";

export const GET_ORG_DETAILS_REQUEST = "GET_ORG_DETAILS_REQUEST";
export const GET_ORG_DETAILS_SUCCESS = "GET_ORG_DETAILS_SUCCESS";
export const GET_ORG_DETAILS_FAILURE = "GET_ORG_DETAILS_FAILURE";

export const SEND_AUTH_TOKEN_FAILURE = "SEND_AUTH_TOKEN_FAILURE";

export const API_AUTH_REQUEST = "API_AUTH_REQUEST";
export const API_AUTH_SUCCESS = "API_AUTH_SUCCESS";
export const API_AUTH_FAILURE = "API_AUTH_FAILURE";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const SEND_USER_INVITE_SUCCESS = "SEND_USER_INVITE_SUCCESS";
export const SEND_USER_INVITE_FAILURE = "SEND_USER_INVITE_FAILURE";

export const TOGGLE_CONTACTS_TABLE = "TOGGLE_CONTACTS_TABLE";
export const HIDE_CONTACTS_TABLE = "HIDE_CONTACTS_TABLE";
export const SHOW_CONTACTS_TABLE = "SHOW_CONTACTS_TABLE";

export const SHOW_MEMBERS = "SHOW_MEMBERS";
export const HIDE_MEMBERS = "HIDE_MEMBERS";
export const TOGGLE_MEMBERS = "TOGGLE_MEMBERS";

export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const SHOW_DOCK = "SHOW_DOCK";
export const SHOW_SRW_DOCK = "SHOW_SRW_DOCK";
export const HIDE_DOCK = "HIDE_DOCK";
export const HIDE_SRW_DOCK = "HIDE_SRW_DOCK";
export const TOGGLE_DOCK = "TOGGLE_DOCK";
export const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
export const NAVIGATED_FROM_HEADER = "NAVIGATED_FROM_HEADER";

export const GET_WORKFLOW_INSTANCES_SUCCESS = "GET_WORKFLOW_INSTANCES_SUCCESS";
export const GET_WORKFLOW_INSTANCES_FAILURE = "GET_WORKFLOW_INSTANCES_FAILURE";
export const PAGINATE_WORKFLOW_INSTANCES_SUCCESS =
  "PAGINATE_WORKFLOW_INSTANCES_SUCCESS";

export const TOGGLE_PROCESS_VERSIONS_VISIBILITY =
  "TOGGLE_PROCESS_VERSIONS_VISIBILITY";
export const UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_REQUEST =
  "UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_REQUEST";
export const UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_SUCCESS =
  "UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_SUCCESS";

export const SHOW_EDIT_WORKFLOW = "SHOW_EDIT_WORKFLOW";

export const GET_NEXT_SEQ_NO_REQUEST = "GET_NEXT_SEQ_NO_REQUEST";
export const GET_NEXT_SEQ_NO_REQUEST_SUCCESS =
  "GET_NEXT_SEQ_NO_REQUEST_SUCCESS";
export const GET_NEXT_SEQ_NO_REQUEST_FAILURE =
  "GET_NEXT_SEQ_NO_REQUEST_FAILURE";
export const CLEAR_NEXT_SEQ_NO = "CLEAR_NEXT_SEQ_NO";

export const GET_CHECKLIST_INSTANCES_SUCCESS =
  "GET_CHECKLIST_INSTANCES_SUCCESS";
export const GET_CHECKLIST_INSTANCES_FAILURE =
  "GET_CHECKLIST_INSTANCES_FAILURE";
export const CLEAR_CHECKLIST_INSTANCES = "CLEAR_CHECKLIST_INSTANCES";

export const SET_REPLY_TO = "SET_REPLY_TO";
export const CLEAR_REPLY_TO = "CLEAR_REPLY_TO";

// export const EDIT_CHECKLIST = "EDIT_CHECKLIST";
// export const EDIT_CHECKLIST_SUCCESS = "EDIT_CHECKLIST_SUCCESS";

export const SHOW_VIEW = "SHOW_VIEW";
export const HIDE_VIEW = "HIDE_VIEW";
export const TOGGLE_VIEW = "TOGGLE_VIEW";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const SET_EMAIL_MODAL_STAGE = "SET_EMAIL_MODAL_STAGE";
export const SAVE_EMAIL_MODAL_DATA = "SAVE_EMAIL_MODAL_DATA";
export const SET_EMAIL_MODAL_DATA = "SET_EMAIL_MODAL_DATA";
export const ADD_EMAIL_RECIPIENT = "ADD_EMAIL_RECIPIENT";
export const REMOVE_EMAIL_RECIPIENT = "REMOVE_EMAIL_RECIPIENT";
export const SET_RECIPIENT_ROLE = "SET_RECIPIENT_ROLE";
export const CLEAR_EMAIL_MODAL_DATA = "CLEAR_EMAIL_MODAL_DATA";
export const SET_INFO_MODAL = "SET_INFO_MODAL";
export const SHOW_INFO_MODAL = "SHOW_INFO_MODAL";
export const CLOSE_INFO_MODAL = "CLOSE_INFO_MODAL";

export const TOGGLE_FORWARD_MODAL = "TOGGLE_FORWARD_MODAL";
export const SHOW_FORWARD_MODAL = "SHOW_FORWARD_MODAL";
export const HIDE_FORWARD_MODAL = "HIDE_FORWARD_MODAL";

export const SHOW_PROMPT_RULES = "SHOW_PROMPT_RULES";
export const HIDE_PROMPT_RULES = "HIDE_PROMPT_RULES";

export const CLEAR_FILTER_TYPE = "CLEAR_FILTER_TYPE";

export const SHOW_FILTER_DIALOG = "SHOW_FILTER_DIALOG";
export const HIDE_FILTER_DIALOG = "HIDE_FILTER_DIALOG";
export const TOGGLE_FILTER_DIALOG = "TOGGLE_FILTER_DIALOG";

export const SET_SORT_BY = "SET_SORT_BY";

export const GET_RELATED_COUNT_REQUEST = "GET_RELATED_COUNT_REQUEST";
export const GET_RELATED_COUNT_FAILURE = "GET_RELATED_COUNT_FAILURE";
export const GET_RELATED_COUNT_SUCCESS = "GET_RELATED_COUNT_SUCCESS";

export const GET_RELATED_CONVERSATION_REQUEST =
  "GET_RELATED_CONVERSATION_REQUEST";
export const GET_RELATED_CONVERSATION_FAILURE =
  "GET_RELATED_CONVERSATION_FAILURE";
export const GET_RELATED_CONVERSATION_SUCCESS =
  "GET_RELATED_CONVERSATION_SUCCESS";

export const PUSH_RELATED_CONVERSATION = "PUSH_RELATED_CONVERSATION";
export const POP_RELATED_CONVERSATION = "POP_RELATED_CONVERSATION";

export const SHOW_RELATED_CONVERSATION = "SHOW_RELATED_CONVERSATION";
export const HIDE_RELATED_CONVERSATION = "HIDE_RELATED_CONVERSATION";

export const SET_CHATROOM_ATTRIBUTE_REQUEST = "SET_CHATROOM_ATTRIBUTE_REQUEST";
export const SET_CHATROOM_ATTRIBUTE_FAILURE = "SET_CHATROOM_ATTRIBUTE_FAILURE";
export const SET_CHATROOM_ATTRIBUTE_SUCCESS = "SET_CHATROOM_ATTRIBUTE_SUCCESS";
export const CHANGE_CHATROOM_ATTRIBUTE = "CHANGE_CHATROOM_ATTRIBUTE";

export const CREATE_CHATROOM_REQUEST = "CREATE_CHATROOM_REQUEST";
export const CREATE_CHATROOM_FAILURE = "CREATE_CHATROOM_FAILURE";
export const CREATE_CHATROOM_SUCCESS = "CREATE_CHATROOM_SUCCESS";

export const SET_RECENT_WORKFLOW_REQUEST = "SET_RECENT_WORKFLOW_REQUEST";
export const SET_RECENT_WORKFLOW_FAILURE = "SET_RECENT_WORKFLOW_FAILURE";
export const SET_RECENT_WORKFLOW_SUCCESS = "SET_RECENT_WORKFLOW_SUCCESS";

export const GET_RECENT_WORKFLOW_FAILURE = "GET_RECENT_WORKFLOW_FAILURE";
export const GET_RECENT_WORKFLOW_SUCCESS = "GET_RECENT_WORKFLOW_SUCCESS";

export const SET_CONVERSATION_FIELD = "SET_CONVERSATION_FIELD";
export const SET_CHECKLIST_VALUE_SUCCESS = "SET_CHECKLIST_VALUE_SUCCESS";
export const SET_EMBEDDED_VALUE_SUCCESS = "SET_EMBEDDED_VALUE_SUCCESS";

export const RESET_USER_SEARCH = "RESET_USER_SEARCH";
export const RESET_USER_SEARCH_SUCCESS = "RESET_USER_SEARCH_SUCCESS";

export const CREATE_INLINE_WORKFLOW_SUCCESS = "CREATE_INLINE_WORKFLOW_SUCCESS";
export const CREATE_INLINE_WORKFLOW_FAILURE = "CREATE_INLINE_WORKFLOW_FAILURE";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const INIT_USER = "INIT_USER";
export const INIT_USER_FAILURE = "INIT_USER_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const SIGN_IN = "SIGN_IN";
export const HOME = "HOME";
export const SIGN_UP = "SIGN_UP";
export const JOIN = "JOIN";
export const REDIRECT_TO_MOBILE_APP = "REDIRECT_TO_MOBILE_APP";

export const JOIN_ORG_REQUEST = "JOIN_ORG_REQUEST";
export const JOIN_ORG_FAILURE = "JOIN_ORG_FAILURE";
export const JOIN_ORG_SUCCESS = "JOIN_ORG_SUCCESS";

export const CONTINUE_TO_APP = "CONTINUE_TO_APP";
export const LOCATION_SNAP_SHOT = "LOCATION_SNAP_SHOT";
export const CLEAR_LOCATION_SNAP_SHOT = "CLEAR_LOCATION_SNAP_SHOT";

export const FIREBASE_SIGN_UP_REQUEST = "FIREBASE_SIGN_UP_REQUEST";
export const FIREBASE_SIGN_UP_SUCCESS = "FIREBASE_SIGN_UP_SUCCESS";
export const FIREBASE_SIGN_UP_FAILURE = "FIREBASE_SIGN_UP_FAILURE";

export const SETUP_PROFILE = "SETUP_PROFILE";
export const SETUP_PROFILE_FAILURE = "SETUP_PROFILE_FAILURE";

export const CHECK_SIGN_UP_EMAIL = "CHECK_SIGN_UP_EMAIL";
export const VALIDATE_AUTH_CODE = "VALIDATE_AUTH_CODE";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SEND_VERIFICATION_MAIL_REQUEST = "SEND_VERIFICATION_MAIL_REQUEST";
export const SEND_VERIFICATION_MAIL_SUCCESS = "SEND_VERIFICATION_MAIL_SUCCESS";
export const SEND_VERIFICATION_MAIL_FAILURE = "SEND_VERIFICATION_MAIL_FAILURE";

export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";

export const SETUP_ORG_REQUEST = "SETUP_ORG_REQUEST";
export const SETUP_ORG_SUCCESS = "SETUP_ORG_SUCCESS";
export const SETUP_ORG_FAILURE = "SETUP_ORG_FAILURE";

export const EMAIL_VALIDATION_FAILURE = "EMAIL_VALIDATION_FAILURE";

export const CLEAR_SIGNUP_ERRORS = "CLEAR_SIGNUP_ERRORS";
export const CLEAR_SIGN_IN_ERRORS = "CLEAR_SIGN_IN_ERRORS";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const SET_SIGN_UP_EMAIL = "SET_SIGN_UP_EMAIL";
export const CLEAR_SIGN_UP_EMAIL = "CLEAR_SIGN_UP_EMAIL";
export const SET_SIGN_IN_EMAIL = "SET_SIGN_IN_EMAIL";
export const SET_RESET_CODE = "SET_RESET_CODE";
export const VERIFY_RESET_CODE = "VERIFY_RESET_CODE";

export const UPLOAD_PROFILE_PICTURE_REQUEST = "UPLOAD_PROFILE_PICTURE_REQUEST";
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_FAILURE = "UPLOAD_PROFILE_PICTURE_FAILURE";

export const UPDATE_DISPLAY_NAME_SUCCESS = "UPDATE_DISPLAY_NAME_SUCCESS";
export const UPDATE_DISPLAY_NAME_REQUEST = "UPDATE_DISPLAY_NAME_REQUEST";
export const UPDATE_DISPLAY_NAME_FAILURE = "UPDATE_DISPLAY_NAME_FAILURE";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const VALIDATE_SIGN_UP_CODE_REQUEST = "VALIDATE_SIGN_UP_CODE_REQUEST";
export const VALIDATE_SIGN_UP_CODE_SUCCESS = "VALIDATE_SIGN_UP_CODE_SUCCESS";
export const VALIDATE_SIGN_UP_CODE_FAILURE = "VALIDATE_SIGN_UP_CODE_FAILURE";

export const SET_REQUESTED_CHATROOM = "SET_REQUESTED_CHATROOM";
export const CLEAR_INVITED_USER = "CLEAR_INVITED_USER";

export const LOAD_CONVERSATIONS = "LOAD_CONVERSATIONS";
export const CLEAR_SIGNUP_EMAIL = "CLEAR_SIGNUP_EMAIL";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";

export const SYNC_USERS_REQUEST = "SYNC_USERS_REQUEST";
export const SYNC_USERS_SUCCESS = "SYNC_USERS_SUCCESS";
export const SYNC_USERS_FAILURE = "SYNC_USERS_FAILURE";

export const SET_NEW_CONVERSATION_ATTRIBUTE = "SET_NEW_CONVERSATION_ATTRIBUTE";

export const SEARCH_WORKFLOW_REQUEST = "SEARCH_WORKFLOW_REQUEST";
export const SEARCH_WORKFLOW_FAILURE = "SEARCH_WORKFLOW_FAILURE";
export const SEARCH_WORKFLOW_SUCCESS = "SEARCH_WORKFLOW_SUCCESS";

export const RESET_WORKFLOW_SEARCH_REQUEST = "RESET_WORKFLOW_SEARCH_REQUEST";
export const RESET_WORKFLOW_SEARCH_SUCCESS = "RESET_WORKFLOW_SEARCH_SUCCESS";

export const SET_NEW_CONVERSATION_ATTRIBUTES =
  "SET_NEW_CONVERSATION_ATTRIBUTES";

export const CLEAR_NEW_CONVERSATION = "CLEAR_NEW_CONVERSATION";

export const SEARCH_CHATROOM_REQUEST = "SEARCH_CHATROOM_REQUEST";
export const SEARCH_CHATROOM_FAILURE = "SEARCH_CHATROOM_FAILURE";
export const SEARCH_CHATROOM_SUCCESS = "SEARCH_CHATROOM_SUCCESS";

export const RESET_CHATROOM_SEARCH = "RESET_CHATROOM_SEARCH";
export const RESET_CHATROOM_SEARCH_SUCCESS = "RESET_CHATROOM_SEARCH_SUCCESS";

export const SHOW_SAVE_PINNED_LIST = "SHOW_SAVE_PINNED_LIST";
export const HIDE_SAVE_PINNED_LIST = "HIDE_SAVE_PINNED_LIST";

export const SHOW_SORT_BY_MODAL = "SHOW_SORT_BY_MODAL";
export const HIDE_SORT_BY_MODAL = "HIDE_SORT_BY_MODAL";

export const SHOW_STATUS_MODAL = "SHOW_STATUS_MODAL";
export const HIDE_STATUS_MODAL = "HIDE_STATUS_MODAL";

export const SAVE_PINNED_LIST_REQUEST = "SAVE_PINNED_LIST_REQUEST";
export const SAVE_PINNED_LIST_FAILURE = "SAVE_PINNED_LIST_FAILURE";
export const SAVE_PINNED_LIST_SUCCESS = "SAVE_PINNED_LIST_SUCCESS";

export const DELETE_PINNED_LIST_REQUEST = "DELETE_PINNED_LIST_REQUEST";
export const DELETE_PINNED_LIST_FAILURE = "DELETE_PINNED_LIST_FAILURE";

export const EDIT_PINNED_LIST_REQUEST = "EDIT_PINNED_LIST_REQUEST";
export const EDIT_PINNED_LIST_FAILURE = "EDIT_PINNED_LIST_FAILURE";
export const EDIT_PINNED_LIST_SUCCESS = "EDIT_PINNED_LIST_SUCCESS";

export const SYNC_PINNED_LIST_FAILURE = "SYNC_PINNED_LIST_FAILURE";
export const SYNC_PINNED_LIST_SUCCESS = "SYNC_PINNED_LIST_SUCCESS";

export const SET_AVAILABLE_ORGS = "SET_AVAILABLE_ORGS";

export const JOIN_EXISTING_ORG_REQUEST = "JOIN_EXISTING_ORG_REQUEST";
export const JOIN_EXISTING_ORG_FAILURE = "JOIN_EXISTING_ORG_FAILURE";

export const LOAD_GENERAL_CHATROOM = "LOAD_GENERAL_CHATROOM";
export const LOAD_REQUESTED_CHATROOM = "LOAD_REQUESTED_CHATROOM";

export const FOCUS_CHAT_INPUT = "FOCUS_CHAT_INPUT";
export const RESET_CHAT_INPUT_FOCUS = "RESET_CHAT_INPUT_FOCUS";

export const PRESERVE_CHAT_INPUT = "PRESERVE_CHAT_INPUT";
export const CLEAR_CHAT_INPUT = "CLEAR_CHAT_INPUT";

export const LOGIN_AS_REQUEST = "LOGIN_AS_REQUEST";
export const LOGIN_AS_SUCCESS = "LOGIN_AS_SUCCESS";
export const LOGIN_AS_FAILURE = "LOGIN_AS_FAILURE";

export const LOGIN_WITH_TOKEN_REQUEST = "LOGIN_WITH_TOKEN_REQUEST";
export const LOGIN_WITH_TOKEN_SUCCESS = "LOGIN_WITH_TOKEN_SUCCESS";
export const LOGIN_WITH_TOKEN_FAILURE = "LOGIN_WITH_TOKEN_FAILURE";

export const CANCEL_CHATROOM_REQUEST = "CANCEL_CHATROOM_REQUEST";
export const CANCEL_CHATROOM_FAILURE = "CANCEL_CHATROOM_FAILURE";
export const CANCEL_CHATROOM_SUCCESS = "CANCEL_CHATROOM_SUCCESS";

export const REMOVE_USERS = "REMOVE_USERS";

export const SET_CONTACTS_REQUEST = "SET_CONTACTS_REQUEST";
export const SET_CONTACTS_FAILURE = "SET_CONTACTS_FAILURE";
export const SET_CONTACTS_SUCCESS = "SET_CONTACTS_SUCCESS";

export const SET_PROCESS_REQUEST = "SET_PROCESS_REQUEST";
export const SET_PROCESS_FAILURE = "SET_PROCESS_FAILURE";
export const SET_PROCESS_SUCCESS = "SET_PROCESS_SUCCESS";

export const EDIT_PROCESS_REQUEST = "EDIT_PROCESS_REQUEST";
export const EDIT_PROCESS_FAILURE = "EDIT_PROCESS_FAILURE";
export const EDIT_PROCESS_SUCCESS = "EDIT_PROCESS_SUCCESS";

export const CREATE_PROCESS_REQUEST = "CREATE_PROCESS_REQUEST";
export const CREATE_PROCESS_FAILURE = "CREATE_PROCESS_FAILURE";
export const CREATE_PROCESS_SUCCESS = "CREATE_PROCESS_SUCCESS";

export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";

export const HIDE_REPORTS_SAVE_MODAL = "HIDE_REPORTS_SAVE_MODAL";
export const SHOW_REPORTS_SAVE_MODAL = "SHOW_REPORTS_SAVE_MODAL";

export const DELETE_REPORT_REQUEST = "DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";

export const EDIT_REPORT_REQUEST = "EDIT_REPORT_REQUEST";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAILURE = "EDIT_REPORT_FAILURE";

export const UPDATE_REPORT_TITLE_REQUEST = "UPDATE_REPORT_TITLE_REQUEST";
export const UPDATE_REPORT_TITLE_FAILURE = "UPDATE_REPORT_TITLE_FAILURE";
export const UPDATE_REPORT_TITLE_SUCCESS = "UPDATE_REPORT_TITLE_SUCCESS";

export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";

export const SET_REPORTS_REQUEST = "SET_REPORTS_REQUEST";
export const SET_REPORTS_FAILURE = "SET_REPORTS_FAILURE";
export const SET_REPORTS_SUCCESS = "SET_REPORTS_SUCCESS";

export const SET_ACTIVE_REPORT_REQUEST = "SET_ACTIVE_REPORT_REQUEST";
export const SET_ACTIVE_REPORT_SUCCESS = "SET_ACTIVE_REPORT_SUCCESS";
export const SET_ACTIVE_REPORT_FAILURE = "SET_ACTIVE_REPORT_FAILURE";

export const SET_FORMS_REQUEST = "SET_FORMS_REQUEST";
export const SET_FORMS_FAILURE = "SET_FORMS_FAILURE";
export const SET_FORMS_SUCCESS = "SET_FORMS_SUCCESS";

// used by sagas to begin the navigation to process
export const NAVIGATE_TO_PROCESS = "NAVIGATE_TO_PROCESS";
// used by sagas to set an active report en route to process
export const REPORTS_TO_PROCESS = "REPORTS_TO_PROCESS";

export const GET_CHATROOM_CHECKLISTS_SUCCESS =
  "GET_CHATROOM_CHECKLISTS_SUCCESS";
export const GET_CHATROOM_CHECKLISTS_FAILURE =
  "GET_CHATROOM_CHECKLISTS_FAILURE";

export const SET_REQUESTED_PAGE = "SET_REQUESTED_PAGE";

export const SET_INVOLVED_PROCESSES = "SET_INVOLVED_PROCESSES";

export const SYNC_STANDARD_FILTER_LAST_READ_SUCCESS =
  "SYNC_STANDARD_FILTER_LAST_READ_SUCCESS";
export const SYNC_CUSTOM_FILTER_LAST_READ_SUCCESS =
  "SYNC_CUSTOM_FILTER_LAST_READ_SUCCESS";

export const SET_STANDARD_FILTER_LAST_UPDATED =
  "SET_STANDARD_FILTER_LAST_UPDATED";
export const SET_CUSTOM_FILTER_LAST_UPDATED_REQUEST =
  "SET_CUSTOM_FILTER_LAST_UPDATED_REQUEST";
export const SET_CUSTOM_FILTER_LAST_UPDATED_SUCCESS =
  "SET_CUSTOM_FILTER_LAST_UPDATED_SUCCESS";
export const SET_CUSTOM_FILTER_LAST_UPDATED_FAILURE =
  "SET_CUSTOM_FILTER_LAST_UPDATED_FAILURE";

export const SET_STANDARD_FILTER_LAST_READ_REQUEST =
  "SET_STANDARD_FILTER_LAST_READ_REQUEST";
export const SET_STANDARD_FILTER_LAST_READ_SUCCESS =
  "SET_STANDARD_FILTER_LAST_READ_SUCCESS";
export const SET_STANDARD_FILTER_LAST_READ_FAILURE =
  "SET_STANDARD_FILTER_LAST_READ_FAILURE";

export const SET_CUSTOM_FILTER_LAST_READ_REQUEST =
  "SET_CUSTOM_FILTER_LAST_READ_REQUEST";
export const SET_CUSTOM_FILTER_LAST_READ_SUCCESS =
  "SET_CUSTOM_FILTER_LAST_READ_SUCCESS";
export const SET_CUSTOM_FILTER_LAST_READ_FAILURE =
  "SET_CUSTOM_FILTER_LAST_READ_FAILURE";

export const REMOVE_CHATROOM = "REMOVE_CHATROOM";
export const NO_NETWORK = "NO_NETWORK";

export const CHANGE_CONVERSATION_TYPE_REQUEST =
  "CHANGE_CONVERSATION_TYPE_REQUEST";
export const CHANGE_CONVERSATION_TYPE_FAILURE =
  "CHANGE_CONVERSATION_TYPE_FAILURE";
export const CHANGE_CONVERSATION_TYPE_SUCCESS =
  "CHANGE_CONVERSATION_TYPE_SUCCESS";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";

export const SYNC_DEPARTMENTS_FAILURE = "SYNC_DEPARTMENTS_FAILURE";
export const SYNC_DEPARTMENTS_SUCCESS = "SYNC_DEPARTMENTS_SUCCESS";

export const CLEAR_SIGNUP_DETAILS = "CLEAR_SIGNUP_DETAILS";

export const SERVER_AUTH_REQUEST = "SERVER_AUTH_REQUEST";
export const SERVER_AUTH_SUCCESS = "SERVER_AUTH_SUCCESS";
export const SERVER_AUTH_FAILURE = "SERVER_AUTH_FAILURE";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";

export const CLEAR_LAST_CREATED_WORKFLOW = "CLEAR_LAST_CREATED_WORKFLOW";
export const CLEAR_LAST_CREATED_CHECKLIST = "CLEAR_LAST_CREATED_CHECKLIST";

export const REMOVE_WORKFLOW_SUCCESS = "REMOVE_WORKFLOW_SUCCESS";

export const GET_PROCESS_UNIQUE_INSTANCE_VALUES_REQUEST =
  "GET_PROCESS_UNIQUE_INSTANCE_VALUES_REQUEST";
export const GET_PROCESS_UNIQUE_INSTANCE_VALUES_FAILURE =
  "GET_PROCESS_UNIQUE_INSTANCE_VALUES_FAILURE";
export const GET_PROCESS_UNIQUE_INSTANCE_VALUES_SUCCESS =
  "GET_PROCESS_UNIQUE_INSTANCE_VALUES_SUCCESS";

export const GET_PROCESS_INSTANCE_FILE_REQUEST =
  "GET_PROCESS_INSTANCE_FILE_REQUEST";
export const GET_PROCESS_INSTANCE_FILE_FAILURE =
  "GET_PROCESS_INSTANCE_FILE_FAILURE";
export const GET_PROCESS_INSTANCE_FILE_SUCCESS =
  "GET_PROCESS_INSTANCE_FILE_SUCCESS";

export const GET_FORM_INSTANCE_FILE_REQUEST = "GET_FORM_INSTANCE_FILE_REQUEST";
export const GET_FORM_INSTANCE_FILE_FAILURE = "GET_FORM_INSTANCE_FILE_FAILURE";
export const GET_FORM_INSTANCE_FILE_SUCCESS = "GET_FORM_INSTANCE_FILE_SUCCESS";

export const OPEN_CONVERSATION_MODAL = "OPEN_CONVERSATION_MODAL";
export const CLOSE_CONVERSATION_MODAL = "CLOSE_CONVERSATION_MODAL";

export const LOAD_UNREAD_MESSAGES_REQUEST = "LOAD_UNREAD_MESSAGES_REQUEST";
export const LOAD_UNREAD_MESSAGES_FAILURE = "LOAD_UNREAD_MESSAGES_FAILURE";
export const LOAD_UNREAD_MESSAGES_SUCCESS = "LOAD_UNREAD_MESSAGES_SUCCESS";

export const SET_LAST_READ_MESSAGE = "SET_LAST_READ_MESSAGE";

export const REFETCH_WORKFLOW_INSTANCES = "REFETCH_WORKFLOW_INSTANCES";

export const SET_CURRENT_CHATROOM_ALIAS = "SET_CURRENT_CHATROOM_ALIAS";

export const GET_CHECKLIST_INSTANCE_FILE_REQUEST =
  "GET_CHECKLIST_INSTANCE_FILE_REQUEST";
export const GET_CHECKLIST_INSTANCE_FILE_FAILURE =
  "GET_CHECKLIST_INSTANCE_FILE_FAILURE";
export const GET_CHECKLIST_INSTANCE_FILE_SUCCESS =
  "GET_CHECKLIST_INSTANCE_FILE_SUCCESS";

export const GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_REQUEST =
  "GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_REQUEST";
export const GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_FAILURE =
  "GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_FAILURE";
export const GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_SUCCESS =
  "GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_SUCCESS";

export const ATTACH_FILE_TO_EMAIL_REQUEST = "ATTACH_FILE_TO_EMAIL_REQUEST";
export const ATTACH_FILE_TO_EMAIL_FAILURE = "ATTACH_FILE_TO_EMAIL_FAILURE";

export const CLEAR_ATTACHMENT = "CLEAR_ATTACHMENT";
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";

export const CLEAR_TEMP_ATTACHMENT = "CLEAR_TEMP_ATTACHMENT";
export const CLEAR_ALL_TEMP_ATTACHMENTS = "CLEAR_ALL_TEMP_ATTACHMENTS";

export const UPDATE_TEMP_ATTACHMENT_PROGRESS =
  "UPDATE_TEMP_ATTACHMENT_PROGRESS";
export const ADD_TEMP_ATTACHMENT_SUCCESS = "ADD_TEMP_ATTACHMENT_SUCCESS";
export const ADD_TEMP_ATTACHMENT_FAILURE = "ADD_TEMP_ATTACHMENT_FAILURE";

export const GENERATE_CHATROOM_EMAIL_REQUEST =
  "GENERATE_CHATROOM_EMAIL_REQUEST";
export const GENERATE_CHATROOM_EMAIL_FAILURE =
  "GENERATE_CHATROOM_EMAIL_FAILURE";
export const GENERATE_CHATROOM_EMAIL_SUCCESS =
  "GENERATE_CHATROOM_EMAIL_SUCCESS";

export const SET_FILE_REQUEST = "SET_FILE_REQUEST";
export const SET_FILE_FAILURE = "SET_FILE_FAILURE";
export const SET_FILE_SUCCESS = "SET_FILE_SUCCESS";

export const SET_WORKFLOW_BUILDER_ATTRIBUTE = "SET_WORKFLOW_BUILDER_ATTRIBUTE";
export const SET_WORKFLOW_BUILDER_ATTRIBUTES =
  "SET_WORKFLOW_BUILDER_ATTRIBUTES";

export const SHOW_WORKFLOW_BUILDER = "SHOW_WORKFLOW_BUILDER";
export const HIDE_WORKFLOW_BUILDER = "HIDE_WORKFLOW_BUILDER";

export const SEARCH_CHECKLIST_REQUEST = "SEARCH_CHECKLIST_REQUEST";
export const SEARCH_CHECKLIST_FAILURE = "SEARCH_CHECKLIST_FAILURE";
export const SEARCH_CHECKLIST_SUCCESS = "SEARCH_CHECKLIST_SUCCESS";
export const CLEAR_CHECKLIST_SEARCH = "CLEAR_CHECKLIST_SEARCH";

export const CREATE_CONVERSATION_FROM_CHECKLIST_REQUEST =
  "CREATE_CONVERSATION_FROM_CHECKLIST_REQUEST";
export const CREATE_CONVERSATION_FROM_CHECKLIST_FAILURE =
  "CREATE_CONVERSATION_FROM_CHECKLIST_FAILURE";
export const CREATE_CONVERSATION_FROM_CHECKLIST_SUCCESS =
  "CREATE_CONVERSATION_FROM_CHECKLIST_SUCCESS";

export const GET_PRINCIPAL_CHECKLIST_REQUEST =
  "GET_PRINCIPAL_CHECKLIST_REQUEST";
export const GET_PRINCIPAL_CHECKLIST_FAILURE =
  "GET_PRINCIPAL_CHECKLIST_FAILURE";
export const GET_PRINCIPAL_CHECKLIST_SUCCESS =
  "GET_PRINCIPAL_CHECKLIST_SUCCESS";

export const LOADED_CONVERSATIONS = "LOADED_CONVERSATIONS";

export const OPEN_MANAGE_PAGE = "OPEN_MANAGE_PAGE";
export const OPEN_CONTACTS_PAGE = "OPEN_CONTACTS_PAGE";

export const VIEW_PROCESS_DETAILS = "VIEW_PROCESS_DETAILS";
export const VIEW_CHECKLIST_DETAILS = "VIEW_CHECKLIST_DETAILS";

export const SELECT_MESSAGE = "SELECT_MESSAGE";
export const DESELECT_MESSAGE = "DESELECT_MESSAGE";
export const CLEAR_MESSAGE_SELECTION = "CLEAR_MESSAGE_SELECTION";

export const SELECT_ROOM = "SELECT_ROOM";
export const DESELECT_ROOM = "DESELECT_ROOM";
export const CLEAR_ROOM_SELECTION = "CLEAR_ROOM_SELECTION";

export const SET_DELETE_OPTIONS = "SET_DELETE_OPTIONS";
export const SET_FORWARD_OPTIONS = "SET_FORWARD_OPTIONS";
export const SET_MOBILE_OPTIONS = "SET_MOBILE_OPTIONS";
export const HIDE_SELECT_OPTIONS = "HIDE_SELECT_OPTIONS";

export const FILTER_CONTACTS_SUCCESS = "FILTER_CONTACTS_SUCCESS";
export const FILTER_CONTACTS_FAILURE = "FILTER_CONTACTS_FAILURE";

export const GET_PROCESS_INSTANCE_COUNT_SUCCESS =
  "GET_PROCESS_INSTANCE_COUNT_SUCCESS";

export const SEARCH_UNIQUE_PARENT_SUCCESS = "SEARCH_UNIQUE_PARENT_SUCCESS";
export const SEARCH_UNIQUE_CREATOR_SUCCESS = "SEARCH_UNIQUE_CREATOR_SUCCESS";
export const SEARCH_UNIQUE_OWNER_SUCCESS = "SEARCH_UNIQUE_OWNER_SUCCESS";
export const SEARCH_UNIQUE_PARTICIPANT_SUCCESS =
  "SEARCH_UNIQUE_PARTICIPANT_SUCCESS";
export const SEARCH_UNIQUE_CONVERSATION_SUCCESS =
  "SEARCH_UNIQUE_CONVERSATION_SUCCESS";
export const SEARCH_UNIQUE_WORKFLOW_SUCCESS = "SEARCH_UNIQUE_WORKFLOW_SUCCESS";

export const REORDER_PROCESS_COLUMN = "REORDER_PROCESS_COLUMN";
export const SAVE_CUSTOM_PROCESS_COLUMN_FAILURE =
  "SAVE_CUSTOM_PROCESS_COLUMN_FAILURE";

export const SYNC_CUSTOM_PROCESS_COLUMN_REQUEST =
  "SYNC_CUSTOM_PROCESS_COLUMN_REQUEST";
export const SYNC_CUSTOM_PROCESS_COLUMN_FAILURE =
  "SYNC_CUSTOM_PROCESS_COLUMN_FAILURE";
export const SYNC_CUSTOM_PROCESS_COLUMN_SUCCESS =
  "SYNC_CUSTOM_PROCESS_COLUMN_SUCCESS";

export const TOGGLE_PROCESS_ROW_SELECTION = "TOGGLE_PROCESS_ROW_SELECTION";
export const CLEAR_PROCESS_ROW_SELECTION = "CLEAR_PROCESS_ROW_SELECTION";

export const TOGGLE_FORM_ROW_SELECTION = "TOGGLE_FORM_ROW_SELECTION";
export const CLEAR_FORM_ROW_SELECTION = "CLEAR_FORM_ROW_SELECTION";

export const BULK_UPDATE_PROCESS_REQUEST = "BULK_UPDATE_PROCESS_REQUEST";
export const BULK_UPDATE_PROCESS_OPTIMISTIC = "BULK_UPDATE_PROCESS_OPTIMISTIC";
export const BULK_UPDATE_PROCESS_FAILURE = "BULK_UPDATE_PROCESS_FAILURE";
export const BULK_UPDATE_PROCESS_SUCCESS = "BULK_UPDATE_PROCESS_SUCCESS";

export const GET_COMMON_MEMBERS_REQUEST = "GET_COMMON_MEMBERS_REQUEST";
export const GET_COMMON_MEMBERS_FAILURE = "GET_COMMON_MEMBERS_FAILURE";
export const GET_COMMON_MEMBERS_SUCCESS = "GET_COMMON_MEMBERS_SUCCESS";

export const REMOVE_COMMON_MEMBERS_REQUEST = "REMOVE_COMMON_MEMBERS_REQUEST";
export const REMOVE_COMMON_MEMBERS_FAILURE = "REMOVE_COMMON_MEMBERS_FAILURE";
export const REMOVE_COMMON_MEMBERS_SUCCESS = "REMOVE_COMMON_MEMBERS_SUCCESS";

export const BULK_ADD_ROOM_MEMBERS_REQUEST = "BULK_ADD_ROOM_MEMBERS_REQUEST";
export const BULK_ADD_ROOM_MEMBERS_FAILURE = "BULK_ADD_ROOM_MEMBERS_FAILURE";
export const BULK_ADD_ROOM_MEMBERS_SUCCESS = "BULK_ADD_ROOM_MEMBERS_SUCCESS";

export const SEARCH_UNIQUE_COMPLETED_BY_SUCCESS =
  "SEARCH_UNIQUE_COMPLETED_BY_SUCCESS";

export const LOAD_DEFAULT_CHATROOM = "LOAD_DEFAULT_CHATROOM";

export const GET_UPDATES_SUCCESS = "GET_UPDATES_SUCCESS";
export const GET_UPDATES_FAILURE = "GET_UPDATES_FAILURE";

export const RESEND_INVITE_REQUEST = "RESEND_INVITE_REQUEST";
export const RESEND_INVITE_FAILURE = "RESEND_INVITE_FAILURE";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";

export const SET_PROMPT_FIELDS = "SET_PROMPT_FIELDS";
export const GET_PROMPT_FIELDS_FAILURE = "GET_PROMPT_FIELDS_FAILURE";
export const REMOVE_PROMPT_FIELD = "REMOVE_PROMPT_FIELD";

export const REFETCH_PROMPT_FIELD = "REFETCH_PROMPT_FIELD";
export const REFETCH_PROMPT_FIELD_FAILURE = "REFETCH_PROMPT_FIELD_FAILURE";

export const DISMISS_PROMPT_REQUEST = "DISMISS_PROMPT_REQUEST";
export const DISMISS_PROMPT_SUCCESS = "DISMISS_PROMPT_SUCCESS";
export const DISMISS_PROMPT_FAILURE = "DISMISS_PROMPT_FAILURE";
export const UNDO_DISMISS_PROMPT_REQUEST = "UNDO_DISMISS_PROMPT_REQUEST";
export const UNDO_DISMISS_PROMPT_SUCCESS = "UNDO_DISMISS_PROMPT_SUCCESS";
export const UNDO_DISMISS_PROMPT_FAILURE = "UNDO_DISMISS_PROMPT_FAILURE";

export const REHYDRATION_COMPLETE = "REHYDRATION_COMPLETE";

export const START_ROOMS_CHANNEL = "START_ROOMS_CHANNEL";

export const BATCH_LOAD_ROOMS_SUCCESS = "BATCH_LOAD_ROOMS_SUCCESS";
export const BATCH_LOAD_ROOMS_FAILURE = "BATCH_LOAD_ROOMS_FAILURE";

export const RESET_HAS_NEW_MESSAGE = "RESET_HAS_NEW_MESSAGE";
export const HAS_NEW_MESSAGE = "HAS_NEW_MESSAGE";

export const FETCH_CHATROOM_ATTRIBUTES_SUCCESS =
  "FETCH_CHATROOM_ATTRIBUTES_SUCCESS";
export const RE_FETCH_CHATROOM_ATTRIBUTES_SUCCESS =
  "RE_FETCH_CHATROOM_ATTRIBUTES_SUCCESS";

export const SET_RELATED_COUNT = "SET_RELATED_COUNT";

export const FETCH_RELATED_CONVERSATION_REQUEST =
  "FETCH_RELATED_CONVERSATION_REQUEST";
export const FETCH_RELATED_CONVERSATION_FAILURE =
  "FETCH_RELATED_CONVERSATION_FAILURE";

export const CREATE_DIRECT_MESSAGE_REQUEST = "CREATE_DIRECT_MESSAGE_REQUEST";
export const CREATE_DIRECT_MESSAGE_FAILURE = "CREATE_DIRECT_MESSAGE_FAILURE";
export const CREATE_DIRECT_MESSAGE_SUCCESS = "CREATE_DIRECT_MESSAGE_SUCCESS";

export const LOAD_DIRECT_CONVERSATIONS = "LOAD_DIRECT_CONVERSATIONS";

export const CREATE_DIRECT_CONVERSATION_REQUEST =
  "CREATE_DIRECT_CONVERSATION_REQUEST";
export const CREATE_DIRECT_CONVERSATION_FAILURE =
  "CREATE_DIRECT_CONVERSATION_FAILURE";
export const CREATE_DIRECT_CONVERSATION_SUCCESS =
  "CREATE_DIRECT_CONVERSATION_SUCCESS";

export const SET_GROUPS_REQUEST = "SET_GROUPS_REQUEST";
export const SET_GROUPS_FAILURE = "SET_GROUPS_FAILURE";
export const SET_GROUPS_SUCCESS = "SET_GROUPS_SUCCESS";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export const GET_GROUPS_FAILURE = "GET_GROUPS_FAILURE";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";

export const SET_GROUP_MODAL_ATTRIBUTES = "SET_GROUP_MODAL_ATTRIBUTES";
export const CLOSE_GROUP_MODAL = "CLOSE_GROUP_MODAL";

export const EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";

export const SET_CURRENT_GROUP = "SET_CURRENT_GROUP";
export const GET_GROUP_REQUEST = "GET_GROUP_REQUEST";
export const GET_GROUP_FAILURE = "GET_GROUP_FAILURE";

export const GET_GROUPS_BY_USERS = "GET_GROUPS_BY_USERS";

export const SEARCH_GROUPS_REQUEST = "SEARCH_GROUPS_REQUEST";
export const SEARCH_GROUPS_FAILURE = "SEARCH_GROUPS_FAILURE";
export const SEARCH_GROUPS_SUCCESS = "SEARCH_GROUPS_SUCCESS";

export const SEARCH_GROUP_DETAILS_REQUEST = "SEARCH_GROUP_DETAILS_REQUEST";
export const SEARCH_GROUP_DETAILS_FAILURE = "SEARCH_GROUP_DETAILS_FAILURE";
export const SEARCH_GROUP_DETAILS_SUCCESS = "SEARCH_GROUP_DETAILS_SUCCESS";

export const TOGGLE_GROUP_DETAILS_SELECTION = "TOGGLE_GROUP_DETAILS_SELECTION";
export const CLEAR_GROUP_DETAILS_SELECTION = "CLEAR_GROUP_DETAILS_SELECTION";

export const EDIT_GROUP_MEMBERS_REQUEST = "EDIT_GROUP_MEMBERS_REQUEST";
export const EDIT_GROUP_MEMBERS_FAILURE = "EDIT_GROUP_MEMBERS_FAILURE";
export const EDIT_GROUP_MEMBERS_SUCCESS = "EDIT_GROUP_MEMBERS_SUCCESS";

export const TOGGLE_PEOPLE_ROW_SELECTION = "TOGGLE_PEOPLE_ROW_SELECTION";
export const CLEAR_PEOPLE_ROW_SELECTION = "CLEAR_PEOPLE_ROW_SELECTION";

export const GET_USER_GROUP_REQUEST = "GET_USER_GROUP_REQUEST";
export const GET_USER_GROUP_FAILURE = "GET_USER_GROUP_FAILURE";
export const GET_USER_GROUP_SUCCESS = "GET_USER_GROUP_SUCCESS";

export const GET_UNIQUE_CONTACT_VALUES_REQUEST =
  "GET_UNIQUE_CONTACT_VALUES_REQUEST";
export const GET_UNIQUE_CONTACT_VALUES_FAILURE =
  "GET_UNIQUE_CONTACT_VALUES_FAILURE";
export const GET_UNIQUE_CONTACT_VALUES_SUCCESS =
  "GET_UNIQUE_CONTACT_VALUES_SUCCESS";

export const SEARCH_DEPARTMENT_REQUEST = "SEARCH_DEPARTMENT_REQUEST";
export const SEARCH_DEPARTMENT_FAILURE = "SEARCH_DEPARTMENT_FAILURE";

export const SEARCH_UNIQUE_DEPARTMENT_SUCCESS =
  "SEARCH_UNIQUE_DEPARTMENT_SUCCESS";
export const SEARCH_UNIQUE_GROUPS_SUCCESS = "SEARCH_UNIQUE_GROUPS_SUCCESS";

export const SET_OWNER_TRIGGER = "SET_OWNER_TRIGGER";
export const SET_OWNER_TRIGGER_FROM_INFO_PANEL =
  "SET_OWNER_TRIGGER_FROM_INFO_PANEL";
export const SET_PARTICIPANT_TRIGGER = "SET_PARTICIPANT_TRIGGER";
export const SET_PRIORITY_TRIGGER = "SET_PRIORITY_TRIGGER";
export const SET_STATUS_TRIGGER = "SET_STATUS_TRIGGER";

export const SET_PROCESS_ALIAS_REQUEST = "SET_PROCESS_ALIAS_REQUEST";

export const NAVIGATE_FROM_DAILY_SUMMARY = "NAVIGATE_FROM_DAILY_SUMMARY";

export const GET_CHILD_CONVERSATIONS_REQUEST =
  "GET_CHILD_CONVERSATIONS_REQUEST";
export const GET_CHILD_CONVERSATIONS_FAILURE =
  "GET_CHILD_CONVERSATIONS_FAILURE";

export const CONVERSATION_DIALOG_WARN_MODAL = "CONVERSATION_DIALOG_WARN_MODAL";
export const CLEAR_CONVERSATION_DIALOG_WARN_MODAL =
  "CLEAR_CONVERSATION_DIALOG_WARN_MODAL";

export const GET_CURRENT_ORG_DETAILS_SUCCESS =
  "GET_CURRENT_ORG_DETAILS_SUCCESS";

export const GET_USERS_ORG_DETAILS_REQUEST = "GET_USERS_ORG_DETAILS_REQUEST";
export const GET_USERS_ORG_DETAILS_FAILURE = "GET_USERS_ORG_DETAILS_FAILURE";
export const GET_USERS_ORG_DETAILS_SUCCESS = "GET_USERS_ORG_DETAILS_SUCCESS";

export const SET_DEFAULT_FILTER = "SET_DEFAULT_FILTER";
export const SET_SAVED_FILTER = "SET_SAVED_FILTER";
export const COPY_CONVERSATION_EMAIL = "COPY_CONVERSATION_EMAIL";

export const SYNC_GROUPS_SUCCESS = "SYNC_GROUPS_SUCCESS";
export const SYNC_GROUPS_FAILURE = "SYNC_GROUPS_FAILURE";

export const TOGGLE_PARTICIPANT_DROPDOWN = "TOGGLE_PARTICIPANT_DROPDOWN";

export const SET_USER_ANALYTICS_REQUEST = "SET_USER_ANALYTICS_REQUEST";
export const SET_USER_ANALYTICS_SUCCESS = "SET_USER_ANALYTICS_SUCCESS";
export const SET_USER_ANALYTICS_FAILURE = "SET_USER_ANALYTICS_FAILURE";

export const GET_USER_ANALYTICS_SUCCESS = "GET_USER_ANALYTICS_SUCCESS";
export const GET_USER_ANALYTICS_FAILURE = "GET_USER_ANALYTICS_FAILURE";

export const GET_USER_ANALYTICS_TOTAL = "GET_USER_ANALYTICS_TOTAL";

export const TOGGLE_USER_ANALYTICS_TOTAL = "TOGGLE_USER_ANALYTICS_TOTAL";
export const TOGGLE_USER_ANALYTICS_AVERAGE = "TOGGLE_USER_ANALYTICS_AVERAGE";

export const SYNC_STATUSES_SUCCESS = "SYNC_STATUSES_SUCCESS";
export const SYNC_STATUSES_FAILURE = "SYNC_STATUSES_FAILURE";

export const SEARCH_STATUS_REQUEST = "SEARCH_STATUS_REQUEST";
export const SEARCH_STATUS_FAILURE = "SEARCH_STATUS_FAILURE";
export const SEARCH_STATUS_SUCCESS = "SEARCH_STATUS_SUCCESS";

export const CREATE_STATUS_REQUEST = "CREATE_STATUS_REQUEST";
export const CREATE_STATUS_FAILURE = "CREATE_STATUS_FAILURE";
export const CREATE_STATUS_SUCCESS = "CREATE_STATUS_SUCCESS";

export const DELETE_STATUS_REQUEST = "DELETE_STATUS_REQUEST";
export const DELETE_STATUS_FAILURE = "DELETE_STATUS_FAILURE";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";

export const EDIT_STATUS_REQUEST = "EDIT_STATUS_REQUEST";
export const EDIT_STATUS_FAILURE = "EDIT_STATUS_FAILURE";
export const EDIT_STATUS_SUCCESS = "EDIT_STATUS_SUCCESS";

export const TOGGLE_STATUS_DROPDOWN = "TOGGLE_STATUS_DROPDOWN";

export const SYNC_WORKFLOWS_STATUS_SUCCESS = "SYNC_WORKFLOWS_STATUS_SUCCESS";

export const GET_USER_PROCESS_REQUEST = "GET_USER_PROCESS_REQUEST";
export const GET_USER_PROCESS_FAILURE = "GET_USER_PROCESS_FAILURE";
export const GET_USER_PROCESS_SUCCESS = "GET_USER_PROCESS_SUCCESS";

export const GET_USER_ONBOARDING_STATUS_REQUEST =
  "GET_USER_ONBOARDING_STATUS_REQUEST";
export const GET_USER_ONBOARDING_STATUS_FAILURE =
  "GET_USER_ONBOARDING_STATUS_FAILURE";
export const GET_USER_ONBOARDING_STATUS_SUCCESS =
  "GET_USER_ONBOARDING_STATUS_SUCCESS";

export const UPDATE_OTHER_USER_DETAILS_REQUEST =
  "UPDATE_OTHER_USER_DETAILS_REQUEST";
export const UPDATE_OTHER_USER_DETAILS_FAILURE =
  "UPDATE_OTHER_USER_DETAILS_FAILURE";
export const UPDATE_OTHER_USER_DETAILS_SUCCESS =
  "UPDATE_OTHER_USER_DETAILS_SUCCESS";

export const SET_NEW_WORKFLOW = "SET_NEW_WORKFLOW";
export const MOST_USED_WORKFLOW = "MOST_USED_WORKFLOW";
export const SET_MESSAGE_AS_CONVERSATION_TITLE =
  "SET_MESSAGE_AS_CONVERSATION_TITLE";

export const FETCH_NESTED_ROWS_SUCCESS = "FETCH_NESTED_ROWS_SUCCESS";

export const DISABLE_USER_REQUEST = "DISABLE_USER_REQUEST";
export const DISABLE_USER_FAILURE = "DISABLE_USER_FAILURE";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";

export const ENABLE_USER_REQUEST = "ENABLE_USER_REQUEST";
export const ENABLE_USER_FAILURE = "ENABLE_USER_FAILURE";
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";

export const MAKE_ADMIN_REQUEST = "MAKE_ADMIN_REQUEST";
export const MAKE_ADMIN_FAILURE = "MAKE_ADMIN_FAILURE";
export const MAKE_ADMIN_SUCCESS = "MAKE_ADMIN_SUCCESS";

export const MAKE_MEMBER_REQUEST = "MAKE_MEMBER_REQUEST";
export const MAKE_MEMBER_FAILURE = "MAKE_MEMBER_FAILURE";
export const MAKE_MEMBER_SUCCESS = "MAKE_MEMBER_SUCCESS";

export const SHOW_DISABLE_USER_MODAL = "SHOW_DISABLE_USER_MODAL";
export const HIDE_DISABLE_USER_MODAL = "HIDE_DISABLE_USER_MODAL";

export const FETCH_STATUS_COUNT_REQUEST = "FETCH_STATUS_COUNT_REQUEST";
export const FETCH_STATUS_COUNT_FAILURE = "FETCH_STATUS_COUNT_FAILURE";
export const FETCH_STATUS_COUNT_SUCCESS = "FETCH_STATUS_COUNT_SUCCESS";

export const SHOW_STATUS_REMOVE_WARNING = "SHOW_STATUS_REMOVE_WARNING";
export const HIDE_STATUS_REMOVE_WARNING = "HIDE_STATUS_REMOVE_WARNING";

export const SINGLE_RESPONSE_WINDOW = "SINGLE_RESPONSE_WINDOW";
export const MOBILE_RESPONSE_WINDOW = "MOBILE_RESPONSE_WINDOW";
export const SRW_AUTH_REQUEST = "SRW_AUTH_REQUEST";
export const SRW_AUTH_SUCCCESS = "SRW_AUTH_SUCCCESS";
export const SRW_AUTH_FAILURE = "SRW_AUTH_FAILURE";
export const SRW_SIGNED_IN = "SRW_SIGNED_IN";
export const SRW_CHATROOM_METADATA_SUCCESS = "SRW_CHATROOM_METADATA_SUCCESS";
export const SRW_CHATROOM_METADATA_FAILURE = "SRW_CHATROOM_METADATA_FAILURE";
export const SRW_CURRENT_CHATROOM = "SRW_CURRENT_CHATROOM";
export const SRW_CHATROOMS_LOADED = "SRW_CHATROOMS_LOADED";
export const SRW_CHATROOM_ATTRIBUTES = "SRW_CHATROOM_ATTRIBUTES";
export const SRW_FETCH_ALL_METADATA = "SRW_FETCH_ALL_METADATA";
export const SRW_SERVER_AUTH_REQUEST = "SRW_SERVER_AUTH_REQUEST";
export const SRW_SERVER_AUTH_SUCCESS = "SRW_SERVER_AUTH_SUCCESS";
export const SRW_SERVER_AUTH_FAILURE = "SRW_SERVER_AUTH_FAILURE";
export const SET_ORG_DETAILS_SRW = "SET_ORG_DETAILS_SRW";
export const SRW_SET_EXISTING_USER = "SRW_SET_EXISTING_USER";
export const SRW_SHOW_SIGNUP_MODAL = "SRW_SHOW_SIGNUP_MODAL";
export const SRW_HIDE_SIGNUP_MODAL = "SRW_HIDE_SIGNUP_MODAL";
export const SRW_SIGN_UP = "SRW_SIGN_UP";
export const SRW_SIGN_UP_SUCCESS = "SRW_SIGN_UP_SUCCESS";
export const SRW_SIGN_UP_FAILURE = "SRW_SIGN_UP_FAILURE";

export const TRIGGER_CLEAR_INPUT = "TRIGGER_CLEAR_INPUT";
export const RESET_CLEAR_INPUT = "RESET_CLEAR_INPUT";

export const CAN_SRW_USER_JOIN = "CAN_SRW_USER_JOIN";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";

export const CLOSE_DOWNLOAD_PREVENTED_MODAL = "CLOSE_DOWNLOAD_PREVENTED_MODAL";

export const SET_SETTINGS_REQUEST = "SET_SETTINGS_REQUEST";
export const SET_SETTINGS_FAILURE = "SET_SETTINGS_FAILURE";
export const SET_SETTINGS_SUCCESS = "SET_SETTINGS_SUCCESS";

export const GET_USER_NOTIFICATION_SETTINGS_REQUEST =
  "GET_USER_NOTIFICATION_SETTINGS_REQUEST";
export const GET_USER_NOTIFICATION_SETTINGS_SUCCESS =
  "GET_USER_NOTIFICATION_SETTINGS_SUCCESS";

export const SET_USER_NOTIFICATION_SETTINGS_REQUEST =
  "SET_USER_NOTIFICATION_SETTINGS_REQUEST";
export const SET_USER_NOTIFICATION_SETTINGS_OPTIMISTIC =
  "SET_USER_NOTIFICATION_SETTINGS_OPTIMISTIC";
export const SET_USER_NOTIFICATION_SETTINGS_FAILURE =
  "SET_USER_NOTIFICATION_SETTINGS_FAILURE";

export const SHOW_FILTER_MODAL = "SHOW_FILTER_MODAL";
export const HIDE_FILTER_MODAL = "HIDE_FILTER_MODAL";
export const TOGGLE_FILTER_MODAL = "TOGGLE_FILTER_MODAL";

export const SET_CONVERSATION_TYPE_SEARCH = "SET_CONVERSATION_TYPE_SEARCH";

export const GET_SEARCHED_CONVERSATION_TYPES =
  "GET_SEARCHED_CONVERSATION_TYPES";
export const GET_SEARCHED_CONVERSATION_TYPES_ERROR =
  "GET_SEARCHED_CONVERSATION_TYPES_ERROR";

export const GET_SEARCHED_PINNED_LISTS = "GET_SEARCHED_PINNED_LISTS";
export const GET_SEARCHED_PINNED_LISTS_ERROR =
  "GET_SEARCHED_PINNED_LISTS_ERROR";

export const SET_CURRENT_FILTER_NAME = "SET_CURRENT_FILTER_NAME";

export const GET_SAVED_QUERY_LIST_SUCCESS = "GET_SAVED_QUERY_LIST_SUCCESS";

export const PLAY_VIDEO_FILE = "PLAY_VIDEO_FILE";
export const CLOSE_VIDEO_PLAYER = "CLOSE_VIDEO_PLAYER";

export const SEARCH_PRINCIPAL_CHECKLIST_REQUEST =
  "SEARCH_PRINCIPAL_CHECKLIST_REQUEST";
export const SEARCH_PRINCIPAL_CHECKLIST_FAILURE =
  "SEARCH_PRINCIPAL_CHECKLIST_FAILURE";
export const SEARCH_PRINCIPAL_CHECKLIST_SUCCESS =
  "SEARCH_PRINCIPAL_CHECKLIST_SUCCESS";

export const TOGGLE_MEMBER_ADD_DIALOG = "TOGGLE_MEMBER_ADD_DIALOG";
export const SET_CUSTOM_STATUS = "SET_CUSTOM_STATUS";
export const TOGGLE_CHATROOM_OVERLAY = "TOGGLE_CHATROOM_OVERLAY";

export const SHOW_EDIT_FILTER_MODAL = "SHOW_EDIT_FILTER_MODAL";
export const HIDE_EDIT_FILTER_MODAL = "HIDE_EDIT_FILTER_MODAL";

export const SHOW_TO_BE_EDITED_FILTER_NAME = "SHOW_TO_BE_EDITED_FILTER_NAME";

export const LOAD_CHATROOM_METADATA = "LOAD_CHATROOM_METADATA";
export const UPDATE_CHECKLIST_FIELDS = "UPDATE_CHECKLIST_FIELDS";

export const GET_USER_WORKFLOWS_SUCCESS = "GET_USER_WORKFLOWS_SUCCESS";

export const UPDATE_SEARCHED_QUICK_FILTER_WORKFLOWS =
  "UPDATE_SEARCHED_QUICK_FILTER_WORKFLOWS";

export const GET_SUGGESTED_WORKFLOW_SUCCESS = "GET_SUGGESTED_WORKFLOW_SUCCESS";
export const GET_SUGGESTED_WORKFLOW_FAILURE = "GET_SUGGESTED_WORKFLOW_FAILURE";

export const UPDATE_SUGGESTED_WORKFLOWS_REQUEST =
  "UPDATE_SUGGESTED_WORKFLOWS_REQUEST";
export const UPDATE_SUGGESTED_WORKFLOWS_SUCCESS =
  "UPDATE_SUGGESTED_WORKFLOWS_SUCCESS";
export const UPDATE_SUGGESTED_WORKFLOWS_FAILURE =
  "UPDATE_SUGGESTED_WORKFLOWS_FAILURE";

export const UPDATE_SEGMENTED_LIST_BY_STATUS =
  "UPDATE_SEGMENTED_LIST_BY_STATUS";
export const UPDATE_SEGMENTED_LIST_BY_PRIORITY =
  "UPDATE_SEGMENTED_LIST_BY_PRIORITY";

export const SET_SYSTEM_PROCESS = "SET_SYSTEM_PROCESS";

export const UPDATE_CHATROOM_FROM_MANAGE_VIEW_REQUEST =
  "UPDATE_CHATROOM_FROM_MANAGE_VIEW_REQUEST";
export const UPDATE_CHATROOM_FROM_MANAGE_VIEW_FAILURE =
  "UPDATE_CHATROOM_FROM_MANAGE_VIEW_FAILURE";
export const UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC =
  "UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC";
export const UPDATE_CHATROOM_FROM_MANAGE_VIEW_SUCCESS =
  "UPDATE_CHATROOM_FROM_MANAGE_VIEW_SUCCESS";

export const UPDATE_CHATROOM_FROM_CONVERSATION_MODAL =
  "UPDATE_CHATROOM_FROM_CONVERSATION_MODAL";

export const UPDATE_CHECKLIST_FROM_MANAGE_VIEW_REQUEST =
  "UPDATE_CHECKLIST_FROM_MANAGE_VIEW_REQUEST";
export const UPDATE_CHECKLIST_FROM_MANAGE_VIEW_FAILURE =
  "UPDATE_CHECKLIST_FROM_MANAGE_VIEW_FAILURE";
export const UPDATE_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC =
  "UPDATE_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC";
export const UPDATE_EMBEDDED_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC =
  "UPDATE_EMBEDDED_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC";
export const UPDATE_CHECKLIST_FROM_MANAGE_VIEW_SUCCESS =
  "UPDATE_CHECKLIST_FROM_MANAGE_VIEW_SUCCESS";

export const UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_REQUEST =
  "UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_REQUEST";
export const UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_FAILURE =
  "UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_FAILURE";
export const UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_SUCCESS =
  "UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_SUCCESS";

export const UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_REQUEST =
  "UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_REQUEST";
export const UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_FAILURE =
  "UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_FAILURE";
export const UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC =
  "UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC";
export const UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_SUCCESS =
  "UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_SUCCESS";

export const INVITE_USER_FROM_CHECKLIST_REQUEST =
  "INVITE_USER_FROM_CHECKLIST_REQUEST";
export const INVITE_USER_FROM_CHECKLIST_SUCCESS =
  "INVITE_USER_FROM_CHECKLIST_SUCCESS";
export const INVITE_USER_FROM_CHECKLIST_FAILURE =
  "INVITE_USER_FROM_CHECKLIST_FAILURE";

export const REPLACE_CHECKLIST_VALUE = "REPLACE_CHECKLIST_VALUE";

export const GET_CHART_TYPES_REQUEST = "GET_CHART_TYPES_REQUEST";
export const GET_CHART_TYPES_FAILURE = "GET_CHART_TYPES_FAILURE";
export const GET_CHART_TYPES_SUCCESS = "GET_CHART_TYPES_SUCCESS";

export const SET_NEW_CHART_DIALOG_ATTRIBUTES =
  "SET_NEW_CHART_DIALOG_ATTRIBUTES";
export const CLOSE_NEW_CHART_DIALOG = "CLOSE_NEW_CHART_DIALOG";

export const CREATE_CHART_REQUEST = "CREATE_CHART_REQUEST";
export const CREATE_CHART_FAILURE = "CREATE_CHART_FAILURE";
export const CREATE_CHART_SUCCESS = "CREATE_CHART_SUCCESS";

export const GET_CHART_REQUEST = "GET_CHART_REQUEST";
export const GET_CHART_FAILURE = "GET_CHART_FAILURE";
export const GET_CHART_SUCCESS = "GET_CHART_SUCCESS";

export const GET_REPORTS_CHART_JSON_REQUEST = "GET_REPORTS_CHART_JSON_REQUEST";
export const GET_REPORTS_CHART_JSON_SUCCESS = "GET_REPORTS_CHART_JSON_SUCCESS";
export const GET_REPORTS_CHART_JSON_FAILURE = "GET_REPORTS_CHART_JSON_FAILURE";

export const GET_CHART_JSON_REQUEST = "GET_CHART_JSON_REQUEST";
export const GET_CHART_JSON_FAILURE = "GET_CHART_JSON_FAILURE";
export const GET_CHART_JSON_SUCCESS = "GET_CHART_JSON_SUCCESS";

export const GET_CHARTS_REQUEST = "GET_CHARTS_REQUEST";
export const GET_CHARTS_FAILURE = "GET_CHARTS_FAILURE";
export const GET_CHARTS_SUCCESS = "GET_CHARTS_SUCCESS";

export const EDIT_CHART = "EDIT_CHART";

export const DELETE_CHART_REQUEST = "DELETE_CHART_REQUEST";
export const DELETE_CHART_FAILURE = "DELETE_CHART_FAILURE";
export const DELETE_CHART_SUCCESS = "DELETE_CHART_SUCCESS";

export const SET_REPORT_MODAL_ATTRIBUTES = "SET_REPORT_MODAL_ATTRIBUTES";

export const SEARCH_COLUMN_NAMES_REQUEST = "SEARCH_COLUMN_NAMES_REQUEST";
export const SEARCH_COLUMN_NAMES_FAILURE = "SEARCH_COLUMN_NAMES_FAILURE";
export const SEARCH_COLUMN_NAMES_SUCCESS = "SEARCH_COLUMN_NAMES_SUCCESS";

export const SHOW_EDIT_CHART_MODAL = "SHOW_EDIT_CHART_MODAL";
export const COPY_CHART_URL = "COPY_CHART_URL";

export const EDIT_REPORT_MODAL = "EDIT_REPORT_MODAL";

export const SET_DASHBOARD_REQUEST = "SET_DASHBOARD_REQUEST";
export const SET_DASHBOARD_FAILURE = "SET_DASHBOARD_FAILURE";
export const SET_DASHBOARD_SUCCESS = "SET_DASHBOARD_SUCCESS";

export const CREATE_DASHBOARD_REQUEST = "CREATE_DASHBOARD_REQUEST";
export const CREATE_DASHBOARD_FAILURE = "CREATE_DASHBOARD_FAILURE";
export const CREATE_DASHBOARD_SUCCESS = "CREATE_DASHBOARD_SUCCESS";

export const GET_ALL_DASHBOARD_REQUEST = "GET_ALL_DASHBOARD_REQUEST";
export const GET_ALL_DASHBOARD_FAILURE = "GET_ALL_DASHBOARD_FAILURE";
export const GET_ALL_DASHBOARD_SUCCESS = "GET_ALL_DASHBOARD_SUCCESS";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";

export const DELETE_DASHBOARD_REQUEST = "DELETE_DASHBOARD_REQUEST";
export const DELETE_DASHBOARD_FAILURE = "DELETE_DASHBOARD_FAILURE";
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";

export const EDIT_DASHBOARD_TITLE_REQUEST = "EDIT_DASHBOARD_TITLE_REQUEST";
export const EDIT_DASHBOARD_TITLE_OPTIMISTIC =
  "EDIT_DASHBOARD_TITLE_OPTIMISTIC";
export const EDIT_DASHBOARD_TITLE_FAILURE = "EDIT_DASHBOARD_TITLE_FAILURE";
export const EDIT_DASHBOARD_TITLE_SUCCESS = "EDIT_DASHBOARD_TITLE_SUCCESS";

export const GET_ALL_CHARTS_REQUEST = "GET_ALL_CHARTS_REQUEST";

export const ADD_CHART_TO_DASHBOARD_REQUEST = "ADD_CHART_TO_DASHBOARD_REQUEST";
export const ADD_CHART_TO_DASHBOARD_SUCCESS = "ADD_CHART_TO_DASHBOARD_SUCCESS";
export const ADD_CHART_TO_DASHBOARD_FAILURE = "ADD_CHART_TO_DASHBOARD_FAILURE";

export const DELETE_CHART_FROM_DASHBOARD_REQUEST =
  "DELETE_CHART_FROM_DASHBOARD_REQUEST";
export const DELETE_CHART_FROM_DASHBOARD_FAILURE =
  "DELETE_CHART_FROM_DASHBOARD_FAILURE";
export const DELETE_CHART_FROM_DASHBOARD_SUCCESS =
  "DELETE_CHART_FROM_DASHBOARD_SUCCESS";

export const LOADED_DASHBOARD = "LOADED_DASHBOARD";
export const LOAD_TYPING = "LOAD_TYPING";

export const SET_TYPING_REQUEST = "SET_TYPING_REQUEST";
export const DELETE_TYPING_REQUEST = "DELETE_TYPING_REQUEST";

export const STOP_TYPING_SYNC = "STOP_TYPING_SYNC";
export const SYNC_TYPING_SUCCESS = "SYNC_TYPING_SUCCESS";
export const SYNC_TYPING_FAILURE = "SYNC_TYPING_FAILURE";

export const SET_TYPING_SUCCESS = "SET_TYPING_SUCCESS";
export const SET_TYPING_FAILURE = "SET_TYPING_FAILURE";

export const DELETE_TYPING_SUCCESS = "DELETE_TYPING_SUCCESS";
export const DELETE_TYPING_FAILURE = "DELETE_TYPING_FAILURE";

export const SET_MANAGE_VIEW_FILTER = "SET_MANAGE_VIEW_FILTER";
export const PAGINATE_MANAGE_VIEW_FILTER = "PAGINATE_MANAGE_VIEW_FILTER";

export const SAVE_CHANGES_TO_REPORT = "SAVE_CHANGES_TO_REPORT";

export const SET_WORKFLOW_BUILDER_REMINDER = "SET_WORKFLOW_BUILDER_REMINDER";
export const REMOVE_WORKFLOW_BUILDER_REMINDER =
  "REMOVE_WORKFLOW_BUILDER_REMINDER";
export const FETCH_CONVERSATION_FILE_SUCCESS =
  "FETCH_CONVERSATION_FILE_SUCCESS";
export const FETCH_CONVERSATION_FILE_FAILURE =
  "FETCH_CONVERSATION_FILE_FAILURE";

export const SEARCH_FILES_REQUEST = "SEARCH_FILES_REQUEST";
export const SEARCH_FILES_SUCCESS = "SEARCH_FILES_SUCCESS";
export const SEARCH_FILES_FAILURE = "SEARCH_FILES_FAILURE";

export const UPLOAD_FILE_TO_CHECKLIST_REQUEST =
  "UPLOAD_FILE_TO_CHECKLIST_REQUEST";
export const UPLOAD_FILE_TO_CHECKLIST_SUCCESS =
  "UPLOAD_FILE_TO_CHECKLIST_SUCCESS";
export const UPLOAD_FILE_TO_CHECKLIST_FAILURE =
  "UPLOAD_FILE_TO_CHECKLIST_FAILURE";

export const UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS =
  "UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS";

export const CLEAR_CONVERSATION_CHECKLIST_LOADER =
  "CLEAR_CONVERSATION_CHECKLIST_LOADER";

export const SET_CHECKLIST_BUILDER_PARENT_CONVERSATION =
  "SET_CHECKLIST_BUILDER_PARENT_CONVERSATION";

export const DROPDOWN_FILE_UPLOAD_PROGRESS = "DROPDOWN_FILE_UPLOAD_PROGRESS";

export const UPLOAD_FILE_IN_DROPDOWN_REQUEST =
  "UPLOAD_FILE_IN_DROPDOWN_REQUEST";
export const UPLOAD_FILE_IN_DROPDOWN_FAILURE =
  "UPLOAD_FILE_IN_DROPDOWN_FAILURE";
export const UPLOAD_FILE_IN_DROPDOWN_SUCCESS =
  "UPLOAD_FILE_IN_DROPDOWN_SUCCESS";
export const CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR =
  "CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR";

export const INVITE_OWNER_REQUEST = "INVITE_OWNER_REQUEST";
export const INVITE_OWNER_FAILURE = "INVITE_OWNER_FAILURE";
export const INVITE_OWNER_SUCCESS = "INVITE_OWNER_SUCCESS";

export const SYNC_ROOM_MEMBERS_REQUEST = "SYNC_ROOM_MEMBERS_REQUEST";

export const SYNC_ORG_FILE_UPLOAD_SETTING_SUCCESS =
  "SYNC_ORG_FILE_UPLOAD_SETTING_SUCCESS";
export const SYNC_ORG_FILE_UPLOAD_SETTING_FAILURE =
  "SYNC_ORG_FILE_UPLOAD_SETTING_FAILURE";
export const SET_FILE_UPLOAD_SETTING = "SET_FILE_UPLOAD_SETTING";

export const CLEAR_WORKFLOW_INSTANCES = "CLEAR_WORKFLOW_INSTANCES";

export const FETCH_CHATROOM_UPDATES_REQUEST = "FETCH_CHATROOM_UPDATES_REQUEST";
export const FETCH_CHATROOM_UPDATES_FAILURE = "FETCH_CHATROOM_UPDATES_FAILURE";
export const FETCH_CHATROOM_UPDATES_SUCCESS = "FETCH_CHATROOM_UPDATES_SUCCESS";
export const FETCH_CHATROOM_UPDATES_ABORTED = "FETCH_CHATROOM_UPDATES_ABORTED";

export const SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW =
  "SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW";

export const LOAD_ALL_CHATROOMS_REQUEST = "LOAD_ALL_CHATROOMS_REQUEST";
export const SET_LAST_FETCHED_TIME = "SET_LAST_FETCHED_TIME";

export const LOAD_MEMBERSHIP_FAILURE = "LOAD_MEMBERSHIP_FAILURE";

export const EXPAND_CHART = "EXPAND_CHART";

export const SET_ORG_SETTINGS_REQUEST = "SET_ORG_SETTINGS_REQUEST";
export const SET_ORG_SETTINGS_FAILURE = "SET_ORG_SETTINGS_FAILURE";
export const SET_ORG_SETTINGS_SUCCESS = "SET_ORG_SETTINGS_SUCCESS";

export const SET_HOME_SCREEN_BUILDER_ATTRIBUTES =
  "SET_HOME_SCREEN_BUILDER_ATTRIBUTES";

export const CREATE_SECTION_REQUEST = "CREATE_SECTION_REQUEST";
export const CREATE_SECTION_FAILURE = "CREATE_SECTION_FAILURE";
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";

export const GET_ALL_SECTION_FAILURE = "GET_ALL_SECTION_FAILURE";
export const GET_ALL_SECTION_SUCCESS = "GET_ALL_SECTION_SUCCESS";

export const UPDATE_SECTION_REQUEST = "UPDATE_SECTION_REQUEST";
export const UPDATE_SECTION_OPTIMISTIC = "UPDATE_SECTION_OPTIMISTIC";
export const UPDATE_SECTION_FAILURE = "UPDATE_SECTION_FAILURE";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";

export const CREATE_TILE_REQUEST = "CREATE_TILE_REQUEST";
export const CREATE_TILE_FAILURE = "CREATE_TILE_FAILURE";
export const CREATE_TILE_SUCCESS = "CREATE_TILE_SUCCESS";

export const EDIT_TILE_REQUEST = "EDIT_TILE_REQUEST";
export const EDIT_TILE_FAILURE = "EDIT_TILE_FAILURE";
export const EDIT_TILE_SUCCESS = "EDIT_TILE_SUCCESS";

export const CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES =
  "CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES";

export const GET_TILE_TYPES_FAILURE = "GET_TILE_TYPES_FAILURE";
export const GET_TILE_TYPES_SUCCESS = "GET_TILE_TYPES_SUCCESS";

export const GET_ALL_TILES_FAILURE = "GET_ALL_TILES_FAILURE";
export const GET_ALL_TILES_SUCCESS = "GET_ALL_TILES_SUCCESS";

export const GET_ALL_USER_SEGMENTS_FAILURE = "GET_ALL_USER_SEGMENTS_FAILURE";
export const GET_ALL_USER_SEGMENTS_SUCCESS = "GET_ALL_USER_SEGMENTS_SUCCESS";

export const SEARCH_USER_SEGMENTS_REQUEST = "SEARCH_USER_SEGMENTS_REQUEST";
export const SEARCH_USER_SEGMENTS_FAILURE = "SEARCH_USER_SEGMENTS_FAILURE";
export const SEARCH_USER_SEGMENTS_SUCCESS = "SEARCH_USER_SEGMENTS_SUCCESS";

export const SET_SEGMENT_USER_MODAL_ATTRIBUTES =
  "SET_SEGMENT_USER_MODAL_ATTRIBUTES";
export const CLOSE_SEGMENT_USER_MODAL = "CLOSE_SEGMENT_USER_MODAL";

export const GET_SEGMENT_TYPE_FAILURE = "GET_SEGMENT_TYPE_FAILURE";
export const GET_SEGMENT_TYPE_SUCCESS = "GET_SEGMENT_TYPE_SUCCESS";

export const CREATE_USER_SEGMENT_REQUEST = "CREATE_USER_SEGMENT_REQUEST";
export const CREATE_USER_SEGMENT_FAILURE = "CREATE_USER_SEGMENT_FAILURE";
export const CREATE_USER_SEGMENT_SUCCESS = "CREATE_USER_SEGMENT_SUCCESS";

export const EDIT_USER_SEGMENT_REQUEST = "EDIT_USER_SEGMENT_REQUEST";
export const EDIT_USER_SEGMENT_FAILURE = "EDIT_USER_SEGMENT_FAILURE";
export const EDIT_USER_SEGMENT_SUCCESS = "EDIT_USER_SEGMENT_SUCCESS";

export const UPDATE_TILE_REQUEST = "UPDATE_TILE_REQUEST";
export const UPDATE_TILE_OPTIMISTIC = "UPDATE_TILE_OPTIMISTIC";
export const UPDATE_TILE_FAILURE = "UPDATE_TILE_FAILURE";
export const UPDATE_TILE_SUCCESS = "UPDATE_TILE_SUCCESS";

export const GET_PLATFORM_FAILURE = "GET_PLATFORM_FAILURE";
export const GET_PLATFORM_SUCCESS = "GET_PLATFORM_SUCCESS";

export const UPDATE_SECTION_ORDER_REQUEST = "UPDATE_SECTION_ORDER_REQUEST";
export const UPDATE_SECTION_ORDER_FAILURE = "UPDATE_SECTION_ORDER_FAILURE";
export const UPDATE_SECTION_ORDER_SUCCESS = "UPDATE_SECTION_ORDER_SUCCESS";

export const REMOVE_DELETED_ROOMS = "REMOVE_DELETED_ROOMS";

export const SET_HOME_SCREEN_REQUEST = "SET_HOME_SCREEN_REQUEST";
export const SET_HOME_SCREEN_FAILURE = "SET_HOME_SCREEN_FAILURE";
export const SET_HOME_SCREEN_SUCCESS = "SET_HOME_SCREEN_SUCCESS";

export const SET_WATCH_SET_PREVIOUS_CHATROOM =
  "SET_WATCH_SET_PREVIOUS_CHATROOM";

export const GET_HOME_SCREEN_FAILURE = "GET_HOME_SCREEN_FAILURE";
export const GET_HOME_SCREEN_SUCCESS = "GET_HOME_SCREEN_SUCCESS";

export const GET_MY_CONVERSATION_CHART_REQUEST =
  "GET_MY_CONVERSATION_CHART_REQUEST";
export const GET_MY_CONVERSATION_CHART_FAILURE =
  "GET_MY_CONVERSATION_CHART_FAILURE";
export const GET_MY_CONVERSATION_CHART_SUCCESS =
  "GET_MY_CONVERSATION_CHART_SUCCESS";

export const SET_HOME_SCREEN_STATUS = "SET_HOME_SCREEN_STATUS";

export const TOGGLE_HOME_SCREEN_REQUEST = "TOGGLE_HOME_SCREEN_REQUEST";
export const TOGGLE_HOME_SCREEN_FAILURE = "TOGGLE_HOME_SCREEN_FAILURE";
export const TOGGLE_HOME_SCREEN_SUCCESS = "TOGGLE_HOME_SCREEN_SUCCESS";

export const DELETE_TILE_REQUEST = "DELETE_TILE_REQUEST";
export const DELETE_TILE_FAILURE = "DELETE_TILE_FAILURE";
export const DELETE_TILE_OPTIMISTIC = "DELETE_TILE_OPTIMISTIC";

export const OPEN_HOME_SCREEN_TILE = "OPEN_HOME_SCREEN_TILE";

export const SYNC_USER_ORG_REQUEST = "SYNC_USER_ORG_REQUEST";
export const SYNC_USER_ORG_FAILURE = "SYNC_USER_ORG_FAILURE";
export const SYNC_USER_ORG_SUCCESS = "SYNC_USER_ORG_SUCCESS";

export const GET_FILE_PREVIEW_REQUEST = "GET_FILE_PREVIEW_REQUEST";
export const GET_FILE_PREVIEW_FAILURE = "GET_FILE_PREVIEW_FAILURE";
export const GET_FILE_PREVIEW_SUCCESS = "GET_FILE_PREVIEW_SUCCESS";

export const GET_FILE_THUMBNAIL_REQUEST = "GET_FILE_THUMBNAIL_REQUEST";
export const GET_FILE_THUMBNAIL_FAILURE = "GET_FILE_THUMBNAIL_FAILURE";
export const GET_FILE_THUMBNAIL_SUCCESS = "GET_FILE_THUMBNAIL_SUCCESS";

export const FETCH_FORMS_REQUEST = "FETCH_FORMS_REQUEST";
export const FETCH_FORMS_SUCCESS = "FETCH_FORMS_SUCCESS";
export const FETCH_FORMS_FAILURE = "FETCH_FORMS_FAILURE";

export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_FAILURE = "CREATE_FORM_FAILURE";
export const CREATE_FORM_REQUEST = "CREATE_FORM_REQUEST";

export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";

export const UPDATE_FORM_REQUEST = "UPDATE_FORM_REQUEST";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE";

export const FETCH_FORM_REQUEST = "FETCH_FORM_REQUEST";
export const FETCH_FORM_SUCCESS = "FETCH_FORM_SUCCESS";
export const FETCH_FORM_FAILURE = "FETCH_FORM_FAILURE";

export const OPEN_FORM_MODAL = "OPEN_FORM_MODAL";
export const CLOSE_FORM_MODAL = "CLOSE_FORM_MODAL";

export const SEARCH_FORMS_REQUEST = "SEARCH_FORMS_REQUEST";
export const SEARCH_FORMS_FAILURE = "SEARCH_FORMS_FAILURE";
export const SEARCH_FORMS_SUCCESS = "SEARCH_FORMS_SUCCESS";

export const FETCH_FORM_FIELDS_FAILURE = "FETCH_FORM_FIELDS_FAILURE";
export const FETCH_FORM_FIELDS_SUCCESS = "FETCH_FORM_FIELDS_SUCCESS";

export const FETCH_FORM_TEMPLATES = "FETCH_FORM_TEMPLATES";
export const GET_CHECKLIST_FORM_VALUES = "GET_CHECKLIST_FORM_VALUES";

export const SHOW_FORM_CREATION_LOADER = "SHOW_FORM_CREATION_LOADER";
export const HIDE_FORM_CREATION_LOADER = "HIDE_FORM_CREATION_LOADER";
export const LOAD_FORM_FIELDS = "LOAD_FORM_FIELDS";

export const GET_FORM_INSTANCES_SUCCESS = "GET_FORM_INSTANCES_SUCCESS";
export const GET_FORM_INSTANCES_FAILURE = "GET_FORM_INSTANCES_FAILURE";

export const GET_FORM_UNIQUE_INSTANCE_VALUES_REQUEST =
  "GET_FORM_UNIQUE_INSTANCE_VALUES_REQUEST";
export const GET_FORM_UNIQUE_INSTANCE_VALUES_FAILURE =
  "GET_FORM_UNIQUE_INSTANCE_VALUES_FAILURE";
export const GET_FORM_UNIQUE_INSTANCE_VALUES_SUCCESS =
  "GET_FORM_UNIQUE_INSTANCE_VALUES_SUCCESS";

export const CLOSE_CHECKLIST_MANAGE_VIEW = "CLOSE_CHECKLIST_MANAGE_VIEW";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const GET_ALL_ROLES_REQUEST = "GET_ALL_ROLES_REQUEST";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const ADD_ROLE_MEMBER_REQUEST = "ADD_ROLE_MEMBER_REQUEST";
export const ADD_ROLE_MEMBER_SUCCESS = "ADD_ROLE_MEMBER_SUCCESS";
export const ADD_ROLE_MEMBER_FAILURE = "ADD_ROLE_MEMBER_FAILURE";

export const REMOVE_ROLE_MEMBER_REQUEST = "REMOVE_ROLE_MEMBER_REQUEST";
export const REMOVE_ROLE_MEMBER_SUCCESS = "REMOVE_ROLE_MEMBER_SUCCESS";
export const REMOVE_ROLE_MEMBER_FAILURE = "REMOVE_ROLE_MEMBER_FAILURE";

export const GET_ALL_PERMISSIONS_REQUEST = "GET_ALL_PERMISSIONS_REQUEST";
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS";
export const GET_ALL_PERMISSIONS_FAILURE = "GET_ALL_PERMISSIONS_FAILURE";

export const SET_RESOURCE_PERMISSION_REQUEST =
  "SET_RESOURCE_PERMISSION_REQUEST";
export const SET_RESOURCE_PERMISSION_SUCCESS =
  "SET_RESOURCE_PERMISSION_SUCCESS";
export const SET_RESOURCE_PERMISSION_FAILURE =
  "SET_RESOURCE_PERMISSION_FAILURE";

export const REMOVE_RESOURCE_PERMISSION_REQUEST =
  "REMOVE_RESOURCE_PERMISSION_REQUEST";
export const REMOVE_RESOURCE_PERMISSION_SUCCESS =
  "REMOVE_RESOURCE_PERMISSION_SUCCESS";
export const REMOVE_RESOURCE_PERMISSION_FAILURE =
  "REMOVE_RESOURCE_PERMISSION_FAILURE";

export const SET_COMPONENT_PERMISSION_REQUEST =
  "SET_COMPONENT_PERMISSION_REQUEST";
export const SET_COMPONENT_PERMISSION_SUCCESS =
  "SET_COMPONENT_PERMISSION_SUCCESS";
export const SET_COMPONENT_PERMISSION_FAILURE =
  "SET_COMPONENT_PERMISSION_FAILURE";

export const REMOVE_COMPONENT_PERMISSION_REQUEST =
  "REMOVE_COMPONENT_PERMISSION_REQUEST";
export const REMOVE_COMPONENT_PERMISSION_SUCCESS =
  "REMOVE_COMPONENT_PERMISSION_SUCCESS";
export const REMOVE_COMPONENT_PERMISSION_FAILURE =
  "REMOVE_COMPONENT_PERMISSION_FAILURE";

export const ADD_CUSTOM_ROLE_REQUEST = "ADD_CUSTOM_ROLE_REQUEST";
export const ADD_CUSTOM_ROLE_SUCCESS = "ADD_CUSTOM_ROLE_SUCCESS";
export const ADD_CUSTOM_ROLE_FAILURE = "ADD_CUSTOM_ROLE_FAILURE";

export const DELETE_CUSTOM_ROLE_REQUEST = "DELETE_CUSTOM_ROLE_REQUEST";
export const DELETE_CUSTOM_ROLE_SUCCESS = "DELETE_CUSTOM_ROLE_SUCCESS";
export const DELETE_CUSTOM_ROLE_FAILURE = "DELETE_CUSTOM_ROLE_FAILURE";

export const UPDATE_CUSTOM_ROLE_REQUEST = "UPDATE_CUSTOM_ROLE_REQUEST";
export const UPDATE_CUSTOM_ROLE_SUCCESS = "UPDATE_CUSTOM_ROLE_SUCCESS";
export const UPDATE_CUSTOM_ROLE_FAILURE = "UPDATE_CUSTOM_ROLE_FAILURE";

export const TOGGLE_FIELD_EXPANSION = "TOGGLE_FIELD_EXPANSION";
export const SET_FIELD_EXPANSION = "SET_FIELD_EXPANSION";

export const SET_REPORT_EXPANDED_FIELDS = "SET_REPORT_EXPANDED_FIELDS";
export const SET_REPORT_EXPANDED_ROWS = "SET_REPORT_EXPANDED_ROWS";
export const SET_REPORT_FORM_VISIBILITY = "SET_REPORT_FORM_VISIBILITY";

export const TOGGLE_ROW_EXPANSION = "TOGGLE_ROW_EXPANSION";

export const TOGGLE_PICKLIST_EXPANSION = "TOGGLE_PICKLIST_EXPANSION";
export const RESET_PICKLIST_EXPANSION = "RESET_PICKLIST_EXPANSION";

export const TOGGLE_FORM_FIELDS_VISIBILITY = "TOGGLE_FORM_FIELDS_VISIBILITY";
export const SET_FORM_FIELDS_VISIBILITY = "SET_FORM_FIELDS_VISIBILITY";

export const GET_EMBEDDED_FIELDS_REQUEST = "GET_EMBEDDED_FIELDS_REQUEST";
export const GET_EMBEDDED_FIELDS_SUCCESS = "GET_EMBEDDED_FIELDS_SUCCESS";
export const GET_EMBEDDED_FIELDS_FAILURE = "GET_EMBEDDED_FIELDS_FAILURE";

export const GET_AUTH_PROVIDER_REQUEST = "GET_AUTH_PROVIDER_REQUEST";
export const GET_AUTH_PROVIDER_SUCCESS = "GET_AUTH_PROVIDER_SUCCESS";
export const GET_AUTH_PROVIDER_FAILURE = "GET_AUTH_PROVIDER_FAILURE";
export const CLEAR_AUTH_PROVIDER = "CLEAR_AUTH_PROVIDER";

export const SEND_VERIFICATION_EMAIL_REQUEST =
  "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_FAILURE =
  "SEND_VERIFICATION_EMAIL_FAILURE";

export const GET_SIGNATURE_URLS_REQUEST = "GET_SIGNATURE_URLS_REQUEST";
export const GET_SIGNATURE_URLS_SUCCESS = "GET_SIGNATURE_URLS_SUCCESS";
export const GET_SIGNATURE_URLS_FAILURE = "GET_SIGNATURE_URLS_FAILURE";

export const CLEAR_CUSTOM_SIGNATURE = "CLEAR_CUSTOM_SIGNATURE";

export const UPLOAD_CUSTOM_SIGNATURE_REQUEST =
  "UPLOAD_CUSTOM_SIGNATURE_REQUEST";
export const UPLOAD_CUSTOM_SIGNATURE_SUCCESS =
  "UPLOAD_CUSTOM_SIGNATURE_SUCCESS";
export const UPLOAD_CUSTOM_SIGNATURE_FAILURE =
  "UPLOAD_CUSTOM_SIGNATURE_FAILURE";

export const SHOW_FILE_UPLOAD_MODAL = "SHOW_FILE_UPLOAD_MODAL";
export const HIDE_FILE_UPLOAD_MODAL = "HIDE_FILE_UPLOAD_MODAL";

export const LOAD_COMPONENT_PERMISSIONS = "LOAD_COMPONENT_PERMISSIONS";

export const SYNC_COMPONENT_PERMISSIONS_SUCCESS =
  "SYNC_COMPONENT_PERMISSIONS_SUCCESS";
export const SYNC_COMPONENT_PERMISSIONS_FAILURE =
  "SYNC_COMPONENT_PERMISSIONS_FAILURE";

export const TOGGLE_CHECKLIST_FIELD_CONDITIONS =
  "TOGGLE_CHECKLIST_FIELD_CONDITIONS";

export const SET_FIELDS_BEHAVIOR = "SET_FIELDS_BEHAVIOR";
export const SET_FORM_FIELDS_BEHAVIOR = "SET_FORM_FIELDS_BEHAVIOR";

export const TOGGLE_WORKFLOW_DELETION_MODAL = "TOGGLE_WORKFLOW_DELETION_MODAL";

export const SEARCH_ROLES = "SEARCH_ROLES";
export const SEARCH_ROLES_SUCCESS = "SEARCH_ROLES_SUCCESS";

export const SET_WORKFLOW_CHANGES = "SET_WORKFLOW_CHANGES";
export const CANCEL_WORKFLOW_CHANGES = "CANCEL_WORKFLOW_CHANGES";
export const SET_WORKFLOW_CHANGES_AND_REDIRECT_TO_CHATROOM =
  "SET_WORKFLOW_CHANGES_AND_REDIRECT_TO_CHATROOM";

export const SET_CHECKLIST_FIELD_SETTINGS_CHANGES =
  "SET_CHECKLIST_FIELD_SETTINGS_CHANGES";
export const CANCEL_CHECKLIST_FIELD_SETTINGS_CHANGES =
  "CANCEL_CHECKLIST_FIELD_SETTINGS_CHANGES";

export const SHOW_BLOCK_NAVIGATION_MODAL = "SHOW_BLOCK_NAVIGATION_MODAL";
export const HIDE_BLOCK_NAVIGATION_MODEL = "HIDE_BLOCK_NAVIGATION_MODEL";

export const OPEN_SECTION = "OPEN_SECTION";
export const CLOSE_SECTION = "CLOSE_SECTION";

export const OPEN_SUB_SECTION = "OPEN_SUB_SECTION";
export const CLOSE_SUB_SECTION = "CLOSE_SUB_SECTION";

export const RUN_CONDITIONS_CODE = "RUN_CONDITIONS_CODE";

export const ADD_EMBEDDED_FIELDS = "ADD_EMBEDDED_FIELDS";

export const GET_FORM_FIELD_VALUES_SUCCESS = "GET_FORM_FIELD_VALUES_SUCCESS";

export const SET_FORM_FIELD_VALUE_SUCCESS = "SET_FORM_FIELD_VALUE_SUCCESS";

export const RESET_WORKFLOW_INSTANCE_FILTER = "RESET_WORKFLOW_INSTANCE_FILTER";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const AUTHORIZE_OAUTH_APP_PAGE = "AUTHORIZE_OAUTH_APP_PAGE";
export const OAUTH_APP_GRANT_PERMISSIONS_REQUEST =
  "OAUTH_APP_GRANT_PERMISSIONS_REQUEST";
export const OAUTH_APP_GRANT_PERMISSIONS_FAILURE =
  "OAUTH_APP_GRANT_PERMISSIONS_FAILURE";
export const SET_INSTANCE_FILTER = "SET_INSTANCE_FILTER";
export const UPDATE_INSTANCE_FILTER = "UPDATE_INSTANCE_FILTER";

export const REFRESH_CHART_REQUEST = "REFRESH_CHART_REQUEST";
export const REFRESH_CHART_SUCCESS = "REFRESH_CHART_SUCCESS";
export const REFRESH_CHART_ERROR = "REFRESH_CHART_ERROR";

// Org clone
export const ORG_CLONE_PAGE = "ORG_CLONE_PAGE";

export const ORG_CLONE_FETCH_TEMPLATE_REQUEST =
  "ORG_CLONE_FETCH_TEMPLATE_REQUEST";
export const ORG_CLONE_FETCH_TEMPLATE_SUCCESS =
  "ORG_CLONE_FETCH_TEMPLATE_SUCCESS";
export const ORG_CLONE_FETCH_TEMPLATE_FAILURE =
  "ORG_CLONE_FETCH_TEMPLATE_FAILURE";

export const ORG_CLONE_WIZARD_UPDATE_DATA = "ORG_CLONE_WIZARD_UPDATE_DATA";
export const ORG_CLONE_CLEAR_DATA_ERROR = "ORG_CLONE_CLEAR_DATA_ERROR";

export const ORG_CLONE_WIZARD_GO_FORWARD_REQUEST =
  "ORG_CLONE_WIZARD_GO_FORWARD_REQUEST";
export const ORG_CLONE_WIZARD_GO_FORWARD_SUCCESS =
  "ORG_CLONE_WIZARD_GO_FORWARD_SUCCESS";
export const ORG_CLONE_WIZARD_GO_FORWARD_ERROR =
  "ORG_CLONE_WIZARD_GO_FORWARD_ERROR";

export const ORG_CLONE_WIZARD_GO_BACKWARD_REQUEST =
  "ORG_CLONE_WIZARD_GO_BACKWARD_REQUEST";
export const ORG_CLONE_WIZARD_GO_BACKWARD_SUCCESS =
  "ORG_CLONE_WIZARD_GO_BACKWARD_SUCCESS";

export const ORG_CLONE_WIZARD_SET_STEP = "ORG_CLONE_WIZARD_SET_STEP";
export const ORG_CLONE_SET_CLONE_STATUS = "ORG_CLONE_SET_CLONE_STATUS";
export const ORG_CLONE_LOGOUT_REQUEST = "ORG_CLONE_LOGOUT_REQUEST";
export const SET_UPDATED_INSTANCES = "SET_UPDATED_INSTANCES";
export const SET_UPDATED_INSTANCES_BY_ID = "SET_UPDATED_INSTANCES_BY_ID";

export const SET_EXPANDED_FIELD = "SET_EXPANDED_FIELD";

export const GET_FIELD_UNIQUE_VALUES = "GET_FIELD_UNIQUE_VALUES";
export const SET_FIELD_UNIQUE_VALUES = "SET_FIELD_UNIQUE_VALUES";

export const REDIRECT_TO_APP_UNIFIZE_COM = "REDIRECT_TO_APP_UNIFIZE_COM";

export const UPLOAD_CSV_TO_PROCESS_REQUEST = "UPLOAD_CSV_TO_PROCESS_REQUEST";
export const UPLOAD_CSV_TO_PROCESS_SUCCESS = "UPLOAD_CSV_TO_PROCESS_SUCCESS";
export const UPLOAD_CSV_TO_PROCESS_FAILURE = "UPLOAD_CSV_TO_PROCESS_FAILURE";

export const GET_PROCESS_FIELD_MAPPINGS_REQUEST =
  "GET_PROCESS_FIELD_MAPPING_REQUEST";
export const GET_PROCESS_FIELD_MAPPINGS_SUCCESS =
  "GET_PROCESS_FIELD_MAPPING_SUCCESS";
export const GET_PROCESS_FIELD_MAPPINGS_FAILURE =
  "GET_PROCESS_FIELD_MAPPING_FAILURE";

export const OPEN_CSV_UPLOAD_MODAL = "OPEN_CSV_UPLOAD_MODAL";

export const STORE_PROCESS_FIELD_MAPPINGS_REQUEST =
  "STORE_PROCESS_FIELD_MAPPINGS_REQUEST";
export const STORE_PROCESS_FIELD_MAPPINGS_SUCCESS =
  "STORE_PROCESS_FIELD_MAPPINGS_SUCCESS";
export const STORE_PROCESS_FIELD_MAPPINGS_FAILURE =
  "STORE_PROCESS_FIELD_MAPPINGS_SUCCESS";

export const CLEAR_CSV_UPLOAD_DATA_STAGE = "CLEAR_CSV_UPLOAD_DATA_STAGE";

export const DELETE_LAST_ORG_REQUEST = "DELETE_LAST_ORG_REQUEST";
export const DELETE_LAST_ORG_SUCCESS = "DELETE_LAST_ORG_SUCCESS";
export const DELETE_LAST_ORG_FAILURE = "DELETE_LAST_ORG_FAILURE";

export const FILTER_EMBEDDED_FIELDS = "FILTER_EMBEDDED_FIELDS";

export const UPDATE_COLUMN_WIDTH = "UPDATE_COLUMN_WIDTH";
export const UPDATE_FIELD_SIZE_FROM_REPORT = "UPDATE_FIELD_SIZE_FROM_REPORT";
export const GET_EMBEDDED_FIELD_DETAILS = "GET_EMBEDDED_FIELD_DETAILS";

export const UPDATE_OPENED_FORM = "UPDATE_OPENED_FORM";

export const SET_ALL_RECORDS_FILTER = "SET_ALL_RECORDS_FILTER";
export const TOGGLE_ALL_RECORDS_FILTER = "TOGGLE_ALL_RECORDS_FILTER";
export const CLEAR_ALL_RECORDS_FILTER = "CLEAR_ALL_RECORDS_FILTER";

export const SET_CHART_REQUEST = "SET_CHART_REQUEST";
export const SET_CHART_FAILURE = "SET_CHART_FAILURE";
export const SET_CHART_SUCCESS = "SET_CHART_SUCCESS";

export const SELECTED_CHART_REQUEST = "SELECTED_CHART_REQUEST";
export const SELECTED_CHART_SUCCESS = "SELECTED_CHART_SUCCESS";
export const SELECTED_CHART_FAILURE = "SELECTED_CHART_FAILURE";

export const GET_PRIMARY_FIELD_LIST_REQUEST = "GET_PRIMARY_FIELD_LIST_REQUEST";
export const GET_PRIMARY_FIELD_LIST_SUCCESS = "GET_PRIMARY_FIELD_LIST_SUCCESS";
export const GET_PRIMARY_FIELD_LIST_FAILURE = "GET_PRIMARY_FIELD_LIST_FAILURE";

export const GET_RECORD_COUNT_FIELD_LIST_REQUEST =
  "GET_RECORD_COUNT_FIELD_LIST_REQUEST";
export const GET_RECORD_COUNT_FIELD_LIST_SUCCESS =
  "GET_RECORD_COUNT_FIELD_LIST_SUCCESS";
export const GET_RECORD_COUNT_FIELD_LIST_FAILURE =
  "GET_RECORD_COUNT_FIELD_LIST_FAILURE";

export const GET_NUMERICAL_FIELD_LIST_REQUEST =
  "GET_NUMERICAL_FIELD_LIST_REQUEST";
export const GET_NUMERICAL_FIELD_LIST_SUCCESS =
  "GET_NUMERICAL_FIELD_LIST_SUCCESS";
export const GET_NUMERICAL_FIELD_LIST_FAILURE =
  "GET_NUMERICAL_FIELD_LIST_FAILURE";

export const GET_EMBEDDED_NUMERICAL_FIELD_LIST_REQUEST =
  "GET_EMBEDDED_NUMERICAL_FIELD_LIST_REQUEST";
export const GET_EMBEDDED_NUMERICAL_FIELD_LIST_SUCCESS =
  "GET_EMBEDDED_NUMERICAL_FIELD_LIST_SUCCESS";
export const GET_EMBEDDED_NUMERICAL_FIELD_LIST_FAILURE =
  "GET_EMBEDDED_NUMERICAL_FIELD_LIST_FAILURE";

export const CREATE_COMPARISON_CHART_REQUEST =
  "CREATE_COMPARISON_CHART_REQUEST";
export const CREATE_COMPARISON_CHART_SUCCESS =
  "CREATE_COMPARISON_CHART_SUCCESS";
export const CREATE_COMPARISON_CHART_FAILURE =
  "CREATE_COMPARISON_CHART_FAILURE";

export const GET_GROUP_BY_FIELD_LIST_REQUEST = "GET_GROUP_BY_LIST_REQUEST";
export const GET_GROUP_BY_FIELD_LIST_SUCCESS = "GET_GROUP_BY_LIST_SUCCESS";
export const GET_GROUP_BY_FIELD_LIST_FAILURE = "GET_GROUP_BY_LIST_FAILURE";

export const UPDATE_COMPARISON_CHART_REQUEST =
  "UPDATE_COMPARISON_CHART_REQUEST";
export const UPDATE_COMPARISON_CHART_SUCCESS =
  "UPDATE_COMPARISON_CHART_SUCCESS";
export const UPDATE_COMPARISON_CHART_FAILURE =
  "UPDATE_COMPARISON_CHART_FAILURE";

export const FETCH_CHART_REQUEST = "FETCH_CHART_REQUEST";
export const FETCH_CHART_SUCCESS = "FETCH_CHART_SUCCESS";
export const FETCH_CHART_FAILURE = "FETCH_CHART_FAILURE";

export const UPDATE_CHART_REQUEST = "UPDATE_CHART_REQUEST";
export const UPDATE_CHART_SUCCESS = "UPDATE_CHART_SUCCESS";
export const UPDATE_CHART_FAILURE = "UPDATE_CHART_FAILURE";

export const GET_CHART_FORM_FIELDS_REQUEST = "GET_CHART_FORM_FIELDS_REQUEST";
export const GET_CHART_FORM_FIELDS_SUCCESS = "GET_CHART_FORM_FIELDS_SUCCESS";
export const GET_CHART_FORM_FIELDS_FAILURE = "GET_CHART_FORM_FIELDS_FAILURE";

export const GET_CHART_EMBEDDED_FIELDS_REQUEST =
  "GET_CHART_EMBEDDED_FIELDS_REQUEST";
export const GET_CHART_EMBEDDED_FIELDS_SUCCESS =
  "GET_CHART_EMBEDDED_FIELDS_SUCCESS";
export const GET_CHART_EMBEDDED_FIELDS_FAILURE =
  "GET_CHART_EMBEDDED_FIELDS_FAILURE";

export const SET_CHART_LOADING_STATE = "SET_CHART_LOADING_STATE";

export const SET_AUTH_PROVIDER = "SET_AUTH_PROVIDER";
export const CHECK_AUTH_PROVIDER = "CHECK_AUTH_PROVIDER";

export const SET_ROOM_PARTICIPANTS = "SET_ROOM_PARTICIPANTS";

export const SAVE_SSO_CONFIG = "SAVE_SSO_CONFIG";

export const SHOW_PARTICIPANT_LOADER = "SHOW_PARTICIPANT_LOADER";
export const HIDE_PARTICIPANT_LOADER = "HIDE_PARTICIPANT_LOADER";

export const SET_ALL_INTEGRATIONS = "SET_ALL_INTEGRATIONS";
export const INSTALL_INTEGRATION_SUCCESS = "INSTALL_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_SUCCESS = "UPDATE_INTEGRATION_SUCCESS";
export const DELETE_INTEGRATION_SUCCESS = "DELETE_INTEGRATION_SUCCESS";

export const SYNC_INTEGRATIONS = "SYNC_INTEGRATIONS";
export const SET_ACTIVE_INTEGRATIONS = "SET_ACTIVE_INTEGRATIONS";
export const UPDATE_ACTIVE_INTEGRATIONS = "UPDATE_ACTIVE_INTEGRATIONS";

export const UPDATE_CHATROOM_TEMPLATE = "UPDATE_CHATROOM_TEMPLATE";
