// @flow

import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect, useSelector } from "react-redux";

import useBoolean from "src/hooks/useBoolean";
import { Current as StyledCurrent } from "src/styles";
import Tooltip from "src/components/Tooltip";
import Title from "./Title";
import Favourite from "./Favourite";
import UrlCopy from "./UrlCopy";
import ApprovalBar from "./ApprovalBar";
import Icon from "src/icons";
import BreadCrumbs from "src/containers/BreadCrumbs";
import Status from "src/components/chatroom/Status";
import Priority from "src/components/chatroom/Priority";
import Owner from "src/containers/chatroom/Owner";
import Related from "src/containers/chatroom/Related";
import Checklist from "src/components/chatroom/Checklist";
import Files from "src/containers/chatroom/Files";
import DueDate from "src/components/chatroom/DueDate";
import Privacy from "src/components/chatroom/Privacy";
import Members from "src/components/chatroom/Members";
import Revisions from "./Revisions";
import MoreMenu from "./MoreMenu";
import PrivacyModal from "./MoreMenu/Privacy/PrivacyModal";
import appLocation from "src/constants/location";

import { showDock, showSrwDock, setHeaderHeight } from "src/actions/dock";
import {
  getCurrentChatRoom,
  getChatroomPrivacy,
  getChatroomMembership,
  isStatusDisabled,
  getIsSrwMobile
} from "src/reducers";
import type { AppState, UnifizeChatRoom, ActiveDockHeader } from "src/types";

import {
  Header as StyledHeader,
  BreadCrumbWrapper,
  TitleRow,
  DockIconWrapper,
  ActionIconsWrapper,
  InfoRow,
  PrivacyWrapper,
  PrivacyText
} from "./styles";

type Props = {
  room: UnifizeChatRoom,
  activeDock: ActiveDockHeader,
  _showDock: Function,
  _setHeaderHeight: Function,
  privacy: Privacy,
  membership: boolean,
  _showSrwDock: Function,
  isSrw?: boolean,
  statusDisabled: boolean,
  currentRef: null | React$ElementRef<StyledCurrent>
};

const Header = ({
  room,
  activeDock,
  _showDock,
  _setHeaderHeight,
  privacy,
  membership,
  isSrw,
  _showSrwDock,
  statusDisabled,
  currentRef
}: Props) => {
  const [node, setNode] = useState(null);
  const {
    value: isPrivacyOpen,
    setTrue: openPrivacyModal,
    setFalse: closePrivacyModal
  } = useBoolean();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const moreMenuRef = useRef(null);

  useEffect(() => {
    setIsMoreMenuOpen(false);
  }, [room && room.id]);

  const measuredRef = useCallback(ref => {
    if (ref !== null) {
      setNode(ref);
    }
  }, []);

  useEffect(() => {
    if (node !== null) {
      const { height } = node.getBoundingClientRect();
      if (height) _setHeaderHeight(height);
    }
  }, [node, activeDock]);

  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  const openDock = useCallback(() => {
    if (isSrw) _showSrwDock("info");
    else _showDock("info");
  }, [isSrw]);

  const toggleMenu = () => {
    isMoreMenuOpen ? setIsMoreMenuOpen(false) : setIsMoreMenuOpen(true);
  };

  if (room)
    return (
      <>
        <StyledHeader ref={measuredRef}>
          {!isSrw && (
            <BreadCrumbWrapper>
              <BreadCrumbs roomId={room.id} />
            </BreadCrumbWrapper>
          )}
          <TitleRow>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center"
              }}
            >
              {!isSrwMobile && privacy !== "none" && (
                <Tooltip title="privacy">
                  <PrivacyWrapper
                    active={
                      (privacy === "full" || privacy === "content") &&
                      room.type !== "direct"
                    }
                    location={appLocation.chatroomHeader}
                    onClick={openPrivacyModal}
                    tabIndex={0}
                  >
                    <Privacy roomId={room.id} />
                  </PrivacyWrapper>
                </Tooltip>
              )}
              <PrivacyModal
                roomId={room.id}
                isSrw={isSrw}
                closeModal={closePrivacyModal}
                isOpen={isPrivacyOpen}
              />
              <Title roomId={room.id} />
              {!isSrw && (
                <ActionIconsWrapper>
                  <Favourite roomId={room.id} />
                  {room.type !== "direct" && isSrwMobile && (
                    <UrlCopy roomId={room.id} />
                  )}
                </ActionIconsWrapper>
              )}
            </div>
          </TitleRow>
          {room.type !== "direct" && (
            <InfoRow>
              {!isSrwMobile && (
                <Status
                  roomId={room.id}
                  location={appLocation.chatroomHeader}
                />
              )}
              {isSrwMobile && (
                <PrivacyWrapper
                  active={
                    (privacy === "full" || privacy === "content") &&
                    room.type !== "direct"
                  }
                >
                  <Privacy roomId={room.id} />
                  {!activeDock && <PrivacyText>Private</PrivacyText>}
                </PrivacyWrapper>
              )}

              <Owner roomId={room.id} location={appLocation.chatroomHeader} />
              <Members
                roomId={room.id}
                location={appLocation.chatroomHeader}
                address={room.address}
              />
              {isSrwMobile && (
                <Checklist
                  roomId={room.id}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                  activeDock={activeDock}
                />
              )}

              {room.type !== "direct" && room.type !== "group" ? (
                <DueDate
                  roomId={room.id}
                  location={appLocation.chatroomHeader}
                />
              ) : null}
              {isSrwMobile && (
                <Files
                  roomId={room.id}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                />
              )}

              {isSrwMobile && !isSrw && <Related roomId={room.id} />}
              {room.type === "workflow" && !statusDisabled ? (
                <Priority
                  roomId={room.id}
                  location={appLocation.chatroomHeader}
                />
              ) : null}
              {room.type === "workflow" && isSrwMobile && (
                <Revisions roomId={room.id} />
              )}

              <MoreMenu
                toggleMenu={toggleMenu}
                setIsMoreMenuOpen={setIsMoreMenuOpen}
                outerRef={moreMenuRef}
                isMoreMenuOpen={isMoreMenuOpen}
                roomId={room.id}
                roomType={room.type}
                isSrwMobile={isSrwMobile}
                isSrw={isSrw}
                currentRef={currentRef}
              />

              {!isSrwMobile && (
                <Checklist
                  roomId={room.id}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                  activeDock={activeDock}
                />
              )}
            </InfoRow>
          )}
          {isSrwMobile ? (
            activeDock ||
            room.type === "direct" ||
            (privacy === "content" && !membership) ? null : (
              <DockIconWrapper onClick={openDock}>
                <Icon type="dockMenu" />
              </DockIconWrapper>
            )
          ) : null}
        </StyledHeader>
        <ApprovalBar roomId={room.id} />
      </>
    );
  return null;
};

Header.defaultProps = {
  isSrw: false
};

const mapStateToProps = (
  { app }: { app: AppState },
  { isSrw }: { isSrw: boolean }
) => {
  const room = getCurrentChatRoom(app);
  return {
    room,
    activeDock: isSrw ? app.activeDock.srw : app.activeDock.main,
    privacy: getChatroomPrivacy(app, (room || {}).id),
    membership: getChatroomMembership(app, (room || {}).id),
    statusDisabled: isStatusDisabled(app, (room || {}).id)
  };
};

export default connect(mapStateToProps, {
  _showDock: showDock,
  _showSrwDock: showSrwDock,
  _setHeaderHeight: setHeaderHeight
})(Header);
