// @flow

import * as R from "ramda";
import type {
  ApprovalFieldSettings,
  ApprovalFieldSettingsV2,
  RevisionFieldSettings,
  RevisionFieldSettingsv2,
  CommonAutomations
} from "src/types";
import { sanitizeSettings as sanitizeApprovalSettings } from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/utils";

const commonAutomations = [
  {
    action: "updateStatus",
    active: false,
    data: null
  },
  {
    action: "addParticipants",
    active: true,
    data: []
  },
  {
    action: "removeParticipants",
    active: true,
    data: []
  },
  {
    action: "updateOwner",
    active: false,
    data: null
  },
  {
    action: "sendMessage",
    active: true,
    data: ""
  },
  {
    action: "updatePrivacy",
    active: true,
    data: {
      mode: "noChange",
      whitelistedUsers: []
    }
  },
  {
    action: "markRevisionAsCurrent",
    active: false,
    data: null
  }
];

const approvalDefaultSettings: ApprovalFieldSettingsV2 = {
  version: 2,

  // contingent approval fields
  contingentApprovals: [],

  // Active means that at-least one contingent approval field is approved
  inactiveBehavior: "disable",

  // Ability to cancel contingent approvals when this field is approved/in-progress
  canCancelContingentApprovals: false,

  // Cancellation comment mandatory
  requireCancellationComment: false,

  // Allow cancellation of approvals-in-progress
  canCancelPartialApproval: true,

  // Cancel all previous approvals (within the hierarchy) on rejection
  cancelContingentApprovalsOnRejection: false,

  // lock fields when this field is approved
  lockFields: {
    mode: "inherit",
    fields: []
  },

  showRequestApprovalButton: false,

  requiredApprovers: "some",
  minApprovers: 1,
  approvers: {
    allParticipants: false,
    owner: false,
    preventRequester: false,
    users: [],
    roles: [],
    groups: [],
    fields: []
  },

  cancellers: {
    allParticipants: false,
    approvers: false,
    users: [],
    roles: [],
    groups: [],
    fields: []
  },

  requireComment: false,
  allowCancelOnApproval: true,
  requireRejectionComment: false,

  lockStatus: {
    onStart: false,
    onApproval: false,
    onCancellation: false,
    onRejection: false
  },

  automations: {
    started: commonAutomations,
    approved: commonAutomations,
    rejected: commonAutomations,
    cancelled: commonAutomations
  }
};

/**
 * Merge new or missing automation data in existing settings
 * @param  { ApprovalFieldSettingsV2 } automationSettings - v2 version of the settings
 * @param  { CommonAutomations } commonAutomations - common automation config
 * @returns { ApprovalFieldSettingsV2 } The v2 version of the settings
 */
export const mergeAutomations = (
  automationSettings: Object,
  commonAutomations: CommonAutomations
) => {
  R.forEachObjIndexed((array, key) => {
    const missingAutomations = R.filter(
      automation => !R.any(R.propEq("action", automation.action), array),
      commonAutomations
    );

    automationSettings[key] = R.concat(array, missingAutomations);
  }, automationSettings);

  return automationSettings;
};

/**
 * Get the v2 version of settings because the settings in some transit could be of v1
 * @param  {ApprovalFieldSettings | ApprovalFieldSettingsV2} settings - v1 or v2 version of the settings
 * @returns { ApprovalFieldSettingsV2 } The v2 version of the settings
 */
export const approval = (
  settings: ApprovalFieldSettings | ApprovalFieldSettingsV2
): ApprovalFieldSettingsV2 => {
  if (settings.version) {
    // If there are missing automations, merge them into automation settings.
    if (settings.automations) {
      const mergedAutomations = mergeAutomations(
        settings.automations,
        commonAutomations
      );

      return sanitizeApprovalSettings(
        R.mergeDeepRight(approvalDefaultSettings, {
          ...settings,
          automations: mergedAutomations
        })
      );
    }

    return sanitizeApprovalSettings(
      R.mergeDeepRight(approvalDefaultSettings, settings)
    );
  }

  // Upgrade v1 to v2
  let newSettings = R.mergeDeepRight(approvalDefaultSettings, {
    lockFields: {
      fields: settings.lockedFields || []
    },

    minApprovers: settings.min || 1,
    approvers: {
      users: settings.users || []
    },

    allowCancelOnApproval: settings?.afterApproval?.allowRevision || false,

    lockStatus: {
      onApproval: settings?.afterApproval?.lockStatus || false
    }
  });

  const transforms = [];

  let prevStatusPostApproval = settings?.afterApproval?.changeStatusTo;
  prevStatusPostApproval = R.isNil(prevStatusPostApproval)
    ? null
    : prevStatusPostApproval;

  transforms.push(
    R.assocPath(["automations", "approved", 0, "data"], prevStatusPostApproval)
  );

  let prevStatusPostRevision = settings?.afterRevision?.changeStatusTo;
  prevStatusPostRevision = R.isNil(prevStatusPostRevision)
    ? null
    : prevStatusPostRevision;

  transforms.push(
    R.assocPath(["automations", "cancelled", 0, "data"], prevStatusPostRevision)
  );

  transforms.push(R.assocPath(["lockFields", "mode"], "new"));

  transforms.forEach(transformer => {
    newSettings = transformer(newSettings);
  });

  return sanitizeApprovalSettings(newSettings);
};

/*
  These are functions used to generate the path to access 
  deply nested properties in the state.
*/
const revisionSettingsPaths = {
  oldCurrent: {
    data: action => ["automations", "updateCurrent", "old", action, "data"],
    active: action => ["automations", "updateCurrent", "old", action, "active"],
    get: action => ["automations", "updateCurrent", "old", action]
  },
  newCurrent: {
    data: action => ["automations", "updateCurrent", "new", action, "data"],
    active: action => ["automations", "updateCurrent", "new", action, "active"],
    get: action => ["automations", "updateCurrent", "new", action]
  }
};

export const revisionDefaultSettings: RevisionFieldSettingsv2 = {
  version: 2,
  authorizedToCreate: {
    allParticipants: false,
    owner: false,
    roles: [],
    users: [],
    groups: [],
    fields: []
  },
  authorizedToMarkCurrent: {
    allParticipants: false,
    owner: false,
    roles: [],
    users: [],
    groups: [],
    fields: [],
    statuses: [],
    allParticipantsOfCurrent: false,
    ownerOfCurrent: false,
    fieldsOfCurrent: []
  },
  copyableFields: [],
  automations: {
    newCurrent: [
      {
        action: "addParticipants",
        active: true,
        data: []
      },
      {
        action: "removeParticipants",
        active: true,
        data: []
      },
      {
        action: "addParticipantsFromFields",
        active: true,
        data: []
      },
      {
        action: "sendMessage",
        active: true,
        data: "This is the current version now."
      },
      {
        action: "updatePrivacy",
        active: true,
        data: {
          mode: "noChange",
          whitelistedUsers: []
        }
      },
      {
        action: "archive",
        active: true,
        data: false
      }
    ],

    oldCurrent: [
      {
        action: "addParticipants",
        active: true,
        data: []
      },
      {
        action: "removeParticipants",
        active: true,
        data: []
      },
      {
        action: "addParticipantsFromFields",
        active: true,
        data: []
      },
      {
        action: "sendMessage",
        active: true,
        data: "This is no longer the current version."
      },
      {
        action: "updatePrivacy",
        active: true,
        data: {
          mode: "noChange",
          whitelistedUsers: []
        }
      },
      {
        action: "updateStatus",
        active: false,
        data: null
      },
      {
        action: "archive",
        active: false,
        data: false
      }
    ]
  }
};

const sanitizeRevisionSettings = (
  settings: RevisionFieldSettingsv2
): RevisionFieldSettingsv2 => {
  let newSettings = R.mergeDeepRight(revisionDefaultSettings, settings);

  Object.keys(revisionDefaultSettings).forEach(key => {
    if (R.isNil(newSettings[key])) {
      // $FlowFixMe
      newSettings[key] = revisionDefaultSettings[key];
    }
  });

  return newSettings;
};

/**
 * Get the v2 version of settings because the settings in some transit could be of v1
 * @param  {RevisionFieldSettings | RevisionFieldSettingsv2} settings - v1 or v2 version of the settings
 * @returns { RevisionFieldSettingsv2 } The v2 version of the settings
 */
export const revision = (
  settings: RevisionFieldSettings | RevisionFieldSettingsv2
): RevisionFieldSettingsv2 => {
  if (settings.version === 2) {
    const settingsV2 = sanitizeRevisionSettings(
      // $FlowFixMe
      settings
    );

    const authorizedUsers = R.prop("authorizedUsers", settingsV2) || [];

    // Move authorizedUsers to authorizedToCreate object if not
    // done already and remove authorizedUsers key.
    if (R.has("authorizedUsers", settingsV2)) {
      if (authorizedUsers.length > 0) {
        settingsV2.authorizedToCreate.users = authorizedUsers;
      }
      delete settingsV2.authorizedUsers;
    }

    if (
      settingsV2.automations.newCurrent.length ===
      revisionDefaultSettings.automations.newCurrent.length
    ) {
      return settingsV2;
    }

    ["oldCurrent", "newCurrent"].forEach(key => {
      // Append the newly added  automations if they don't
      // exist in the settings already.
      const newActions = revisionDefaultSettings.automations[key].reduce(
        (acc, automation) => {
          if (
            !settingsV2.automations[key].find(
              a => a.action === automation.action
            )
          )
            acc.push(automation);
          return acc;
        },
        []
      );

      settingsV2.automations[key] =
        // $FlowFixMe - Flow throwing unknown number of elements in tuple.
        settingsV2.automations[key].concat(newActions);
    });

    return settingsV2;
  }

  return {
    version: 2,
    authorizedToCreate: {
      allParticipants: false,
      owner: false,
      roles: [],
      users: settings.authorizedUsers,
      groups: [],
      fields: []
    },
    authorizedToMarkCurrent: {
      allParticipants: false,
      owner: false,
      roles: [],
      users: [],
      groups: [],
      fields: [],
      statuses: [],
      allParticipantsOfCurrent: false,
      ownerOfCurrent: false,
      fieldsOfCurrent: []
    },
    copyableFields: settings.copyableFields,
    automations: {
      newCurrent: [
        {
          action: "addParticipants",
          active:
            R.path(
              revisionSettingsPaths.newCurrent.active("addParticipants"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.newCurrent.data("addParticipants"),
              settings
            ) || []
        },
        {
          action: "removeParticipants",
          active:
            R.path(
              revisionSettingsPaths.newCurrent.active("removeParticipants"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.newCurrent.data("removeParticipants"),
              settings
            ) || []
        },
        {
          action: "addParticipantsFromFields",
          active:
            R.path(
              revisionSettingsPaths.newCurrent.active(
                "addParticipantsFromFields"
              ),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.newCurrent.data(
                "addParticipantsFromFields"
              ),
              settings
            ) || []
        },
        {
          action: "sendMessage",
          active:
            R.path(
              revisionSettingsPaths.newCurrent.active("sendMessage"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.newCurrent.data("sendMessage"),
              settings
            ) || ""
        },
        {
          action: "updatePrivacy",
          active:
            R.path(
              revisionSettingsPaths.newCurrent.active("updatePrivacy"),
              settings
            ) || true,
          data: {
            mode:
              R.path(
                revisionSettingsPaths.newCurrent.data("updatePrivacy"),
                settings
              ) || "noChange",
            whitelistedUsers: []
          }
        },
        {
          action: "archive",
          active: true,
          data: false
        }
      ],

      oldCurrent: [
        {
          action: "addParticipants",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("addParticipants"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data("addParticipants"),
              settings
            ) || []
        },
        {
          action: "removeParticipants",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("removeParticipants"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data("removeParticipants"),
              settings
            ) || []
        },
        {
          action: "addParticipantsFromFields",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active(
                "addParticipantsFromFields"
              ),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data(
                "addParticipantsFromFields"
              ),
              settings
            ) || []
        },
        {
          action: "sendMessage",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("sendMessage"),
              settings
            ) || true,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data("sendMessage"),
              settings
            ) || ""
        },
        {
          action: "updatePrivacy",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("updatePrivacy"),
              settings
            ) || true,
          data: {
            mode:
              R.path(
                revisionSettingsPaths.oldCurrent.data("updatePrivacy"),
                settings
              ) || "noChange",
            whitelistedUsers: []
          }
        },
        {
          action: "updateStatus",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("updateStatus"),
              settings
            ) || false,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data("updateStatus"),
              settings
            ) || null
        },
        {
          action: "archive",
          active:
            R.path(
              revisionSettingsPaths.oldCurrent.active("autoArchive"),
              settings
            ) || false,
          data:
            R.path(
              revisionSettingsPaths.oldCurrent.data("autoArchive"),
              settings
            ) || false
        }
      ]
    }
  };
};
