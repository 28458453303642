// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/AccordionContent";
import { MultiSelect, Checkbox } from "src/components/Unifize";
import {
  getAllActiveUsers,
  getAllGroups,
  getCurrentChecklistBuilderFields,
  getAllRolesById,
  getWorkflowBuilderStatuses
} from "src/reducers";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import {
  SplitContainer,
  SelectContainer,
  SingleSplitContainer
} from "../styles";

import type {
  RevisionFieldSettingsv2,
  UserMention,
  Group,
  Roles
} from "src/types";

type Props = {
  settings: RevisionFieldSettingsv2,
  saveSettings: Function
};

const MarkRevisions = ({ settings, saveSettings }: Props) => {
  const handleSettings = (path, value) => {
    saveSettings(prevState =>
      R.assocPath(["authorizedToMarkCurrent", path], value, prevState)
    );
  };

  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );
  const userFields = checklistFields.filter(field => field.type === "user");
  const users: Array<UserMention> = useSelector(({ app }) =>
    getAllActiveUsers(app)
  );
  const groups: Array<Group> = useSelector(({ app }) => getAllGroups(app));
  const rolesById: Roles = useSelector(({ app }) => getAllRolesById(app)).roles;
  const roles: Array<Object> = Object.values(rolesById);
  const statuses = useSelector(({ app }) => getWorkflowBuilderStatuses(app));

  return (
    <Accordion title="Who can mark revision current">
      <AccordionContent>
        <SplitContainer>
          <Checkbox
            checked={settings?.authorizedToMarkCurrent?.allParticipants}
            label="All participants of the revision being marked current"
            onChange={value => handleSettings("allParticipants", value)}
          />
          <Checkbox
            checked={
              settings?.authorizedToMarkCurrent?.allParticipantsOfCurrent
            }
            label="All participants of the current revision"
            onChange={value =>
              handleSettings("allParticipantsOfCurrent", value)
            }
          />
          <Checkbox
            checked={settings?.authorizedToMarkCurrent?.owner}
            label="Owner of the revision being marked current"
            onChange={value => handleSettings("owner", value)}
          />
          <Checkbox
            checked={settings?.authorizedToMarkCurrent?.ownerOfCurrent}
            label="Owner of the current revision"
            onChange={value => handleSettings("ownerOfCurrent", value)}
          />
        </SplitContainer>
        <SelectContainer>
          <MultiSelect
            label="Specific users"
            placeholder="Search users"
            data={users}
            selected={settings?.authorizedToMarkCurrent?.users || []}
            showIcon
            keys={["id", "name", "email", "photoUrl"]}
            onChange={value => handleSettings("users", value)}
          />
          <MultiSelect
            label="Roles"
            placeholder="Search roles"
            data={roles}
            selected={settings?.authorizedToMarkCurrent?.roles || []}
            keys={["id", "title"]}
            onChange={value => handleSettings("roles", value)}
          />
          <MultiSelect
            label="Groups"
            placeholder="Search groups"
            data={groups}
            selected={settings?.authorizedToMarkCurrent?.groups || []}
            showIcon
            keys={["id", "name", "photoUrl"]}
            onChange={value => handleSettings("groups", value)}
          />
          <SingleSplitContainer>
            <MultiSelect
              label="Users from these field(s) in the revision being marked current"
              placeholder="Search fields"
              data={userFields}
              selected={settings?.authorizedToMarkCurrent?.fields || []}
              keys={["id", "label"]}
              onChange={value => handleSettings("fields", value)}
            />
            <MultiSelect
              label="Users from these field(s) in the current revision"
              placeholder="Search fields"
              data={userFields}
              selected={
                settings?.authorizedToMarkCurrent?.fieldsOfCurrent || []
              }
              keys={["id", "label"]}
              onChange={value => handleSettings("fieldsOfCurrent", value)}
            />
          </SingleSplitContainer>
          <MultiSelect
            label="Allow revision to be marked current only if they are under specific statuses"
            placeholder="Search statuses"
            data={statuses}
            selected={settings?.authorizedToMarkCurrent?.statuses || []}
            keys={["id", "text"]}
            onChange={value => handleSettings("statuses", value)}
          />
        </SelectContainer>
      </AccordionContent>
    </Accordion>
  );
};

export default MarkRevisions;
