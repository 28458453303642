// @flow

import React from "react";
import usePortal from "react-useportal";

import List from "./List";
import CurrentSelection from "./CurrentSelection";
import {
  Overlay,
  Container,
  Content as StyledContent
} from "src/styles/splitPane";

import Close from "src/containers/Close";

const OrgSettings = () => {
  const { Portal } = usePortal();
  // const userPermissions = useSelector(({ app }) => getUserPermissions(app));
  // const checkSSOViewPermission = useCallback(() => {
  //   userPermissions.resourcePermissions.includes(`119-${verb.get}`);
  // }, []);

  const isViewSSOAllowed = false;

  return (
    <Portal>
      <Close />
      <Overlay style={{ overflowX: "hidden" }}>
        <Container>
          <List isSSOViewAllowed={isViewSSOAllowed} />
          <StyledContent>
            <CurrentSelection isSSOViewAllowed={isViewSSOAllowed} />
          </StyledContent>
        </Container>
      </Overlay>
    </Portal>
  );
};

export default OrgSettings;
