// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useRef, useState, useCallback } from "react";
import SelectionSingle from "./SelectionSingle";
import Dropdown from "./Dropdown";
import { showFormCreationLoader } from "src/actions/checklist";
import ButtonLoader from "src/components/ButtonLoader";
import Name from "src/components/Form/Name";
import { RelativeBox } from "src/styles/box";
import { AddButton } from "./styles";

import { getIsFormCreating } from "src/reducers";

import type { RoomId } from "src/types";
import type { AddForm, RemoveForm } from "./index";

type Props = {
  fetching: boolean,
  last?: boolean,
  index: number,
  multiple: ?boolean,
  roomId: RoomId,
  fieldId: number,
  checklistId: number,
  selectedForms: Array<number>,
  value: Array<number>,
  disabled: boolean,
  addForm: AddForm,
  removeForm: RemoveForm
};

const Single = ({
  fetching,
  index,
  last,
  multiple,
  selectedForms,
  value,
  roomId,
  fieldId,
  checklistId,
  disabled,
  addForm,
  removeForm
}: Props) => {
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);

  const creating = useSelector(({ app }) =>
    getIsFormCreating(app, `${roomId}-${fieldId}`)
  );

  const outerRef = useRef(null);

  const firstVal = (selectedForms || [])[0];

  const handleSelect = (newValue: number) => {
    dispatch(showFormCreationLoader({ roomId: parseInt(roomId, 10), fieldId }));
    addForm(parseInt(newValue, 10));
  };

  const handleRemove = useCallback((oldVal: number) => {
    removeForm(oldVal);
  }, []);

  const handleDropdown = () => {
    if ((selectedForms || []).length > 1) {
      setDropdown(!dropdown);
    } else if ((selectedForms || []).length === 1) {
      handleSelect(selectedForms[0]);
      dispatch(
        showFormCreationLoader({ roomId: parseInt(roomId, 10), fieldId })
      );
    }
  };

  if (multiple) {
    return (
      <>
        {(value || []).length > 0 && (
          <SelectionSingle
            roomId={roomId}
            value={value}
            index={index}
            fetching={fetching}
            checklistId={checklistId}
            handleRemove={handleRemove}
            disabled={disabled}
          />
        )}

        {last && (
          <RelativeBox ref={outerRef}>
            <AddButton isLoading={creating}>
              <ButtonLoader
                type="button"
                isLoading={creating}
                handleClick={handleDropdown}
                disabled={disabled}
              >
                {(selectedForms || []).length === 1 ? (
                  <>&#43; Add {firstVal && <Name id={firstVal} />}</>
                ) : (
                  <>&#43; Select form</>
                )}
                {dropdown && (
                  <Dropdown
                    outerRef={outerRef}
                    items={selectedForms}
                    handleSelect={handleSelect}
                    handleClose={() => setDropdown(false)}
                    roomId={roomId}
                    fieldId={fieldId}
                  />
                )}
              </ButtonLoader>
            </AddButton>
          </RelativeBox>
        )}
      </>
    );
  }

  if ((value || []).length > 0) {
    return (
      <SelectionSingle
        roomId={roomId}
        value={value}
        index={index}
        loading={fetching}
        checklistId={checklistId}
        handleRemove={handleRemove}
        disabled={disabled}
      />
    );
  }

  return (
    <RelativeBox ref={outerRef}>
      <AddButton isLoading={creating}>
        <ButtonLoader
          type="button"
          isLoading={creating}
          handleClick={handleDropdown}
          disabled={disabled}
        >
          {(selectedForms || []).length === 1 ? (
            <>&#43; Add {firstVal && <Name id={firstVal} />}</>
          ) : (
            <>&#43; Select form</>
          )}
          {dropdown && (
            <Dropdown
              outerRef={outerRef}
              items={selectedForms}
              handleSelect={handleSelect}
              handleClose={() => setDropdown(false)}
              roomId={roomId}
              fieldId={fieldId}
            />
          )}
        </ButtonLoader>
      </AddButton>
    </RelativeBox>
  );
};

Single.defaultProps = {
  multiple: false,
  last: true
};

export default Single;
