// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Banner } from "src/components/Unifize";
import { Section, SectionTitle } from "./styles";
import Creator from "./CreatorItem";
import type { UnifizeUser } from "src/types";
import {
  getUsersFromIds,
  getAllowedSignatories,
  getAllowedCancellers
} from "src/reducers";

import type {
  FieldId,
  RoomId,
  RevisionFieldSettingsv2,
  ApprovalFieldSettingsV2
} from "src/types";

type Props = {
  path: string,
  settings: RevisionFieldSettingsv2 | ApprovalFieldSettingsV2,
  roomId: RoomId,
  fieldId: FieldId
};

const AdHocDetails = ({ fieldId, roomId, settings, path }: Props) => {
  const { [path]: value = {} } = settings;
  const { preventRequester = false } = value;
  const allowedSignatories = useSelector(({ app }) =>
    getAllowedSignatories(app, fieldId, roomId)
  );
  const cancellers = useSelector(({ app }) =>
    getAllowedCancellers(app, fieldId, roomId)
  );

  const selectedUsers: Array<UnifizeUser> =
    useSelector(({ app }) => getUsersFromIds(app, allowedSignatories)) || [];
  const cancellerUsers: Array<UnifizeUser> =
    useSelector(({ app }) => getUsersFromIds(app, cancellers)) || [];

  return (
    <>
      {preventRequester && (
        <Banner message="Requesters cannot add signatures." />
      )}
      {cancellerUsers.length > 0 && (
        <Section>
          <SectionTitle>Allowed Cancellers</SectionTitle>
          {cancellerUsers.map(user => (
            <Creator
              key={user.uid}
              img={user.photoUrl}
              title={user.displayName || user.email}
            />
          ))}
        </Section>
      )}
      {selectedUsers.length > 0 && (
        <Section>
          <SectionTitle>Allowed Signatories</SectionTitle>
          {selectedUsers.map(user => (
            <Creator
              key={user.uid}
              img={user.photoUrl}
              title={user.displayName || user.email}
            />
          ))}
        </Section>
      )}
    </>
  );
};

export default AdHocDetails;
