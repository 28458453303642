// @flow

import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import { Resizable } from "re-resizable";
import { Box } from "@chakra-ui/react";
import { useClientDimensions } from "react-client-dimensions";

import { hideDock, showDock, showSrwDock } from "src/actions/dock";
import {
  getChatroomType,
  getProcessLayoutSettings,
  getCurrentRoomId,
  getIsSrwMobile
} from "src/reducers";
import { getMainActiveDock, getSRWActiveDock } from "src/reducers/activeDock";
import type { AppState, RoomId, ReactRef } from "src/types";

import Panel from "./Panels";

import * as styles from "./styles";
import { fadeOut } from "./styles";
import { DockContainer } from "./styles";
import * as colors from "src/styles/constants/colors";

type Props = {
  room: RoomId,
  isSrw: boolean,
  type: string,
  showClose: boolean,
  _hideDock: Function,
  parentRef?: ReactRef
};

const Dock = ({
  room,
  _hideDock,
  isSrw,
  showClose,
  type,
  parentRef
}: Props) => {
  const dispatch = useDispatch();
  const { vw } = useClientDimensions();
  const [windowSize, setWindowSize] = useState({ current: vw });

  const checklistLayout = useSelector(({ app }) =>
    getProcessLayoutSettings(app, room)
  );

  const currentChatRoom = useSelector(({ app }) => getCurrentRoomId(app));

  useEffect(() => {
    setWindowSize({ current: vw });
  }, [vw]);

  const [checklistMaxWidth, setChecklistMaxWidth] = useState(0);
  const [currentWidth, setCurrentWidth] = useState({ width: "307px" });
  const [affordance, setAffordance] = useState({ background: "transparent" });

  const activeDock = useSelector(({ app }) =>
    !isSrw ? getMainActiveDock(app) : getSRWActiveDock(app)
  );

  const isMobile = useSelector(({ app }) => getIsSrwMobile(app));

  if (type === "direct") _hideDock();

  useEffect(() => {
    const isOpen =
      checklistLayout &&
      (checklistLayout.checklistOpenState || checklistLayout.openStatus);

    const shouldShowDock =
      isOpen &&
      (isSrw ? (isMobile ? isOpen.liteMobile : isOpen.lite) : isOpen.web);

    if (shouldShowDock && activeDock === null && !isSrw) {
      dispatch(showDock("checklist"));
    }

    if (isSrw && shouldShowDock && activeDock === null) {
      dispatch(showSrwDock("checklist"));
    }
  }, [currentChatRoom, checklistLayout]);

  useEffect(() => {
    if (R.isEmpty(checklistLayout)) {
      setCurrentWidth({
        width: "307px"
      });
    } else if (checklistLayout.checklistWidth === "max") {
      const parentWidth = parentRef?.current?.clientWidth || windowSize.current;
      setCurrentWidth({
        width: parentWidth / 2
      });
    } else {
      setCurrentWidth({
        width: `${
          307 *
          (checklistLayout.checklistWidth
            ? parseInt(checklistLayout.checklistWidth)
            : 1)
        }px`
      });
    }

    switch (true) {
      case windowSize.current > 779 && windowSize.current <= 1290:
        setChecklistMaxWidth(windowSize.current * 0.28);
        break;
      case windowSize.current > 1290 && windowSize.current <= 1390:
        setChecklistMaxWidth(windowSize.current * 0.35);
        break;
      case windowSize.current > 1390 && windowSize.current <= 1490:
        setChecklistMaxWidth(windowSize.current * 0.43);
        break;
      case windowSize.current > 1490 && windowSize.current <= 1590:
        setChecklistMaxWidth(windowSize.current * 0.45);
        break;
      case windowSize.current > 1590:
        setChecklistMaxWidth(windowSize.current * 0.48);
        break;
      default:
        break;
    }
  }, [windowSize, checklistMaxWidth, checklistLayout, currentChatRoom]);

  return (
    activeDock && (
      <DockContainer>
        <Resizable
          maxWidth={`${checklistMaxWidth}px`}
          minWidth={
            windowSize.current <= 779 ? `${windowSize.current}px` : "307px"
          }
          style={styles.ResizableContainer}
          handleStyles={{ left: styles.AffordanceHandle }}
          handleComponent={{
            left: (
              <Box sx={{ ...styles.ChecklistAffordance, ...affordance }}></Box>
            )
          }}
          handleClasses={{ left: "leftHandle" }}
          size={
            windowSize.current <= 779
              ? { width: `${windowSize.current}px` }
              : currentWidth
          }
          onResize={(e, direction, ref) => {
            setCurrentWidth({
              width: ref.style.width
            });
            setAffordance({ background: colors.active });
          }}
          onResizeStop={() => {
            setAffordance({
              background: "transparent",
              animation: `${fadeOut} 1 0.3s`
            });
          }}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <Panel
            activeDock={activeDock}
            room={room}
            isSrw={isSrw}
            showClose={showClose}
          />
        </Resizable>
      </DockContainer>
    )
  );
};

Dock.defaultProps = {
  showClose: false
};

const mapStateToProps = (
  { app }: { app: AppState },
  { room }: { room: RoomId }
) => ({
  type: getChatroomType(app, room)
});

export default connect(mapStateToProps, {
  _hideDock: hideDock
})(Dock);
