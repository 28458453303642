// @flow

import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import type { Map } from "immutable";

import {
  getChatroomChecklistFields,
  getChecklistFields
} from "src/actions/checklist";
import {
  getCurrentUserId,
  getCurrentRoomWorkflowTitle,
  getChecklistLoaderState,
  getCurrentRoomId,
  getChecklistFieldsBySections
} from "src/reducers";
import { fetchChatroomUpdates } from "src/actions/chatroom";
import type {
  AppState,
  RoomId,
  ChecklistHeader as ChecklistHeaderType
} from "src/types";
import WideChecklist from "./WideChecklist";

type Props = {
  currentUser: string,
  checklist: ChecklistHeaderType,
  fields: Map<string, Array<number>>,
  fetchingFields: boolean,
  room: RoomId,
  _getChatroomChecklistFields: Function,
  _fetchChatroomUpdates: Function
};

const Checklist = ({
  checklist,
  fields,
  fetchingFields,
  _getChatroomChecklistFields,
  _fetchChatroomUpdates,
  room
}: Props) => {
  const { id } = checklist;

  useEffect(() => {
    _getChatroomChecklistFields(id, room);
    _fetchChatroomUpdates();
  }, [room, id]);

  const fieldsArray = fields.get(`${id}`);

  let sectionFields = [];

  // Filters section ids from fieldsArray
  const check = useCallback(
    (n: number) => {
      if (typeof n === "object") {
        sectionFields = [...sectionFields, ...n.fields];
      }
    },
    [sectionFields]
  );

  (fieldsArray || []).forEach(check);

  const filteredFieldsArray = (fieldsArray || []).filter(
    item => !sectionFields.includes(item)
  );

  return (
    !fetchingFields && (
      <WideChecklist id={id} roomId={room} fields={filteredFieldsArray} />
    )
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  return {
    title: getCurrentRoomWorkflowTitle(app),
    room: getCurrentRoomId(app),
    currentUser: getCurrentUserId(app),
    fetchingFields: getChecklistLoaderState(app),
    fields: getChecklistFieldsBySections(app)
  };
};

export default connect(mapStateToProps, {
  _getChatroomChecklistFields: getChatroomChecklistFields,
  _getChecklistFields: getChecklistFields,
  _fetchChatroomUpdates: fetchChatroomUpdates
})(Checklist);
