// @flow

import { useDispatch } from "react-redux";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

import type { ChatroomSearchOptions, RoomId } from "src/types";

import List from "./List";
import Footer from "./Footer";
import { Dropdown as StyledDropdown } from "./styles";
import { Input } from "src/styles/input";
import { searchChatroom } from "src/actions/chatroom";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  roomId: RoomId,
  showRemove?: boolean,
  workflow: ?number,
  type: string,
  selectedValue: Array<number>,
  handleClose: Function,
  handleNewRoom: Function,
  handleSelect: Function,
  handleCreate: Function,
  handleRemove?: Function,
  create: boolean,
  select: boolean,
  groupVersions: boolean,
  selectNewRoomVersions?: Function,
  hideSelectedVersions?: boolean,
  dependentInclude?: ?Array<number>,
  dependentExclude?: ?Array<number>,
  alwaysShowRevisionIcon?: boolean,
  fromManageView: boolean,
  showArchived?: boolean
};

const Dropdown = ({
  roomId,
  showRemove,
  workflow,
  type,
  selectedValue,
  handleClose,
  handleNewRoom,
  handleSelect,
  handleCreate,
  handleRemove,
  create,
  select,
  groupVersions,
  selectNewRoomVersions,
  hideSelectedVersions,
  dependentInclude,
  dependentExclude,
  alwaysShowRevisionIcon,
  fromManageView = false,
  showArchived = false
}: Props) => {
  const dispatch = useDispatch();

  const _searchChatroom = (
    searchString: string,
    settings?: ChatroomSearchOptions = {}
  ) => dispatch(searchChatroom(searchString, settings));

  const outerRef = useRef(null);
  const [search, setSearch] = useState("");
  const [debouncedChatroomSearch] = useDebouncedCallback(_searchChatroom, 400);

  const showCreateAndSelect = create && select;

  const workflowId = workflow ? String(workflow) : null;

  useEffect(() => {
    // Search through all the chatrooms
    _searchChatroom("", {
      workflow: workflowId,
      type,
      showArchived
    });
  }, [workflow, type]);

  const closeDropdown = useCallback(
    (event: any) => {
      if (outerRef && outerRef.current) {
        if (!outerRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  const handleKeyDown = useCallback(
    (event: any) => {
      // Close on esc
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("mousedown", closeDropdown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
      document.removeEventListener("mousedown", closeDropdown, false);
    };
  }, []);

  const handleSearch = useCallback(
    (event: any) => {
      const { value } = event.target;
      setSearch(value);
      debouncedChatroomSearch(value, {
        workflow: workflowId,
        type,
        showArchived
      });
    },
    [setSearch, workflow, type, debouncedChatroomSearch]
  );

  const chatroomCreationEnabled = useComponentPermission(
    componentPermissions.checklistNewConversation
  );

  return (
    <StyledDropdown ref={outerRef}>
      <Input
        type="text"
        value={search}
        onChange={handleSearch}
        // if only create then show "Enter title"
        placeholder={!select ? "Enter title" : "Search"}
        autoFocus
      />

      <div style={{ position: fromManageView ? "block" : "absolute" }}>
        {(!create || showCreateAndSelect) && (
          <List
            roomId={roomId}
            showRemove={Boolean(showRemove)}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            selectedValue={selectedValue}
            groupVersions={groupVersions}
            selectNewRoomVersions={selectNewRoomVersions}
            hideSelectedVersions={hideSelectedVersions}
            dependentInclude={dependentInclude}
            dependentExclude={dependentExclude}
            alwaysShowRevisionIcon={Boolean(alwaysShowRevisionIcon)}
          />
        )}
        {(!select || showCreateAndSelect) && chatroomCreationEnabled && (
          <Footer
            text={search}
            workflow={workflow}
            type={type}
            handleCreate={handleCreate}
            handleNewRoom={handleNewRoom}
          />
        )}
      </div>
    </StyledDropdown>
  );
};

Dropdown.defaultProps = {
  single: false,
  groupVersions: false,
  selectNewRoomVersions: null
};

export default Dropdown;
