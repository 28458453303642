// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import type { RevisionFieldSettingsv2 } from "src/types";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/AccordionContent";
import { MultiSelect } from "src/components/Unifize";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import { getCurrentChecklistBuilderFields } from "src/reducers";

type Props = {
  settings: RevisionFieldSettingsv2,
  saveSettings: Function
};

const RevisionDefaults = ({ settings, saveSettings }: Props) => {
  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );

  const handleSettings = (path, value) => {
    saveSettings(prevState => R.assocPath([path], value, prevState));
  };

  return (
    <Accordion title="Revision creation defaults">
      <AccordionContent>
        <MultiSelect
          label="Select fields to copy to revision"
          placeholder="Search fields"
          data={checklistFields}
          selected={settings?.copyableFields || []}
          keys={["id", "label"]}
          onChange={value => handleSettings("copyableFields", value)}
          showSelectDeselect
        />
      </AccordionContent>
    </Accordion>
  );
};

export default RevisionDefaults;
