// @flow

import React, { useState, useCallback, Fragment } from "react";
import { connect } from "react-redux";

import { FileOrEmailContainer, FileOrEmailInfo, FileRemove } from "../styles";
import FileContent from "./FileContent";
import EmailContent from "./EmailContent";
import MessageCard from "src/components/messages/Chat/MessageNotch";
import SelectIndicator from "src/components/messages/Chat/SelectIndicator";
import Icon from "src/icons";
import Deleted from "src/components/messages/Chat/Deleted";
import Hidden from "src/components/messages/Chat/Hidden";
import Forward from "src/components/messages/Chat/Forward";
import User from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";
import NewMenu from "src/components/messages/NewMenu";
import {
  ChatCard,
  UserInfo,
  Name as StyledName,
  MenuIcon
} from "src/components/messages/Chat/styles";
import * as notifications from "src/constants/notifications";
import {
  getCurrentUserId,
  getChatroomMembership,
  getSelectedAction,
  getIsSrwMobile,
  getCurrentChatroomAddress
} from "src/reducers";
import { getAuthorColor } from "src/utils";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import {
  selectMessage,
  deselectMessage,
  hideSelectOptions,
  showMobileOptions
} from "src/actions/chatmenu";

import type {
  UID,
  ChatroomAddress,
  Message,
  MessageID,
  RoomId
} from "src/types";

type Props = {
  roomId: RoomId,
  message: Message,
  currentUser: UID,
  isMember: boolean,
  currentRoomAddress: ChatroomAddress,
  selectedAction: string,
  _selectMessage: Function,
  _deselectMessage: Function,
  selectedMessages: Array<MessageID>,
  _hideSelectOptions: Function,
  _showMobileOptions: Function,
  isSrwMobile: boolean
};

const FileOrEmail = ({
  roomId,
  message,
  currentUser,
  isMember,
  currentRoomAddress,
  selectedAction,
  _selectMessage,
  _deselectMessage,
  selectedMessages,
  _hideSelectOptions,
  _showMobileOptions,
  isSrwMobile
}: Props) => {
  const {
    id,
    timestamp,
    author,
    deleted,
    hidden,
    origin,
    read,
    delivered,
    action,
    data
  } = message;

  const { to, cc, bcc, subject, from } = data;

  const [submenu, setSubmenu] = useState(false);

  const isFile =
    action === notifications.FILE_ADD || action === notifications.FILE_REMOVE;
  const isEmailReceived = action === notifications.EMAIL_RECEIVED;
  const isCurrentUser = currentUser === author;

  const renderContent = () => {
    if (isFile) return <FileContent message={message} roomId={roomId} />;
    return <EmailContent message={message} />;
  };

  const isOwner = currentUser === author;

  const addSpace = useCallback(
    () => [...Array(35)].map((val, id) => <Fragment key={id}>&nbsp;</Fragment>),
    []
  );

  const isMessageSelected = selectedMessages.includes(id);

  const toggleMenu = useCallback(() => {
    if (selectedAction) {
      if (selectedAction === "MOBILE") {
        if (!isMessageSelected) {
          _hideSelectOptions();
          _showMobileOptions();
          _selectMessage(id, timestamp, isOwner);
        } else _deselectMessage(id);
      } else if (!isMessageSelected) _selectMessage(id, timestamp, isOwner);
      else _deselectMessage(id);
    }
  }, [selectedAction, id, timestamp, isOwner, isMessageSelected]);

  const selectMode = useCallback(
    (e: any) => {
      if (!selectedAction) {
        _showMobileOptions();
        _selectMessage(id, timestamp, isCurrentUser);
        e.stopPropagation();
      }
    },
    [selectedAction, id, timestamp, isCurrentUser]
  );

  if (deleted) return <Deleted message={message} />;

  if (hidden) return <Hidden message={message} />;

  if (action === notifications.FILE_REMOVE)
    return (
      <div>
        <FileRemove isCurrentUser={currentUser === author}>
          {currentUser === author ? `You` : <NameOrEmail uid={author} />}{" "}
          deleted a file. {addSpace()}
          <ChatTimestamp
            read={read}
            delivered={delivered}
            timestamp={timestamp}
          />
        </FileRemove>
      </div>
    );

  return (
    <FileOrEmailContainer
      id={id}
      onClick={toggleMenu}
      isSrwMobile={isSrwMobile}
    >
      <ChatCard showSelect={selectedAction && selectedAction !== "MOBILE"}>
        {isEmailReceived ? (
          <Icon type="mail" width={36} />
        ) : (
          <UserInfo>
            <User uid={author} size="sm" shape="ellipse" />
          </UserInfo>
        )}
        <MessageCard first maxWidth isCurrentUser={isCurrentUser}>
          {author ? (
            <StyledName hashColor={getAuthorColor(author, currentRoomAddress)}>
              {isCurrentUser ? <span>You</span> : <NameOrEmail uid={author} />}
            </StyledName>
          ) : (
            <StyledName hashColor={getAuthorColor(from, currentRoomAddress)}>
              <span>{from}</span>
            </StyledName>
          )}
          {origin ? (
            <>
              <Forward message={message} />
              {renderContent()}
            </>
          ) : (
            <>
              {!isEmailReceived && (
                <FileOrEmailInfo>
                  {isFile ? "Uploaded a file" : "Sent an email"}
                </FileOrEmailInfo>
              )}
              {renderContent()}
            </>
          )}
          <ChatTimestamp
            isEmailReceived={isEmailReceived}
            read={read}
            delivered={delivered}
            timestamp={timestamp}
          />
          {isSrwMobile && (
            <MenuIcon onClick={selectMode}>
              <Icon type="menuDown" />
            </MenuIcon>
          )}
        </MessageCard>
      </ChatCard>
      {isMember && !isSrwMobile ? (
        <NewMenu
          id={id}
          submenu={submenu}
          setSubmenu={setSubmenu}
          timestamp={timestamp}
          isOwner={isOwner}
          email={
            !isFile
              ? {
                  from: author,
                  to: to || [],
                  cc: cc || [],
                  bcc: bcc || [],
                  subject,
                  message: data.message
                }
              : null
          }
        />
      ) : null}
      <SelectIndicator id={id} timestamp={timestamp} isOwner={isOwner} />
    </FileOrEmailContainer>
  );
};

const mapStateToProps = ({ app }) => ({
  currentUser: getCurrentUserId(app),
  isMember: getChatroomMembership(app, app.chatRooms.current),
  currentRoomAddress: getCurrentChatroomAddress(app),
  selectedAction: getSelectedAction(app),
  selectedMessages: app.chatRooms.selectedMessages,
  isSrwMobile: getIsSrwMobile(app)
});

export default connect(mapStateToProps, {
  _selectMessage: selectMessage,
  _deselectMessage: deselectMessage,
  _hideSelectOptions: hideSelectOptions,
  _showMobileOptions: showMobileOptions
})(FileOrEmail);
