// @flow

import React, { useCallback } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";
import { connect, useSelector } from "react-redux";

import {
  getConversationModalVisibility,
  getConversationModalId
} from "src/reducers";

import { capitalize } from "src/utils";

import {
  NewMenu as StyledNewMenu,
  Reply,
  Forward,
  Delete,
  Start,
  Submenu as StyledSubmenu,
  EmailReply,
  EmailForward
} from "./styles";
import Submenu from "src/components/messages/Submenu";
import Icon from "src/icons";
import { colors } from "src/styles/constants";
import {
  setReplyTo,
  showDeleteOptions,
  showForwardOptions,
  selectMessage
} from "src/actions/chatmenu";
import { focusChatInput } from "src/actions/chatroom";
import { addRecipient, setEmailData, saveEmailData } from "src/actions/email";
import {
  getUserEmail,
  getUsersById,
  getUsersWithRoles,
  getRolesWithComponentPermission,
  getMessageData,
  getCurrentRoomId,
  getIsSingleResponse,
  getSelectedMessages
} from "src/reducers";
import { setEmailModalStage } from "src/actions/modal";
import * as emailModalStages from "src/constants/emailModalStages";
import useComponentPermission from "src/hooks/useComponentPermission";
import type {
  UsersById,
  UnifizeFile,
  MessageID,
  EmailModalData,
  UID,
  UnifizeChat,
  RoomId
} from "src/types";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  id: string,
  submenu: boolean,
  setSubmenu: Function,
  file?: UnifizeFile,
  timestamp: Date,
  isOwner: boolean,
  showDelete: Function,
  setReply: Function,
  showForward: Function,
  select: Function,
  selectedMessages: Array<MessageID>,
  selectedAction: string,
  email: ({ from: UID } & EmailModalData) | null,
  openEmailModal: Function,
  saveEmailData: Function,
  userEmail: string,
  isSrw: boolean,
  author?: string,
  extraInvitees: UsersById,
  usersById: UsersById,
  addRecipient: Function,
  setEmailData: Function,
  messageData: UnifizeChat,
  _focusChatInput: typeof focusChatInput,
  roomId: RoomId
};

const NewMenu = ({
  id,
  submenu,
  setSubmenu,
  showDelete,
  setReply,
  timestamp,
  isOwner,
  showForward,
  select,
  selectedAction,
  email,
  openEmailModal,
  saveEmailData,
  author,
  addRecipient,
  setEmailData,
  _focusChatInput
}: Props) => {
  const userEmail = useSelector(({ app }) =>
    getUserEmail(app, email?.from ?? "")
  );
  const selectedMessages = useSelector(({ app }) => getSelectedMessages(app));
  const isSrw = useSelector(({ app }) => getIsSingleResponse(app));
  const messageData = useSelector(({ app }) => getMessageData(app, id));
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const usersById = useSelector(({ app }) => getUsersById(app));
  const extraInviteePermissions = useSelector(({ app }) =>
    getRolesWithComponentPermission(app, "prompt-invite")
  );
  const roles = [...extraInviteePermissions, "contact"];
  const extraInvitees = useSelector(({ app }) => getUsersWithRoles(app, roles));

  const isConversationModalVisible = useSelector(({ app }) =>
    getConversationModalVisibility(app)
  );
  const modalId = useSelector(({ app }) => getConversationModalId(app));

  NewMenu.handleClickOutside = () => {
    setSubmenu(false);
  };

  const initiateForward = useCallback(
    (roomId: string) => {
      if (!selectedMessages.includes(id)) {
        select(id, timestamp, isOwner);
      }
      if (selectedAction !== "FORWARD") {
        showForward(roomId);
      }
    },
    [select, id, timestamp, isOwner, showForward, selectedMessages]
  );

  const initiateDelete = useCallback(
    (roomId: string) => {
      if (!selectedMessages.includes(id)) {
        select(id, timestamp, isOwner);
      }
      if (selectedAction !== "DELETE") {
        showDelete(roomId);
      }
    },
    [select, id, timestamp, isOwner, showDelete, selectedMessages]
  );

  const initiateEmailReply = useCallback(() => {
    if (!email) return;

    setEmailData({
      prop: "subject",
      value: !email.subject.startsWith("Re:")
        ? `Re: ${email.subject}`
        : email.subject
    });

    if (messageData.data) {
      // For more info refer the link below
      // https://github.com/unifize/unifize-server/pull/1385

      const { messageId } = messageData.data;

      let references = messageData.data.references;

      // If there are no references then the message
      // to which the user is replying becomes the first
      // reference and the thread begins
      // Else the message to which the user is replying to
      // gets added to the list of references so that the
      // reply to the email stays in the current email thread
      if (R.isNil(references)) {
        references = messageId;
      } else {
        references = `${references},${messageId}`;
      }

      setEmailData({
        prop: "headers",
        value: {
          inReplyTo: messageId,
          references
        }
      });
    }

    const userId = email.from;

    addRecipient({
      prop: "to",
      value: extraInvitees[userId]
        ? {
            email: extraInvitees[userId].email,
            role: extraInvitees[userId].orgRole
          }
        : usersById[userId]
        ? userId
        : { email: userId }
    });

    openEmailModal();
  }, [email, saveEmailData, messageData]);

  const initiateEmailForward = useCallback(() => {
    if (!email) return;

    const subject = `Fwd: ${email.subject}`;

    let message =
      "----------------------------------------------------------------------\n\n";

    message += `From: ${userEmail},\n`;

    [("to", "cc", "bcc", "subject")].forEach(prop => {
      const value = email[prop];
      message += value
        ? `${capitalize(prop)}: ${
            Array.isArray(value) ? R.join(", ", value) : value
          },\n`
        : "";
    });

    if (email.message) message += `\n${email.message}`;

    const emailData = {
      to: [],
      cc: [],
      bcc: [],
      subject,
      message
    };

    saveEmailData(emailData);
    openEmailModal();
  }, [email, saveEmailData]);

  const chatroomCreationEnabled = useComponentPermission(
    componentPermissions.newConversation
  );

  const isReplyEnabled = useComponentPermission(componentPermissions.chatInput);
  const isDeleteEnabled = useComponentPermission(
    componentPermissions.chatDelete
  );

  const handleReply = (id: string) => {
    if (isConversationModalVisible) {
      setReply(id, modalId);
      _focusChatInput(modalId);
    } else {
      setReply(id, roomId);
      _focusChatInput(roomId);
    }
  };

  const handleForward = () => {
    if (isConversationModalVisible) {
      initiateForward(modalId);
    } else {
      initiateForward(roomId);
    }
  };

  const handleDelete = () => {
    if (isConversationModalVisible) {
      initiateDelete(modalId);
    } else {
      initiateDelete(roomId);
    }
  };

  return (
    <StyledNewMenu>
      {author !== "unibot" && isReplyEnabled && (
        <Reply
          onClick={() => handleReply(id)}
          onKeyDown={() => handleReply(id)}
          role="button"
          tabIndex="0"
        >
          <Icon type="reply" color={colors.dullGrey} />
        </Reply>
      )}
      {author !== "unibot" && (
        <Forward
          onClick={handleForward}
          onKeyDown={handleForward}
          role="button"
          tabIndex="0"
        >
          <Icon type="forward" hollow color={colors.dullGrey} />
        </Forward>
      )}
      {isDeleteEnabled && (
        <Delete
          onClick={handleDelete}
          onKeyDown={handleDelete}
          role="button"
          tabIndex="0"
        >
          <Icon type="delete" />
        </Delete>
      )}
      {!isSrw && author !== "unibot" && chatroomCreationEnabled && (
        <Start
          onClick={() => setSubmenu(!submenu)}
          onKeyDown={() => setSubmenu(!submenu)}
          role="button"
          tabIndex="0"
        >
          Start {">"}
        </Start>
      )}
      {!isSrw && email && (
        <>
          <EmailReply onClick={initiateEmailReply}>Reply as Email</EmailReply>
          <EmailForward onClick={initiateEmailForward}>
            Forward as Email
          </EmailForward>
        </>
      )}
      {submenu ? (
        <StyledSubmenu>
          <Submenu submenu={submenu} setSubmenu={setSubmenu} id={id} />
        </StyledSubmenu>
      ) : null}
    </StyledNewMenu>
  );
};

NewMenu.defaultProps = {
  file: null,
  email: null
};

const clickOutsideConfig = {
  handleClickOutside: () => NewMenu.handleClickOutside
};

const mapDispatchToProps = {
  showDelete: showDeleteOptions,
  setReply: setReplyTo,
  showForward: showForwardOptions,
  select: selectMessage,
  openEmailModal: () => setEmailModalStage(emailModalStages.WRITE),
  _focusChatInput: focusChatInput,
  setEmailData,
  addRecipient,
  saveEmailData
};

export default connect(
  null,
  mapDispatchToProps
)(onClickOutside(NewMenu, clickOutsideConfig));
