// @flow

import { connect, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import { Dock as StyledDock, Content } from "../styles";
import Info from "../Info";
import ChecklistSection from "../Checklist";
import LoadingState from "src/components/LoadingState";
import NoFields from "./NoFields";

import {
  RefreshWrapper,
  RefreshMessage,
  RefreshButton,
  ChecklistWrapper
} from "./styles";
import {
  getCurrentChecklist,
  getRecentChats,
  getCurrentUserId,
  getIsSrwMobile,
  getChecklistLoaderState,
  getFieldsByChecklistId,
  getCurrentRoomId
} from "src/reducers";
import { fetchChatroomUpdates } from "src/actions/chatroom";
import { getChatroomChecklistFields } from "src/actions/checklist";

import type { RoomId, AppState, UID } from "src/types";

type Props = {
  room: RoomId,
  activeDock: string,
  isSrw: boolean,
  showClose: boolean,
  isChecklistLoading: boolean,
  checklists: any,
  _fetchChatroomUpdates: Function,
  _getChatroomChecklistFields: Function,
  recentChats: Array<Object>,
  currentUser: UID,
  fetchingFields: boolean
};

const Checklist = ({
  activeDock,
  isSrw,
  showClose,
  checklists,
  _fetchChatroomUpdates,
  _getChatroomChecklistFields,
  currentUser,
  fetchingFields
}: Props) => {
  const room = useSelector(({ app }) => getCurrentRoomId(app));
  const recentChats = useSelector(({ app }) => getRecentChats(app, room));

  const [refreshOption, setRefreshOption] = useState(false);
  const [preventInitialRender, setPreventInitialRender] = useState(true);
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  // $FlowFixMe - Optional chaining is not yet supported
  const currentChecklistId = checklists?.[0]?.id;

  const fieldsByChecklist = useSelector(({ app }) =>
    getFieldsByChecklistId(app, currentChecklistId)
  );

  const checklistFieldCount = fieldsByChecklist?.length ?? 0;

  // Show Refresh button after 3 minutes of clicking refresh
  const showRefreshButton = () => {
    setTimeout(() => {
      setRefreshOption(true);
    }, 180000);
  };

  useEffect(() => {
    // Show Refresh button after 3 minutes of initial checklist load
    showRefreshButton();
  }, []);

  // Checks if last message notification is from checklist actions
  const isLastMessageChecklist =
    recentChats[recentChats.length - 1]?.action === "CHECKLIST_FIELD_FILL";

  const data = recentChats[recentChats.length - 1]?.data;

  const isAuthorAndCurrnentUserSame = isLastMessageChecklist
    ? currentUser === recentChats[recentChats.length - 1]?.author
    : false;

  // shows refresh option if update in checklist ignoring initial load
  useEffect(() => {
    if (
      !preventInitialRender &&
      isLastMessageChecklist &&
      !isAuthorAndCurrnentUserSame
    ) {
      setRefreshOption(true);
    }
  }, [data]);

  // Prevents refresh option to show on first checklist load
  useEffect(() => {
    setPreventInitialRender(false);
  }, []);

  // Reload checklist on clicking refresh button and hide button
  const reloadChecklist = () => {
    checklists.map(el => {
      _getChatroomChecklistFields(el.id, room);
    });
    _fetchChatroomUpdates();
    showRefreshButton();
    setRefreshOption(false);
  };

  return (
    <StyledDock open={activeDock}>
      {isSrwMobile && (
        <Info
          roomId={room}
          title="Checklist"
          back={!isSrw}
          showClose={showClose}
          isSrw={isSrw}
          refreshOption={refreshOption && !isAuthorAndCurrnentUserSame}
        />
      )}
      <Content>
        <ChecklistWrapper isChecklistOpen={activeDock === "checklist"}>
          {isSrwMobile && refreshOption && (
            <RefreshWrapper>
              <RefreshMessage>
                <RefreshButton onClick={reloadChecklist}>Refresh</RefreshButton>
                &nbsp;to see latest checklist data
              </RefreshMessage>
            </RefreshWrapper>
          )}

          {fetchingFields && <LoadingState type="panelBody" />}
          {!fetchingFields && checklistFieldCount === 0 && (
            <NoFields roomId={room} />
          )}
          {currentChecklistId && <ChecklistSection checklist={checklists[0]} />}
        </ChecklistWrapper>
      </Content>
    </StyledDock>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  checklists: getCurrentChecklist(app),
  currentUser: getCurrentUserId(app),
  fetchingFields: getChecklistLoaderState(app)
});

export default connect(mapStateToProps, {
  _fetchChatroomUpdates: fetchChatroomUpdates,
  _getChatroomChecklistFields: getChatroomChecklistFields
})(Checklist);
