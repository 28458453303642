// @flow

import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { ChecklistNotification, ChecklistInfo } from "../styles";
import ChecklistContent from "./Content";
import User from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";
import SelectIndicator from "src/components/messages/Chat/SelectIndicator";
import MessageCard from "src/components/messages/Chat/MessageNotch";
import {
  ChatCard,
  UserInfo,
  Name as StyledName
} from "src/components/messages/Chat/styles";
import NewMenu from "src/components/messages/NewMenu";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import {
  getCurrentUserId,
  getChatroomAddress,
  getCurrentRoomId,
  getChatroomMembership,
  getSelectedAction,
  getActiveDock
} from "src/reducers";
import { showDock } from "src/actions/dock";
import { getAuthorColor } from "src/utils";

import type {
  AppState,
  ChatroomAddress,
  UID,
  ActiveDockHeader
} from "src/types";

type Props = {
  message: any,
  currentUser: UID,
  currentRoomAddress: ChatroomAddress,
  selectedAction: string,
  isMember: boolean,
  _showDock: Function,
  activeDock: ActiveDockHeader
};

const ChecklistFields = ({
  message,
  currentUser,
  currentRoomAddress,
  selectedAction,
  isMember,
  _showDock,
  activeDock
}: Props) => {
  const [submenu, setSubmenu] = useState(false);
  const { id, timestamp, author, data, delivered, read } = message;
  const isCurrentUser = author === currentUser;
  const isOwner = currentUser === author;

  const openChecklists = useCallback(() => {
    if (activeDock !== "checklist") _showDock("checklist");
  }, [activeDock]);

  return (
    <ChecklistNotification id={id}>
      <ChatCard showSelect={selectedAction}>
        <UserInfo>
          <User uid={author} size="sm" shape="ellipse" />
        </UserInfo>
        <MessageCard first isCurrentUser={isCurrentUser} isChecklist>
          <StyledName hashColor={getAuthorColor(author, currentRoomAddress)}>
            {isCurrentUser ? <span>You</span> : <NameOrEmail uid={author} />}
          </StyledName>
          <ChecklistInfo>
            Updated <span onClick={openChecklists}>checklist</span>
          </ChecklistInfo>
          <ChecklistContent
            timestamp={timestamp}
            data={data}
            isCurrentUser={isCurrentUser}
          />
          <ChatTimestamp
            timestamp={timestamp}
            delivered={delivered}
            read={read}
          />
        </MessageCard>
      </ChatCard>
      {isMember ? (
        <NewMenu
          id={id}
          submenu={submenu}
          setSubmenu={setSubmenu}
          timestamp={timestamp}
          isOwner={isOwner}
        />
      ) : null}
      <SelectIndicator id={id} timestamp={timestamp} isOwner={isOwner} />
    </ChecklistNotification>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app),
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app)),
  selectedAction: getSelectedAction(app),
  isMember: getChatroomMembership(app, getCurrentRoomId(app)),
  activeDock: getActiveDock(app)
});

export default connect(mapStateToProps, { _showDock: showDock })(
  ChecklistFields
);
