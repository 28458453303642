// @flow

import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from "react";
import * as R from "ramda";
import TextArea from "react-autosize-textarea";
import { useSelector, useDispatch } from "react-redux";
import { Text as ChakraText } from "@chakra-ui/react";
import Linkify from "react-linkify";

import { TextField, Done } from "./styles";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { getSettings } from "src/utils/checklist";
import AddData from "./AddData";

import {
  getChecklistValue,
  getChecklistFieldDetails,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";
import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import { extractChecklistValue, getPlaceholder } from "src/utils/checklist";

import useFields from "./useFields";
import Icon from "src/icons";

import type {
  FieldId,
  RoomId,
  ReactRef,
  ColumnId,
  FieldValue,
  HttpMethods
} from "src/types";
import { DisplayContainer, IconContainer, Display } from "./styles";

type Props = {
  formId?: ?number,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  handleClose?: Function,
  fromManageView?: boolean,
  handleClose?: Function,
  isSectionField?: boolean
};

const Text = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose
}: Props) => {
  const textAreaRef: ReactRef = useRef(null);
  const dispatch = useDispatch();

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );

  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const [selectedFieldValue, setSelectedFieldValue] = useState(selectedValue);

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );

  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );

  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const placeholder = getPlaceholder(
    //$FlowFixMe Method calls in optional chain
    getSettings(details?.get("settings")),
    "Enter Text"
  );

  const {
    edit,
    value: extractedValue,
    setValue,
    handleChange,
    openEdit,
    closeEdit,
    settings
  } = useFields({
    checklistValue,
    details
  });

  const value = fromManageView ? selectedFieldValue : extractedValue;

  const multiple = settings?.multiple || false;

  const handleSelectedChange = useCallback((e: any) => {
    setSelectedFieldValue(e.target.value);
  }, []);

  useEffect(() => {
    setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        } else {
          setSelectedFieldValue(value);
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const handleClick = useCallback(() => {
    if (locked) return;
    openEdit();
  }, []);

  const componentDecorator = useCallback(
    (href, text, key) => (
      <a
        href={href}
        key={key}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {text}
      </a>
    ),
    []
  );

  const updateChecklistValue = useCallback(() => {
    const prevValue = extractChecklistValue(checklistValue);

    if (prevValue !== value)
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: value === "" ? null : value,
            type: "date",
            checked: !!value
          },
          progress: true,
          formId,
          columnId
        })
      );

    closeEdit();
  }, [roomId, formId, columnId, fieldId, value]);

  // set focus at the end of text on textarea
  useLayoutEffect(() => {
    if (edit && textAreaRef.current)
      textAreaRef.current.setSelectionRange(
        (value || "").length,
        (value || "").length
      );
  }, [edit]);

  const { handleShiftEnter } = useKeyDownHandlers(updateChecklistValue);

  const handleKeyDown = (e: any) => {
    const prevValue = extractChecklistValue(checklistValue);
    // If escape is pressed cancel editing
    if (e.keyCode === 27 && setValue) {
      setValue(prevValue);
      if (closeEdit) {
        closeEdit();
      }
    } else {
      handleShiftEnter(e);
    }
  };

  if (edit) {
    return (
      <OutsideClickHandler onClickOutside={updateChecklistValue}>
        <TextField disabled={locked}>
          <TextArea
            placeholder={placeholder}
            value={value || ""}
            onChange={fromManageView ? handleSelectedChange : handleChange}
            autoFocus
            innerRef={el => {
              textAreaRef.current = el;
            }}
            onKeyDown={handleKeyDown}
            readOnly={locked}
            disabled={locked}
          />
          <Done onClick={updateChecklistValue}>Done</Done>
        </TextField>
      </OutsideClickHandler>
    );
  }

  if (R.isNil(value) || R.isEmpty(value)) {
    return (
      <AddData
        disabled={locked}
        type="text"
        handleClick={openEdit}
        isSelect={false}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <DisplayContainer isText={true} locked={locked}>
      <Display
        clickToEdit={true}
        disabled={locked}
        onClick={handleClick}
        isText={true}
        isMandatory={isMandatory}
      >
        <Linkify componentDecorator={componentDecorator}>
          <ChakraText m={0} noOfLines={null} minH="24px">
            {value}
          </ChakraText>
        </Linkify>
        <IconContainer>
          <Icon type="checklistEdit" handleClick={openEdit} />
        </IconContainer>
      </Display>
    </DisplayContainer>
  );
};

Text.defaultProps = {
  promptCallback: null,
  edit: false,
  settings: {},
  value: "",
  locked: false,
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  setValue: () => {}
};

export default Text;
