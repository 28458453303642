// @flow

import * as R from "ramda";
import {
  put,
  takeEvery,
  take,
  fork,
  cancel,
  delay,
  takeLatest,
  select
} from "redux-saga/effects";

import * as actions from "src/actions/chatroom";
import connection, { rsf } from "src/db";
import getAppState, { getUserMembership } from "src/selectors";
import * as atypes from "src/constants/actionTypes";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";

import type { Action } from "src/types";

// TODO: ensure messages are in order by sorting in reducer
function* handleMessages({ payload }: Action): any {
  try {
    const unsortedMessage = [];
    // Looking for changes in message collection
    for (const change of payload.snapshot.docChanges()) {
      // If change type is `removed', delete the message from state.
      if (change.type === "removed") {
        yield put({
          type: atypes.DELETE_MESSAGE_SUCCESS,
          payload: { id: change.doc.id, roomId: payload.roomId }
        });
      } else if (change.type === "added" || change.type === "modified") {
        const data = change.doc.data();
        unsortedMessage.push({
          ...data,
          id: change.doc.id,
          timestamp: data.timestamp
            ? data.timestamp.toDate().toISOString()
            : new Date().toISOString(),
          delivered: data.timestamp !== null,
          roomId: payload.roomId
        });
      }
    }

    if (unsortedMessage.length > 0) {
      // Sorting messages by date
      const sortByDate = R.sortBy(R.prop("timestamp"));
      const messages = R.map(x => ({ [x.id]: x }), sortByDate(unsortedMessage));

      yield put({
        type: atypes.NEW_MESSAGES,
        payload: {
          messages: {
            entities: R.mergeAll(messages),
            result: R.map(x => R.head(R.keys(x)))(messages)
          },
          roomId: payload.roomId
        }
      });

      if (payload.scroll) {
        // Hide loader shown when the new message on scroll loads up
        yield put({
          type: atypes.HIDE_LOADING_CHATROOM,
          payload: {}
        });
      }
    }
  } catch (e) {
    yield put({ type: atypes.NEW_MESSAGE_FAILURE, payload: e });
  }
}

function* watchHandleMessages(): any {
  yield takeEvery(atypes.HANDLE_MESSAGES, handleMessages);
}

function* syncMessages({ payload }: Action): any {
  const { roomId, messageCount, modal } = payload;

  try {
    const messages = yield fork(
      rsf.firestore.syncCollection,
      connection()
        .collection(`chatrooms/${roomId}/messages`)
        .orderBy("timestamp", "desc")
        .limit(messageCount),
      {
        successActionCreator: (snapshot: Object) =>
          actions.handleMessages(snapshot, roomId)
      }
    );

    const {
      payload: { roomId: cancelRoomId, modal: cancelModal }
    } = yield take(atypes.CANCEL_MESSAGE_SYNC);

    if (roomId === cancelRoomId && cancelModal === modal) {
      console.log("Cancel message sync ", cancelRoomId);
      yield cancel(messages);
    }
  } catch (error) {
    console.log("error syncing messages", roomId, error);
  }
}

function* watchSyncMessages(): any {
  yield takeEvery(atypes.START_MESSAGE_SYNC, syncMessages);
}

function* resyncMessages({ payload }): any {
  try {
    yield put({
      type: atypes.CANCEL_MESSAGE_SYNC,
      payload
    });

    yield delay(300);

    yield put({
      type: atypes.START_MESSAGE_SYNC,
      payload
    });
  } catch (error) {
    console.log("error resyncing messages", payload.roomId);
  }
}

function* watchResyncMessages(): any {
  yield takeEvery(atypes.RESYNC_MESSAGES, resyncMessages);
}

let currentId = null;

function* startMessageSync({ payload }: Action): any {
  try {
    if (currentId) {
      yield put({
        type: atypes.CANCEL_MESSAGE_SYNC,
        payload: {
          roomId: currentId,
          modal: false
        }
      });
      currentId = null;
    }

    yield delay(200);
    currentId = payload.id;

    const srw = (yield select(getAppState)).srw.isSingleResponse;

    if (!srw) {
      const { currentRoomAccessStatus } = (yield select(getAppState)).chatRooms;

      // Check if the user has access to view the messages in this chatroom
      if (currentRoomAccessStatus === currentRoomAccessStatuses.restricted) {
        return;
      }
    }

    let membership = yield select(getUserMembership);

    if (membership.contains(parseInt(currentId, 10))) {
      yield put({
        type: atypes.START_MESSAGE_SYNC,
        payload: {
          roomId: currentId,
          messageCount: 30,
          modal: false
        }
      });
    } else {
      yield take(atypes.GET_USER_MEMBERSHIP_SUCCESS);
      membership = yield select(getUserMembership);

      if (membership.contains(parseInt(currentId, 10))) {
        yield put({
          type: atypes.START_MESSAGE_SYNC,
          payload: {
            roomId: currentId,
            messageCount: 30,
            modal: false
          }
        });
      }
    }
  } catch (error) {
    console.log("Error starting sync", error);
  }
}

function* watchStartMessageSync(): any {
  yield takeLatest(atypes.SET_CURRENT_CHATROOM_SUCCESS, startMessageSync);
}

function* startConversationalModalSync({ payload }: Action): any {
  const roomId = payload.id;
  yield put({
    type: atypes.START_MESSAGE_SYNC,
    payload: {
      roomId,
      messageCount: 30,
      modal: true
    }
  });

  yield take(atypes.CLOSE_CONVERSATION_MODAL);

  yield put({
    type: atypes.CANCEL_MESSAGE_SYNC,
    payload: {
      roomId,
      modal: true
    }
  });
}

function* watchStartConversationalModalSync(): any {
  yield takeEvery(atypes.OPEN_CONVERSATION_MODAL, startConversationalModalSync);
}

export default [
  watchStartConversationalModalSync(),
  watchStartMessageSync(),
  watchHandleMessages(),
  watchSyncMessages(),
  watchResyncMessages()
];
