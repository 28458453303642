// @flow

import React, { useCallback, useState, useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";

import { Text } from "@chakra-ui/react";
import {
  Field,
  NumberInput,
  Done,
  Display,
  NumberIcons,
  DisplayContainer,
  IconContainer
} from "./styles";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import useFields from "./useFields";
import AddData from "./AddData";
import Icon from "src/icons";
import OutsideClickHandler from "src/components/OutsideClickHandler";

import {
  getChecklistValue,
  getChecklistFieldDetails,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";

import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import { extractChecklistValue, getPlaceholder } from "src/utils/checklist";
import { hasValue } from "src/utils";

import type {
  FieldId,
  RoomId,
  ColumnId,
  FieldValue,
  HttpMethods
} from "src/types";
type Props = {
  formId?: ?number,
  roomId: RoomId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  fromManageView?: ?boolean,
  handleClose?: Function,
  isSectionField?: boolean
};

const Number = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );
  const [selectedFieldValue, setSelectedFieldValue] = useState(selectedValue);

  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const {
    edit,
    settings,
    value: extractedValue,
    handleChange,
    openEdit,
    closeEdit,
    setValue
  } = useFields({
    checklistValue,
    details
  });

  const value = fromManageView ? selectedFieldValue : extractedValue;

  const multiple = settings?.multiple || false;

  const handleSelectedChange = useCallback((e: any) => {
    setSelectedFieldValue(e.target.value);
  }, []);

  useEffect(() => {
    setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const placeholder = getPlaceholder(settings, "Enter Number");
  const min = settings && settings.min ? settings.min : null;
  const max = settings && settings.max ? settings.max : null;
  const step = settings && settings.step ? settings.step : null;

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        } else {
          setSelectedFieldValue(value);
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const updateChecklistValue = useCallback(() => {
    const prevValueString = extractChecklistValue(checklistValue);
    const prevValue = hasValue(prevValueString)
      ? parseFloat(prevValueString)
      : null;

    const currentValue = hasValue(value) ? parseFloat(value) : null;

    if (prevValue !== currentValue) {
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: currentValue,
            type: "number",
            checked: currentValue !== null
          },
          progress: true,
          formId,
          columnId
        })
      );
    }
    if (closeEdit) {
      closeEdit();
    }
  }, [checklistValue, formId, columnId, value, roomId, fieldId]);

  const { handleEnter } = useKeyDownHandlers(updateChecklistValue);

  const handleClick = useCallback(() => {
    if (locked) return;
    openEdit();
  }, []);

  const increment = () => {
    if (value !== null) {
      const newValue =
        step !== null ? parseInt(value) + step : parseInt(value) + 1;

      if (max !== null && newValue > max) {
        setValue(max);
      } else {
        setValue(newValue);
      }
    } else {
      const stepCount = step ? step : 1;
      const newValue = min !== null ? min : stepCount;
      setValue(newValue);
    }
  };

  const decrement = () => {
    if (value !== null) {
      const newValue =
        step != null ? parseInt(value) - step : parseInt(value) - 1;

      if (min !== null && newValue < min) {
        setValue(min);
      } else {
        setValue(newValue);
      }
    } else {
      const stepCount = step ? step : 1;
      const newValue = min !== null ? min : stepCount;
      setValue(newValue);
    }
  };

  if (edit) {
    return (
      <OutsideClickHandler onClickOutside={updateChecklistValue}>
        <Field>
          <NumberInput
            type="number"
            placeholder={placeholder}
            value={value || ""}
            onChange={fromManageView ? handleSelectedChange : handleChange}
            min={min}
            max={max}
            step={step}
            autoFocus
            onKeyDown={handleEnter}
          />
          <NumberIcons>
            <Icon type="increment" handleClick={increment} />
            <Icon type="decrement" handleClick={decrement} />
            <Done onClick={updateChecklistValue}>Done</Done>
          </NumberIcons>
        </Field>
      </OutsideClickHandler>
    );
  }

  if (R.isNil(value) || R.isEmpty(value)) {
    return (
      <AddData
        disabled={locked}
        type="number"
        handleClick={openEdit}
        isSelect={false}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <DisplayContainer isText={false} locked={locked}>
      <Display
        clickToEdit={true}
        disabled={locked}
        onClick={handleClick}
        isText={false}
        isMandatory={isMandatory}
      >
        <Text m={0} noOfLines={null}>
          {value}
        </Text>
        <IconContainer>
          <Icon type="checklistEdit" handleClick={openEdit} />
        </IconContainer>
      </Display>
    </DisplayContainer>
  );
};

Number.defaultProps = {
  promptCallback: null,
  edit: false,
  settings: {},
  value: "",
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  locked: false
};

export default Number;
