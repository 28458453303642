// @flow

import * as R from "ramda";
import firebase from "firebase/app";
import { toast } from "react-toastify";

import connection, { increment } from "src/db";
import { backendUrl } from "src/config/firebase";
import * as easyFetch from "src/utils/fetch";

import type {
  UID,
  NewConversation,
  SavedFilter,
  RoomId,
  ChatroomAddress,
  NonOrgParticipant,
  ChecklistId
} from "src/types";

/**
 * Create a new chat room with the creator as the sole member
 *
 * @param {string} title - name of chat room
 * @param {UID} creator - UID of the creator
 * @type {NewConversation}
 */
const create = async (newRoom: NewConversation) => {
  const response = await fetch(`${backendUrl}/chatroom`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(newRoom)
  });

  switch (response.status) {
    case 400:
      throw new Error("Error creating conversation");
    default:
      return response.json();
  }
};

/**
 * Returns chatroom details.
 *
 * @param {RoomId}
 * @return {Chatroom} - chatroom details from Firestore.
 */
const getRoomDetails = async (roomId: RoomId) => {
  const snapshot = await connection()
    .collection("chatrooms")
    .doc(`${roomId}`)
    .get();
  const roomDetails = snapshot.data();
  return roomDetails;
};

/**
 * Assign members to chatroom
 */

const assignMembersAndGroups = async ({
  users,
  groups,
  roomId
}: {
  users: Array<UID | NonOrgParticipant> | UID,
  groups: ?Array<number>,
  roomId: string
}) =>
  easyFetch.put(`/chatroom/${roomId}/member`, {
    body: {
      member: users,
      groups: groups
    }
  });

/**
 * Remove members from a collection
 */
const removeMembers = async ({
  member,
  roomId
}: {
  member: UID,
  roomId: string
}) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/member/${member}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};

const removeGroups = async ({ id, roomId }: { id: number, roomId: string }) =>
  easyFetch._delete(`/chatroom/${roomId}/group/${id}`);

const updateLastRead = async (
  roomId: string,
  uid: UID,
  value: {
    orgId?: number,
    count?: number,
    lastRead?: Date
  }
) => {
  const attributes = connection()
    .collection(`userData/${uid}/chatrooms`)
    .doc(roomId);
  await attributes.set(value, { merge: true });
  return value;
};

const incrementLastRead = async (roomId: RoomId, uid: UID) => {
  const docRef = connection()
    .collection(`userData/${uid}/chatrooms`)
    .doc(roomId);

  await docRef.update({
    count: increment,
    lastRead: new Date()
  });
};

const updateFavourite = async ({
  roomId,
  uid,
  isFavourite
}: {
  roomId: RoomId,
  uid: UID,
  isFavourite: boolean
}) => {
  const docRef = connection()
    .collection(`userData/${uid}/chatrooms`)
    .doc(roomId);
  await docRef.update({
    favourite: isFavourite
  });
};

/**
 * API to get related count for chatroom
 *
 * @param  {string} roomId
 * @return {Object} count of related chatroom
 */
const getRelatedCount = async ({ roomId }: { roomId: string }) => {
  const response = await fetch(
    `${backendUrl}/chatroom/${roomId}/related-count`,
    {
      credentials: "include",
      method: "GET"
    }
  );

  return response.json();
};

/**
 * API to get related conversations for chatroom
 *
 * @param  {string} roomId
 * @return {Object} Related conversations
 */
const getRelatedConversations = async ({ roomId }: { roomId: string }) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}/related`, {
    credentials: "include",
    method: "GET"
  });

  return response.json();
};

/*
 * API to update attribute in chatroom
 *
 * @param  {string} roomId
 * @param  {Object} value value to be set
 * @return  returns void
 */
const updateAttribute = async ({
  roomId,
  value
}: {
  roomId: string,
  value: Object
}) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(value)
  });
  return response.json();
};

export const getChatroom = async (roomId: RoomId) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

export const getChatroomByAddress = (roomAddress: string) =>
  easyFetch.get(`/chatroom-by-address/${roomAddress}`);

/**
 * Save Pinned list
 *
 * @param {number} orgId current org of the user
 * @param {SavedFilter} filter Filter to be saved
 */
const savePinnedList = async ({
  uid,
  orgId,
  filters
}: {
  uid: string,
  orgId: number,
  filters: SavedFilter
}) => {
  const pinnedList = connection()
    .collection(`userData/${uid}/appData/settings/${orgId}`)
    .doc("filters");

  await pinnedList.set({ filters });

  return filters;
};

/*
 * API to Cancel conversation
 *
 * @param  {string} roomId
 * @return  returns void
 */
const cancelConversation = async ({
  roomId,
  value
}: {
  roomId: RoomId,
  value: Object
}) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/cancel`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(value)
  });
};

/*
 * API to Change conversation type
 *
 * @param  {string} roomId
 * @param  {string} type
 * @return  returns void
 */
const changeConversationType = async ({
  roomId,
  newType
}: {
  roomId: RoomId,
  newType: {
    type: string,
    templateId?: number
  }
}) => {
  await fetch(`${backendUrl}/chatroom/${roomId}/change`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(newType)
  });
};

/**
 * Get common members between multiple conversation
 * @param {Array} chatrooms to get common members
 * @return {Array} members common members between conversation
 */
const getCommonMembers = async (chatrooms: Array<number>) => {
  const url = `${backendUrl}/chatrooms/member?${R.join(
    "&",
    R.map(c => `chatroomId=${c}`, chatrooms)
  )}`;

  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Remove common members from multiple conversation
 * @param {Array} chatrooms to get common members
 * @param {Array} members to common members to be removed
 */
const removeCommonMembers = async (options: {
  chatrooms: Array<number>,
  members: Array<UID>
}) => {
  await fetch(`${backendUrl}/chatrooms/member/delete`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options)
  });
};

/**
 * Add members to multiple conversation
 * @param {Array} chatrooms to get common members
 * @param {Array} members to common members to be removed
 */
const addBulkMembers = async (options: {
  chatrooms: Array<number>,
  members: Array<UID>
}) => {
  await fetch(`${backendUrl}/chatrooms/member/add`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options)
  });
};

/**
 * Gets initial user chatroom attributes
 * @param {UID} uid of the user
 * @return {Object} document of user chatroom attributes
 */
const getInitialUserChatroomAtrributes = async (uid: UID) => {
  try {
    const snapshot = await connection()
      .collection(`userData/${uid}/chatrooms`)
      .get();

    return snapshot.docs;
  } catch (error) {
    return [];
  }
};

/**
 * Fetch all conversations
 * @return {Array} chatrooms with meta data
 */
const fetchAllConversations = async () => {
  const chatrooms = await fetch(`${backendUrl}/chatroom`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return chatrooms.json();
};

/**
 * Fetch chatroom metadata for a single conversation
 * @param {number} orgId
 * @param {string} address
 * @return {Object} chatroom metadata for single chatroom
 */
const fetchChatroomMetadata = async ({
  orgId,
  address
}: {
  orgId: number,
  address: ChatroomAddress
}) => {
  const chatroom = await connection()
    .collection("chatrooms")
    .where("orgId", "==", orgId)
    .where("address", "==", address);
  return chatroom.get();
};

/**
 * Fetch child conversation details
 * @param {RoomId} roomId of the parent
 */
const getChildConversations = async (roomId: RoomId) => {
  const response = await fetch(`${backendUrl}/chatroom/${roomId}/child`, {
    credentials: "include",
    method: "GET"
  });

  return response.json();
};

/**
 * Set timestamp of user typing
 * @param {RoomId} roomId of current chatroom
 * @param {UID} currentUserId of current user
 */
export const setUserTyping = async (roomId: RoomId, currentUserId: UID) => {
  const chatroomRef = connection().collection("chatrooms").doc(`${roomId}`);

  const typing = await chatroomRef.collection("typing").doc(currentUserId);

  await typing.set({
    lastTyped: firebase.firestore.FieldValue.serverTimestamp()
  });

  return;
};

/**
 * @param {RoomId} roomId of current chatroom
 * @param {UID} currentUserId of current user
 */
export const deleteUserTyping = async (roomId: RoomId, currentUserId: UID) => {
  const chatroomRef = connection().collection("chatrooms").doc(`${roomId}`);

  const typing = await chatroomRef.collection("typing").doc(currentUserId);

  await typing.delete();

  return;
};

/**
 * Fetching recently updated chatrooms
 * @param {string} time of last fetched conversation
 */
export const getChatroomUpdates = async (time: ?string) => {
  let URL = `${backendUrl}/chatroom-updates`;
  if (time) {
    URL += `?updatedAt=${time}`;
  }

  const chatrooms = await fetch(URL, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return chatrooms.json();
};

/**
 * Update the comment for a chatroom version
 * @param  {number} id
 * @param  {string} versionComment
 */
export const updateVersionComment = (id: number, versionComment: string) =>
  easyFetch.patch(`/chatroom/${id}`, {
    body: { versionComment }
  });

/**
 * Update the current version of a chatroom
 * @param  {number} id
 */
export const updateCurrentVersion = async (id: number) => {
  try {
    const response = await fetch(`${backendUrl}/chatroom/${id}`, {
      credentials: "include",
      method: "PATCH",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        currentVersion: true
      })
    });
    return await response.json();
  } catch (error) {
    if (error.status === 401) {
      toast.error(
        "The status of this revision does not allow it to be marked as current."
      );
    } else {
      toast.error(
        "There was an error while updating the current version of the chatroom."
      );
      console.error(error);
    }
  }
};

/**
 * Get adobe or PDFgen JSON for download
 * @param  {string} provider
 * @param  {RoomId} roomId
 * @param  {checklistId} checklistId
 */
export const downloadJson = async (
  provider: "adobe" | "pdfgen",
  roomId: RoomId,
  checklistId: ChecklistId
) => {
  try {
    const response = await fetch(
      `${backendUrl}/chatroom/${roomId}/checklist/${checklistId}/json/${provider}`
    );

    if (response.ok) {
      toast.success(
        "Generating JSON. Download link will be emailed to you in a couple of minutes."
      );
    }
  } catch (error) {
    console.log(error);
    toast.error("Unable to generate json");
  }
};

export {
  getChildConversations,
  create,
  assignMembersAndGroups,
  removeMembers,
  removeGroups,
  updateLastRead,
  incrementLastRead,
  updateFavourite,
  getRelatedCount,
  getRelatedConversations,
  updateAttribute,
  savePinnedList,
  cancelConversation,
  getRoomDetails,
  changeConversationType,
  getCommonMembers,
  removeCommonMembers,
  addBulkMembers,
  getInitialUserChatroomAtrributes,
  fetchAllConversations,
  fetchChatroomMetadata
};
