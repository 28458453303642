// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import Accordion from "src/components/Accordion.v2";
import AccordionContent from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/AccordionContent";
import { MultiSelect, Checkbox } from "src/components/Unifize";
import {
  getAllActiveUsers,
  getAllGroups,
  getCurrentChecklistBuilderFields,
  getAllRolesById
} from "src/reducers";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";

import type {
  RevisionFieldSettingsv2,
  UserMention,
  Group,
  Roles
} from "src/types";

type Props = {
  settings: RevisionFieldSettingsv2,
  saveSettings: Function
};

const CreateRevisions = ({ settings, saveSettings }: Props) => {
  const handleSettings = (path, value) => {
    saveSettings(prevState =>
      R.assocPath(["authorizedToCreate", path], value, prevState)
    );
  };

  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );
  const userFields = checklistFields.filter(field => field.type === "user");
  const users: Array<UserMention> = useSelector(({ app }) =>
    getAllActiveUsers(app)
  );
  const groups: Array<Group> = useSelector(({ app }) => getAllGroups(app));
  const rolesById: Roles = useSelector(({ app }) => getAllRolesById(app)).roles;
  const roles: Array<Object> = Object.values(rolesById);

  return (
    <Accordion title="Who can create revisions">
      <AccordionContent>
        <Checkbox
          checked={settings?.authorizedToCreate?.allParticipants}
          label="All participants"
          onChange={value => handleSettings("allParticipants", value)}
        />
        <Checkbox
          checked={settings?.authorizedToCreate?.owner}
          label="Owner"
          onChange={value => handleSettings("owner", value)}
        />
        <MultiSelect
          label="Specific users"
          placeholder="Search users"
          data={users}
          selected={settings?.authorizedToCreate?.users || []}
          showIcon
          keys={["id", "name", "email", "photoUrl"]}
          onChange={value => handleSettings("users", value)}
        />
        <MultiSelect
          label="Roles"
          placeholder="Search roles"
          data={roles}
          selected={settings?.authorizedToCreate?.roles || []}
          keys={["id", "title"]}
          onChange={value => handleSettings("roles", value)}
        />
        <MultiSelect
          label="Groups"
          placeholder="Search groups"
          data={groups}
          selected={settings?.authorizedToCreate?.groups || []}
          showIcon
          keys={["id", "name", "photoUrl"]}
          onChange={value => handleSettings("groups", value)}
        />
        <MultiSelect
          label="Users from these field(s)"
          placeholder="Search fields"
          data={userFields}
          selected={settings?.authorizedToCreate?.fields || []}
          keys={["id", "label"]}
          onChange={value => handleSettings("fields", value)}
        />
      </AccordionContent>
    </Accordion>
  );
};

export default CreateRevisions;
