// @flow

import styled from "@emotion/styled";
import { switchProp } from "styled-tools";

import appLocation from "src/constants/location";
import * as colors from "src/styles/constants/colors";

export const DueDate = styled.div`
  color: ${colors.active};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.location === appLocation.chatroomHeader && 0};

  > div > svg {
    position: relative;
    top: 1.25px;
  }
  > div > span {
    padding-left: 0.3em;
  }
  > div {
    display: flex;
    justify-content: center;
  }
`;

export const Value = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.25rem;
  max-width: ${props => props.maxW};

  > span {
    padding-left: 8px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6.5em;
    font-size: 12px;
  }
  :focus {
    background: ${colors.grey32};
  }
  :hover {
    background: ${colors.grey32};
    border-radius: 0.25rem;
  }
  > svg > path {
    fill: ${switchProp("timeline", {
      future: colors.active,
      past: colors.danger,
      present: colors.success,
      noValue: colors.active
    })};
  }
  color: ${switchProp("timeline", {
    future: colors.primary,
    past: colors.danger,
    present: colors.success,
    noValue: colors.primary
  })};
  ${props =>
    props.maxWidth && `max-width: ${props.maxWidth}; overflow-x: hidden;`}
`;

export const FloatContainer = styled.div`
  position: absolute;
  top: ${props => props.top ?? "auto"};
  bottom: ${props => props.bottom ?? "auto"};
  left: ${props => props.left ?? "0px"};
  right: ${props => props.right ?? "0px"};
`;
